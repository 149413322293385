import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_sunburst from "@amcharts/amcharts4/plugins/sunburst";
import am4lang_fr_FR from "@amcharts/amcharts4/lang/fr_FR";
import { useNavFiltre } from "../../Contextes/navfiltreContext";
am4core.useTheme(am4themes_animated);
const NestedDonut = (props) => {
  //filtre global
  const { addToFiltre } = useNavFiltre();
  const chart_draw = useRef(null);

  useLayoutEffect(() => {
    // create chart
    var chart = am4core.create(props.id, am4plugins_sunburst.Sunburst);
    chart.padding(0, 0, 0, 0);
    chart.radius = am4core.percent(98);
    let data = [];

    props.data.forEach((element) => {
      let rows = [];
      element["data"]["buckets"].forEach((e) => {
        rows.push({ name: e.key, value: e.doc_count });
      });
      chart.data.push({ name: element.key, children: rows });
    });

    chart.colors.step = 2;
    chart.fontSize = 11;
    chart.innerRadius = am4core.percent(10);

    // define data fields
    chart.dataFields.value = "value";
    chart.dataFields.name = "name";
    chart.dataFields.children = "children";

    var level0SeriesTemplate = new am4plugins_sunburst.SunburstSeries();
    level0SeriesTemplate.hiddenInLegend = false;
    chart.seriesTemplates.setKey("0", level0SeriesTemplate);

    // this makes labels to be hidden if they don't fit
    level0SeriesTemplate.labels.template.truncate = true;
    level0SeriesTemplate.labels.template.hideOversized = true;

    level0SeriesTemplate.labels.template.adapter.add("rotation", function (
      rotation,
      target
    ) {
      target.maxWidth =
        target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
      target.maxHeight = Math.abs(
        ((target.dataItem.slice.arc *
          (target.dataItem.slice.innerRadius + target.dataItem.slice.radius)) /
          2) *
          am4core.math.RADIANS
      );

      return rotation;
    });

    var level1SeriesTemplate = level0SeriesTemplate.clone();
    chart.seriesTemplates.setKey("1", level1SeriesTemplate);
    level1SeriesTemplate.fillOpacity = 0.75;
    level1SeriesTemplate.hiddenInLegend = true;

    var level2SeriesTemplate = level0SeriesTemplate.clone();

    chart.seriesTemplates.setKey("2", level2SeriesTemplate);
    level2SeriesTemplate.fillOpacity = 0.5;
    level2SeriesTemplate.hiddenInLegend = true;
    //.columns.template
    level1SeriesTemplate.slices.template.cursorOverStyle =
      am4core.MouseCursorStyle.pointer;

    level1SeriesTemplate.slices.template.events.on("hit", function (ev) {
      let value = ev.target.dataItem.dataContext["name"];
      addToFiltre(props.typeData, value);
    });
    chart.legend = new am4charts.Legend();
    chart.legend.position = "left";
    chart.legend.fontSize = 14;
    /*title*/
    let title = chart.titles.create();
    title.text = props.title;
    title.align = "left";
    title.fontSize = 15;
    title.fontWeight = 600;
    title.marginBottom = 10;
    title.fill = "#303950";

    chart_draw.current = chart;
    return () => {
      chart.dispose();
    };
  }, []);

  return <div id={props.id} style={{ width: "95%", height: "400px" }}></div>;
};

export default NestedDonut;
