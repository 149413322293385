import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import {
  MDBIcon,
  MDBNotification,
  MDBInput,
  MDBBtn,
  MDBRow,
  MDBCol,
} from "mdbreact";
import SelectSearch from "react-select-search";
import DataTable, { createTheme } from "react-data-table-component";
import Moment from "moment";
import { IconButton } from "@material-ui/core";
export default function AdminUsers() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [editMode, seteditMode] = useState(false);
  const getUsers = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/admin/users`).then((resp) => {
      setUsers(resp.data);
    });
  };
  const Fdelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/admin/users/${id}`)
      .then((resp) => {
        console.log(resp.data);
        getUsers();
      });
  };
  const FOpenEdit = (id = null) => {
    //load userdata
    if (id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/admin/users/${id}`)
        .then((resp) => {
          console.log(resp.data);
          setUser(resp.data);
        });
    }

    seteditMode(true);
  };
  useEffect(() => {
    getUsers();
    return () => {
      setUsers([]);
    };
  }, []);
  const save = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";

    if (event.target.reportValidity()) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/admin/users`, { user: user })
        .then((resp) => {
          seteditMode(false);
          getUsers();
        });
    }
  };
  createTheme("solarized", {
    text: {
      primary: "#268bd2",
      secondary: "#2aa198",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  });
  const columns = [
    {
      name: "Active ",
      selector: "active",
      sortable: true,
      cell: (row) => (row.active ? "Oui" : "Non"),
    },
    {
      name: "Role ",
      selector: "role",
      sortable: true,
    },
    {
      name: "Nom ",
      selector: "nom",
      sortable: true,
      cell: (row) => row.prenom + " " + row.nom,
      minWidth: "20%",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      minWidth: "25%",
    },
    {
      name: "Entreprise",
      selector: "entreprise",
      sortable: true,
    },
    {
      name: "Fonction",
      selector: "fonction",
      sortable: true,
    },
    {
      name: "Last login ",
      selector: "login",
      sortable: true,
      cell: (row) => row.login && Moment(row.login).format("DD/MM/YYYY"),
    },
    {
      maxWidth: "12%",
      cell: (row) => (
        <div>
          <IconButton
            aria-label="delete"
            className="d-inline"
            color="secondary"
            onClick={() => {
              if (window.confirm("Are you sure you wish to delete this item?"))
                Fdelete(row._id);
            }}
          >
            <MDBIcon far icon="trash-alt" className="fa_sm" />
          </IconButton>
          <IconButton
            className="d-inline "
            color="secondary"
            onClick={() => {
              FOpenEdit(row._id);
            }}
          >
            <MDBIcon fas icon="pen" className="fa_sm" />
          </IconButton>
        </div>
      ),
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
  };
  return (
    <Fragment>
      <MDBRow>
        <MDBCol size="12">
          <MDBIcon
            icon="plus"
            className="float-left mb-2"
            onClick={(e) => {
              setUser({});
              seteditMode(!editMode);
            }}
          />
        </MDBCol>
      </MDBRow>
      {editMode && (
        <form
          className="needs-validation mb-3"
          onSubmit={(e) => {
            save(e);
          }}
          noValidate
        >
          {" "}
          <MDBRow>
            <MDBCol size="12">
              <MDBIcon
                icon="times"
                className="float-right mb-2"
                onClick={(e) => seteditMode(!editMode)}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="6">
              <MDBInput
                hint="Nom"
                className="mt-2"
                value={user.nom}
                type="text"
                required
                onChange={(e) => {
                  setUser({ ...user, nom: e.target.value });
                }}
              />
            </MDBCol>
            <MDBCol size="6">
              {" "}
              <MDBInput
                hint="Prénom"
                className="mt-2"
                value={user.prenom}
                type="text"
                required
                onChange={(e) => {
                  setUser({ ...user, prenom: e.target.value });
                }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="6">
              {" "}
              <MDBInput
                hint="Email"
                type="email"
                value={user.email}
                required
                onChange={(e) => {
                  setUser({ ...user, email: e.target.value });
                }}
              />
            </MDBCol>
            <MDBCol size="6">
              {" "}
              <MDBInput
                hint="Téléphone"
                type="text"
                value={user.telephone}
                onChange={(e) => {
                  setUser({ ...user, telephone: e.target.value });
                }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="6">
              {" "}
              <MDBInput
                hint="Entreprise"
                className="mt-2"
                value={user.entreprise}
                type="text"
                required
                onChange={(e) => {
                  setUser({ ...user, entreprise: e.target.value });
                }}
              />
            </MDBCol>
            <MDBCol size="6">
              {" "}
              <MDBInput
                hint="Fonction"
                className="mt-2"
                value={user.fonction}
                type="text"
                required
                onChange={(e) => {
                  setUser({ ...user, fonction: e.target.value });
                }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="6">
              <MDBInput
                hint="Mot de passe"
                className="mt-2"
                value={user.password}
                // type="password"
                onChange={(e) => {
                  setUser({ ...user, password: e.target.value });
                }}
              />
            </MDBCol>
            <MDBCol size="6" className="mt-4">
              <SelectSearch
                onChange={(e) => setUser({ ...user, role: e })}
                closeOnSelect={true}
                value={user.role}
                printOptions="on-focus"
                placeholder="Role"
                options={[
                  { name: "User", value: "user" },
                  { name: "Admin", value: "admin" },
                  { name: "Light", value: "light" },
                ]}
              />{" "}
              <SelectSearch
                onChange={(e) => setUser({ ...user, active: e })}
                closeOnSelect={true}
                value={user.active}
                printOptions="on-focus"
                placeholder="Active ?"
                options={[
                  { name: "Oui", value: true },
                  { name: "Non", value: false },
                ]}
              />
            </MDBCol>
          </MDBRow>
          <MDBBtn
            color="success"
            className="px-auto pt-2"
            type="submit"
            size="sm"
          >
            Valider
            <MDBIcon
              icon="paper-plane"
              size="1x"
              className="ml-2 white-text"
            ></MDBIcon>
          </MDBBtn>
        </form>
      )}
      {users.length > 0 && (
        <DataTable
          defaultSortAsc="false"
          noHeader="true"
          pagination="True"
          paginationPerPage="5"
          title=""
          columns={columns}
          data={users}
          //   theme="solarized"
        />
      )}
    </Fragment>
  );
}
