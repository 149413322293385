import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_fr_FR from "@amcharts/amcharts4/lang/fr_FR";
am4core.useTheme(am4themes_animated);
const TendanceGraph = (props) => {
  const chart = useRef(null);

  useLayoutEffect(() => {
    let x = am4core.create("chartdiv", am4charts.XYChart);
    x.language.locale = am4lang_fr_FR;
    x.paddingRight = 20;

    let title = x.titles.create();
    title.text = "Tendance temps réel";
    title.align = "left";
    title.fontSize = 15;
    title.fontWeight = 600;
    title.marginBottom = 10;
    title.fill = "#303950";

    let label = x.chartContainer.createChild(am4core.Label);
    label.text = "Offres uniques normalisées";
    label.align = "center";
    label.fontSize = 15;
    label.fontWeight = 600;
    label.marginBottom = 10;
    label.fill = "#303950";

    let data = [];
    let dataAll = [];
    props.tendence.forEach((element, i) => {
      data.push({
        date: element.key_as_string,
        value: element.doc_count,
      });
      if (i == 0) dataAll["tendance"] = [];
      dataAll["tendance"].push({
        date: element.key_as_string,
        value: element.doc_count,
      });

      element.metiers.buckets.forEach((e, j) => {
        if (!dataAll[e.key]) dataAll[e.key] = [];
        dataAll[e.key].push({
          date: element.key_as_string,
          value: e.doc_count,
        });
      });
    });

    console.log(dataAll);
    // x.exporting.title = "Tendence temps réel";
    //  x.exporting.menu = new am4core.ExportMenu();

    //x.data = data;

    let dateAxis = x.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.fontSize = 12;
    let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;
    valueAxis.fontSize = 12;

    let scrollbarX = new am4charts.XYChartScrollbar();
    console.log(Object.keys(dataAll).length);
    for (const [key, value] of Object.entries(dataAll)) {
      if (key === "tendance") {
        let series = x.series.push(new am4charts.LineSeries());
        series.data = value;
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";
        series.tooltipText = "{valueY.value}";
        series.fontSize = 12;
        scrollbarX.series.push(series);
      }
    }

    x.cursor = new am4charts.XYCursor();

    x.scrollbarX = scrollbarX;
    x.scrollbarX.background.fill = am4core.color("#ffffff");
    x.scrollbarX.fill = am4core.color("blue");
    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [props]);

  return <div id="chartdiv" style={{ width: "95%", height: "400px" }}></div>;
};

export default TendanceGraph;
