import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavFiltre } from "./../Contextes/navfiltreContext";
import ContratGraph from "../components/chart/ContratGraph";
import axios from "axios";

import Indicateur from "../components/widget/Indicateur";
const Contrat = (props) => {
  // filtre
  const { filtre } = useNavFiltre();
  const [graph, setGraph] = useState([]);

  //get salaire aggr
  const getContratAggr = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/contratAggr`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        filtre: filtre,
      })
      .then((resp) => {
        let data = resp.data.aggregations.typeContrat.buckets;
        // console.log(data);
        setGraph(data);
      });
  };

  // Init function
  useEffect(() => {
    setGraph([]);
    getContratAggr();
  }, [filtre]);

  return (
    <Fragment>
      {" "}
      <Indicateur></Indicateur>
      {graph.length > 0 && (
        <ContratGraph
          id="typecontratDevheatmap"
          data={graph}
          title="Natures et types de contrats"
        ></ContratGraph>
      )}
    </Fragment>
  );
};
export default Contrat;
