import React, { Fragment, useState, useEffect, useRef } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { MDBContainer, MDBRow, toast, ToastContainer } from "mdbreact";
import Header from "../components/header/Header";
import SideBar from "../components/menu/SideBar";
import AdminUsers from "../components/admin/AdminUsers";
import AdminMessages from "../components/admin/AdminMessages";

const Admin = (props) => {
  return (
    <Fragment>
      <Header></Header>
      <ToastContainer
        hideProgressBar={false}
        newestOnTop={false}
        autoClose={1500}
      ></ToastContainer>
      <MDBContainer fluid>
        <MDBRow>
          <div className="dream-text p-2 border-right">
            <SideBar></SideBar>
          </div>
          <div className="max-Heigher m-0">
            <Switch>
              <Route path="/admin/users" component={AdminUsers} />
              <Route path="/admin/messages" component={AdminMessages} />
              <Route path="*" component={AdminUsers} />
            </Switch>
          </div>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

export default Admin;
