import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "../components/header/Header";
import OffreApercu from "../components/offre/OffreApercu";
import {
  MDBMask,
  MDBView,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput,
} from "mdbreact";
import axios from "axios";
const Home = () => {
  //vars
  const [offres, setOffres] = useState([]);
  const [search, setSearch] = useState("");
  //function to load offres
  const loadOffres = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/public`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        search: search,
      })
      .then((resp) => {
        console.log(resp.data["hits"]);
        setOffres(resp.data["hits"]);
      });
  };

  // Init function
  useEffect(() => {
    loadOffres();
  }, []);

  const handleSearchChange = (e) => {
    setSearch(e);
  };
  const handleSearchClick = (e) => {
    if (e.key === "Enter") {
      loadOffres();
    } else return;
  };
  return (
    <Fragment>
      <Header></Header>
      <MDBContainer fluid className="">
        <MDBRow center>
          <MDBCol md="6" sm="12">
            <MDBInput
              icon="search"
              iconSize="2x"
              id="search"
              onChange={(e) => handleSearchChange(e.target.value)}
              onKeyDown={handleSearchClick}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow className="">
          <MDBCol md="12">
            {offres.map((item, index) => {
              return <OffreApercu offre={item} key={index}></OffreApercu>;
            })}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

export default Home;
