import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavFiltre } from "./../Contextes/navfiltreContext";
import AmChartLine from "../components/chart/AmChartLine";
import axios from "axios";
import SelectSearch from "react-select-search";
import Indicateur from "../components/widget/Indicateur";
const Secteur = () => {
  // filtre
  const { filtre, setFiltre } = useNavFiltre();
  const [graph, setGraph] = useState([]);
  const [size, setSize] = useState(10);
  //get salaire aggr
  const getSecteurAggr = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/secteurAggr`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        filtre: filtre,
        size: parseInt(size),
      })
      .then((resp) => {
        let data = resp.data.aggregations.secteurs.buckets;
        // console.log(data);
        setGraph(data);
      });
  };

  // Init function
  useEffect(() => {
    setGraph([]);
    getSecteurAggr();
  }, [filtre, size]);

  return (
    <Fragment>
      {" "}
      <Indicateur></Indicateur>
      <div className="width-10">
        <SelectSearch
          onChange={setSize}
          closeOnSelect={true}
          printOptions="on-focus"
          placeholder="Top 10"
          options={[
            { name: "Top 10", value: "10" },
            { name: "Top 15", value: "15" },
            { name: "Top 20", value: "20" },
            { name: "Top 25", value: "25" },
            { name: "Top 30", value: "30" },
          ]}
        />
      </div>
      {graph.length > 0 && (
        <AmChartLine
          typeData="secteurs"
          id="devSecteurs"
          title="Top Secteurs"
          label=""
          data={graph}
          fill="#42a5f5"
        ></AmChartLine>
      )}
    </Fragment>
  );
};

export default Secteur;
