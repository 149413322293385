import React, { Fragment, useState, useEffect, useRef } from "react";
import Truncate from "react-truncate";
import {
  MDBMask,
  MDBView,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCardBody,
  MDBBtn,
} from "mdbreact";
export const OffreApercu = (props) => {
  return (
    <MDBCardBody className="pb-0">
      <h4 className="font-weight-bold my-3">{props.offre._source.intitule}</h4>
      {props.offre._source.entreprise && (
        <small>{props.offre._source.entreprise.nom} - </small>
      )}
      <small>{props.offre.fields.dateCreation}</small>
      <p className="grey-text">
        <Truncate
          lines={3}
          ellipsis={
            <span>
              ... <a href="/">voir plus</a>
            </span>
          }
        >
          {props.offre._source.description}
        </Truncate>
      </p>
      {/*<MDBBtn color="indigo" size="sm">
      <MDBIcon far icon="clone" className="left" />
        Voir plus
      </MDBBtn>
        */}
    </MDBCardBody>
  );
};
export default OffreApercu;
