import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavFiltre } from "./../Contextes/navfiltreContext";
import TreeMap from "../components/chart/TreeMap";
import axios from "axios";

import Indicateur from "../components/widget/Indicateur";
const Formation = (props) => {
  // filtre
  const { filtre } = useNavFiltre();
  const [graph, setGraph] = useState([]);

  //get salaire aggr
  const getFormationAggr = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/formationAggr`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        filtre: filtre,
      })
      .then((resp) => {
        let data = resp.data.aggregations.formations.buckets;
        // console.log(data);
        setGraph(data);
      });
  };

  // Init function
  useEffect(() => {
    setGraph([]);
    getFormationAggr();
  }, [filtre]);

  return (
    <Fragment>
      {" "}
      <Indicateur></Indicateur>
      {graph.length > 0 && (
        <TreeMap
          typeData="formations"
          id="typedomainesDev1"
          data={graph}
          title="Domaines et niveaux de formations recherchés"
        ></TreeMap>
      )}
    </Fragment>
  );
};
export default Formation;
