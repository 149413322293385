import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import {
  MDBIcon,
  MDBNotification,
  MDBInput,
  MDBBtn,
  MDBRow,
  MDBCol,
} from "mdbreact";
import SelectSearch from "react-select-search";
import DataTable, { createTheme } from "react-data-table-component";
import Moment from "moment";
import { IconButton } from "@material-ui/core";
export default function AdminMessages() {
  const [users, setUsers] = useState([]);
  const getUsers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/messages`)
      .then((resp) => {
        setUsers(resp.data);
      });
  };

  useEffect(() => {
    getUsers();
    return () => {
      setUsers([]);
    };
  }, []);

  createTheme("solarized", {
    text: {
      primary: "#268bd2",
      secondary: "#2aa198",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  });
  const columns = [
    {
      name: "Nom ",
      selector: "nom",
      sortable: true,
    },
    {
      name: "email ",
      selector: "email",
      sortable: true,
    },
    {
      name: "objet",
      selector: "objet",
      sortable: true,
    },
    {
      name: "message",
      selector: "message",
      sortable: true,
      minWidth: "50%",
    },

    {
      name: "Date ",
      selector: "date",
      sortable: true,
      cell: (row) => Moment(row.date).format("DD/MM/YYYY"),
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
  };
  return (
    <Fragment>
      {users.length > 0 && (
        <DataTable
          defaultSortAsc="false"
          noHeader="true"
          pagination="True"
          paginationPerPage="5"
          title=""
          columns={columns}
          data={users}
          //   theme="solarized"
        />
      )}
    </Fragment>
  );
}
