import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBListGroup,
  MDBListGroupItem,
  MDBContainer,
} from "mdbreact";
import { useNavFiltre } from "../Contextes/navfiltreContext";

import axios from "axios";

const SkillNorm = () => {
  const { filtreRAM, addToFiltre, removeFromFiltre } = useNavFiltre();
  const nl2br = require("react-nl2br");
  const [data, setData] = useState([]);
  const [text, setText] = useState(
    "Vous développez le portefeuille d'affaires d'une centaine de prospects et clients, pilotez les projets dans leur globalité, du besoin à la mise en place (mobile, voix sur IP, WAN (VPN MPLS ; SDwan), UCaaS, Sécurité). \nVous serez amené à échanger avec différents interlocuteurs ainsi que le management de l'entreprise."
  );
  const [phrases, setPhrases] = useState([]);
  const [skills, setSkills] = useState([]);
  const [uniqueSkills, setUniqueSkills] = useState([]);
  const [openInput, setOpenInput] = useState(true);
  const get = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/skillnorm`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        text: text,
      })
      .then((resp) => {
        let d = resp.data;
        // console.log(resp.data);
        //setData(resp.data);

        var arayUnique = [];
        let skillsBrut = d[d.length - 1]["skills"];
        skillsBrut.sort(function (a, b) {
          return b.degre - a.degre;
        });
        skillsBrut.forEach((element) => {
          if (arayUnique.includes(element.libelle) == false)
            arayUnique = [...arayUnique, element.libelle];
        });
        setUniqueSkills(arayUnique);
        setSkills(d[d.length - 1]["skills"]);
        d.pop();
        setPhrases(d);
        setOpenInput(false);
        // if (resp.data.length > 0) {
        //   console.log("****skills****");
        //   console.log(data[data.length - 1]["skills"]);
        //   var arayUnique = [];
        //   resp.data[data.length - 1]["skills"].forEach((element) => {
        //     if (arayUnique.includes(element.libelle) == false)
        //       arayUnique = [...arayUnique, element.libelle];
        //   });
        //   setUniqueSkills(arayUnique);
        //   console.log("****phrases****");
        //   data.pop();
        //   console.log(data);
        //   setPhrases(data);
        //   setOpenInput(false);
        // }
      });
  };
  const getbtn = () => {
    get();
  };
  const getSkills = (i) => {
    let result = skills.filter((word) => word.id == i);
    result.sort(function (a, b) {
      return b.degre - a.degre;
    });
    setUniqueSkills(result.map((x) => x.libelle));
  };

  return (
    <Fragment>
      {openInput == true && (
        <MDBRow>
          <MDBCol className="mt-5 ">
            <MDBInput
              type="textarea"
              label="Offre"
              rows="5"
              icon="pencil-alt"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <MDBBtn
              rounded
              outline
              color="info"
              size="sm"
              className="ml-3 animated  bounceIn "
              onClick={getbtn}
            >
              OK
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      )}
      {openInput == false && (
        <MDBContainer>
          <MDBRow>
            <MDBCol size="2">
              <MDBBtn
                rounded
                outline
                color="warning"
                size="sm"
                className="ml-3 animated  bounceIn "
                onClick={(e) => setOpenInput(true)}
              >
                Reset
              </MDBBtn>
            </MDBCol>
            <MDBCol size="8"></MDBCol>
            <MDBCol size="2">
              {" "}
              <MDBBtn
                rounded
                outline
                color="danger"
                size="sm"
                className="ml-3 animated  bounceIn "
                onClick={(e) => getbtn()}
              >
                x
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12"></MDBCol>
            <MDBCol size="12">
              <div className="ml-3 mt-2">
                {phrases.length > 0 &&
                  phrases.map(function (item, i) {
                    if (item.id > -1) {
                      if (item.noise === false) {
                        return (
                          <b
                            key={i}
                            class="blue-text bold"
                            style={{
                              cursor: "zoom-in",
                              whiteSpace: "pre-line",
                            }}
                            onClick={(e) => getSkills(i)}
                          >
                            {item.text.replace(/\\n/, " ")}
                          </b>
                        );
                      } else {
                        return nl2br(item.text);
                      }
                    }
                  })}
              </div>
            </MDBCol>

            <MDBCol className="mt-2">
              <ul>
                {uniqueSkills.length > 0 &&
                  uniqueSkills.map(function (item, i) {
                    return (
                      <li key={i}>
                        <a onClick={(e) => addToFiltre("competences", item)}>
                          {i + 1} : {item}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )}
    </Fragment>
  );
};

export default SkillNorm;
