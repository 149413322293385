import React, {Component, Fragment, useState, useEffect} from "react";
import "./Header.css";
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavItem,
    MDBNavLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBIcon,
    MDBContainer,
    MDBBtn,
} from "mdbreact";
import {BrowserRouter as Router, Link} from "react-router-dom";
import {useLocation} from "react-router-dom";

const Header = (props) => {
    const [path, setPath] = useState(["/", "/login", "/signin", "/contact"]);
    const [privatePath, setPrivatePath] = useState(false);
    const [home, setHome] = useState("/");
    const [state, setState] = useState({
        location: useLocation(),
        isOpen: false,
        token: localStorage.getItem("tokens"),
        nomcomplet:
            localStorage.getItem("prenom") + " " + localStorage.getItem("nom"),
    });
    const [role, setRole] = useState();
    //alert(localStorage.getItem("role"));
    const toggleCollapse = () => {
        setState({state, isOpen: !state.isOpen});
    };
    useEffect(() => {
        console.log('role', role)
    })
    // Init function
    useEffect(() => {
        setRole(localStorage.getItem("role"));
        if (path.includes(window.location.pathname)) {
            setPrivatePath(false);
            setHome("/");
        } else {
            setPrivatePath(true);
            setHome("/dashboard");
        }
    }, []);
    return (
        <MDBNavbar
            className="p-2"
            color="dream "
            dark
            fixed="top"
            expand="md"
            style={{boxShadow: "none"}}
        >
            <MDBContainer fluid>
                <MDBNavbarBrand>
                    <Link to={home}>
                        <strong className="white-text">
                            <img src="/logo.png" height="40px"></img>
                        </strong>
                    </Link>
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={toggleCollapse}/>
                <MDBCollapse id="navbarCollapse3" isOpen={state.isOpen} navbar>
                    {privatePath && (
                        <MDBNavbarNav left className="">
                            <MDBNavItem>
                                <MDBNavLink to="/dashboard/tendance">Tendance</MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to="/dashboard/salaire">Salaires</MDBNavLink>
                            </MDBNavItem>
                            {role === '"admin"' && (
                                <MDBNavItem>
                                    <MDBNavLink to="/dashboard/secteur">Secteurs</MDBNavLink>
                                </MDBNavItem>
                            )}
                            <MDBNavItem>
                                <MDBNavLink to="/dashboard/metier">Métiers</MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to="/dashboard/rome"> ROME</MDBNavLink>
                            </MDBNavItem>
                            {role === '"admin"' && (
                                <MDBNavItem>
                                    <MDBNavLink to="/dashboard/recruteur"> Recruteurs</MDBNavLink>
                                </MDBNavItem>
                            )}
                            <MDBNavItem>
                                <MDBNavLink to="/dashboard/contrat">
                                    {" "}
                                    {/* Types de contrats{" "} */}
                                    Contrats
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                {/* <MDBNavLink to="/dashboard/competence"> Compétences</MDBNavLink> */}

                                <MDBDropdown>
                                    <MDBDropdownToggle nav caret color="primary" dropleft>
                                        Compétences
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu className="dropdown-indigo  nav-user-menu">
                                        <MDBDropdownItem>
                                            <Link to="/dashboard/competence">
                                                Compétences opérationnelles
                                            </Link>
                                        </MDBDropdownItem>
                                        <MDBDropdownItem>
                                            <Link to="/dashboard/softSkills">Soft Skills</Link>
                                        </MDBDropdownItem>
                                        <MDBDropdownItem>
                                            <Link to="/dashboard/programmationSkills">
                                                Langages de programmation
                                            </Link>
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBNavItem>

                            {role !== '"light"' && (
                                <MDBNavItem>
                                    <MDBNavLink to="/dashboard/formation"> Formations</MDBNavLink>
                                </MDBNavItem>
                            )}

                            <MDBNavItem>
                                <MDBNavLink to="/dashboard/offres"> Offres</MDBNavLink>
                            </MDBNavItem>

                            {role === '"admin"' && (
                                <MDBNavItem>
                                    <MDBDropdown>
                                        <MDBDropdownToggle nav caret color="primary" dropleft>
                                            Norm
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu className="dropdown-indigo  nav-user-menu">
                                            <MDBDropdownItem>
                                                <Link to="/dashboard/SkillNorm">SkillNorm</Link>
                                            </MDBDropdownItem>
                                            {/* <MDBDropdownItem>
                        <Link to="/dashboard/Cv">CVNorm0</Link>
                      </MDBDropdownItem> */}
                                            <MDBDropdownItem>
                                                <Link to="/dashboard/Cv1">CVNorm</Link>
                                            </MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavItem>
                            )}

                            {role === '"admin"' && (
                                <MDBNavItem>
                                    <MDBDropdown>
                                        <MDBDropdownToggle nav caret color="primary" dropleft>
                                            <MDBIcon icon="users" className="mr-2 white-text"/>
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu className="dropdown-indigo  nav-user-menu">
                                            <MDBDropdownItem>
                                                <Link to="/admin/users">Users</Link>
                                            </MDBDropdownItem>
                                            <MDBDropdownItem>
                                                <Link to="/admin/messages">Messages</Link>
                                            </MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavItem>
                            )}
                        </MDBNavbarNav>
                    )}

                    <MDBNavbarNav right>
                        {!privatePath ? (
                            <Fragment>
                                <MDBNavItem>
                                    <MDBNavLink to="/contact" className="white-text">
                                        <MDBBtn color="success" rounded size="sm">
                                            Demander une démo
                                        </MDBBtn>
                                    </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="/login" className="white-text">
                                        <MDBBtn
                                            rounded
                                            outline
                                            color="success"
                                            size="sm"
                                            id="connect-btn"
                                        >
                                            Se connecter
                                        </MDBBtn>
                                    </MDBNavLink>
                                </MDBNavItem>
                            </Fragment>
                        ) : (
                            <MDBNavItem>
                                <MDBDropdown>
                                    <MDBDropdownToggle nav caret color="primary" dropleft>
                                        <MDBIcon icon="user" className="mr-2 white-text"/>
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu className="dropdown-indigo  nav-user-menu">
                                        <MDBDropdownItem>
                                            <Link to="/logout">Logout</Link>
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBNavItem>
                        )}
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBContainer>
        </MDBNavbar>
    );
};

export default Header;
