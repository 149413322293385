import React, { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import CountUp from "react-countup";
import ImageUploader from "react-images-upload";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavFiltre } from "./../Contextes/navfiltreContext";
import "./cv.css";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBCardBody,
  MDBCard,
  MDBModalHeader,
  MDBContainer,
  MDBModalBody,
  MDBIcon,
  MDBModalFooter,
  MDBBtnGroup,
  MDBCollapseHeader,
  MDBCollapse,
  MDBCardTitle,
  MDBCardText,
} from "mdbreact";
const Cv = (props) => {
  // filtre
  const { filtre } = useNavFiltre();
  const [Cvs, setCvs] = useState([]);
  const [CvText, setCvText] = useState("");
  const [load, setLoad] = useState(false);
  const [loadgaP, setLoadgaP] = useState(0);
  const [gapData, setGapData] = useState("");
  const [selectedCompetences, setSelectedCompetences] = useState([]);
  const [
    competenceOperationnelleLength,
    setCompetenceOperationnelleLength,
  ] = useState(0);
  const [competenceOperationnelles, setCompetenceOperationnelles] = useState(
    []
  );
  const [state, setState] = useState({
    collapseID: "1",
  });
  const [stateGap, setStateGap] = useState({
    collapseID: "gap_4",
  });
  const toggleCollapse = (collapseID) => () =>
    setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  const toggleCollapseGap = (collapseID) => () =>
    setStateGap((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  // Init function
  // add selected competences

  function addSelectedCompences(comp) {
    setSelectedCompetences([...selectedCompetences, comp]);
  }
  useEffect(() => {
    if (CvText) gapFunct();
  }, [filtre]);
  async function onDrop(pictureFiles, pictureDataURLs) {
    setGapData(null);
    setGapData(null);
    setCvText({});
    setLoad(true);
    let cvTXT = await upload([pictureFiles]);
    // cvTXT = JSON.parse(cvTXT);
    setLoad(false);

    // order by degree
    let lastCompetenceOperationelles = cvTXT.competence_operationnelle;
    lastCompetenceOperationelles.sort(compare_degre);

    lastCompetenceOperationelles = lastCompetenceOperationelles.filter(
      (word) => word.degre > 0.43
    );
    setCompetenceOperationnelleLength(lastCompetenceOperationelles.length);

    setCompetenceOperationnelles(lastCompetenceOperationelles);
    setCvText(cvTXT);
  }
  function compare_degre(a, b) {
    if (a.degre > b.degre) {
      return -1;
    }
    if (a.degre < b.degre) {
      return 1;
    }
    return 0;
  }
  const removeCompetenceOperationnelle = (i) => {
    // alert(i);
    let interMedVAr = competenceOperationnelles;
    interMedVAr.splice(i, 1);
    setCompetenceOperationnelles(interMedVAr);
    setCompetenceOperationnelleLength(interMedVAr.length);
  };
  async function gapFunct() {
    setLoadgaP(1);
    let matchURL = "http://40.89.138.96:7969/match";
    let competences_operationelles_CV = competenceOperationnelles;

    const dataCompetenceBrut = await axios.post(
      `${process.env.REACT_APP_API_URL}/dashbord/competenceTableAggr`,
      {
        filtre: filtre,
      }
    );
    let competences_filtre = dataCompetenceBrut.data.map((a) => a.key);

    // send  comptence cv  and  competences_filtre
    const gapDATABrut = await axios.post(
      `${process.env.REACT_APP_API_URL}/dashbord/cvGap`,
      {
        competences_operationelles_CV: competences_operationelles_CV.map(
          (a) => a.libelle
        ),
        competences_filtre: competences_filtre,
      }
    );
    setLoadgaP(2);
    setGapData(gapDATABrut.data);
  }
  //upload files
  async function upload(files) {
    const formData = new FormData();
    formData.append("cv", files[0][0]);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/dashbord/uploadCvNorm`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    return response.data;
  }

  async function analyseCV() {}
  return (
    <Fragment>
      <MDBRow>
        <MDBCol size="12" className="text-center mb-2">
          <ImageUploader
            accept="*"
            fileContainerStyle={{ width: "100%" }}
            withLabel={false}
            singleImage={true}
            withPreview={false}
            withIcon={false}
            buttonText="Télécharger un CV"
            onChange={(e, a) => {
              onDrop(e, a);
            }}
            imgExtension={[".pdf"]}
            maxFileSize={155242880}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12" className="text-center mb-2">
          {load ? (
            <div>
              Traitement en cours
              <ScaleLoader size={10} color={"#303950"} loading={true} />
            </div>
          ) : (
            CvText && (
              <Fragment>
                <MDBContainer className="pt-0 mt-0">
                  <MDBContainer className="md-accordion ">
                    <MDBCard>
                      <MDBCollapseHeader
                        onClick={toggleCollapse(1)}
                        tag="h5"
                        ClassName="p-0"
                        tagClassName="indigo-text d-flex justify-content-between align-items-center "
                      >
                        {CvText && CvText.competence_operationnelle && (
                          <CountUp
                            delay={0}
                            start={competenceOperationnelleLength}
                            end={competenceOperationnelleLength}
                            duration={0}
                            separator=" "
                            decimals={0}
                            decimal=""
                            prefix=""
                            suffix=" "
                          />
                        )}{" "}
                        Compétences opérationnelles
                        <MDBIcon
                          icon={
                            state.collapseID === 1 ? "angle-up" : "angle-down"
                          }
                          className="indigo-text"
                          size="2x"
                        />
                      </MDBCollapseHeader>
                      <MDBCollapse id={1} isOpen={state.collapseID}>
                        <MDBCardBody class="pt-0">
                          <ul
                            style={{ listStyleType: "none" }}
                            className="pl-2 ml-0"
                          >
                            {competenceOperationnelles.length > 0 &&
                              competenceOperationnelles.map(function (item, i) {
                                return (
                                  <li className="text-left">
                                    <span
                                      onClick={(e) =>
                                        removeCompetenceOperationnelle(i)
                                      }
                                      className="red-text pointer font-weight-bold pr-2"
                                    >
                                      X{" "}
                                    </span>
                                    {"  "}
                                    {item.libelle} [
                                    {(item.degre * 100).toFixed(1)} %]
                                  </li>
                                );
                              })}
                          </ul>
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>

                    {/* <MDBCard>
                    <MDBCollapseHeader
                      onClick={toggleCollapse(2)}
                      tag="h5"
                      ClassName="p-0"
                      tagClassName="indigo-text d-flex justify-content-between align-items-center "
                    >
                      {CvText &&
                        CvText.norm &&
                        CvText.norm["Soft skill"] &&
                        CvText.norm["Soft skill"].length}{" "}
                      Soft skill
                      <MDBIcon
                        icon={
                          state.collapseID === 2 ? "angle-up" : "angle-down"
                        }
                        className="indigo-text"
                        size="2x"
                      />
                    </MDBCollapseHeader>
                    <MDBCollapse id={2} isOpen={state.collapseID}>
                      <MDBCardBody class="pt-0">
                        <ul>
                          {CvText &&
                            CvText.norm &&
                            CvText.norm["Soft skill"].map(function (item, i) {
                              return (
                                <li className="text-left">{item.libelle}</li>
                              );
                            })}
                        </ul>
                      </MDBCardBody>
                    </MDBCollapse>
                  </MDBCard>
                */}

                    <MDBCard>
                      <MDBCollapseHeader
                        onClick={toggleCollapse(3)}
                        tag="h5"
                        ClassName="p-0"
                        tagClassName="indigo-text d-flex justify-content-between align-items-center "
                      >
                        {CvText && CvText.programation && (
                          <CountUp
                            delay={0}
                            start={0}
                            end={CvText.programation.length}
                            duration={4}
                            separator=" "
                            decimals={0}
                            decimal=""
                            prefix=""
                            suffix=" "
                          />
                        )}{" "}
                        Autres compétences
                        <MDBIcon
                          icon={
                            state.collapseID === 3 ? "angle-up" : "angle-down"
                          }
                          className="indigo-text"
                          size="2x"
                        />
                      </MDBCollapseHeader>
                      <MDBCollapse id={3} isOpen={state.collapseID}>
                        <MDBCardBody class="pt-0">
                          <ul>
                            {CvText &&
                              CvText.programation &&
                              CvText.programation.map(function (item, i) {
                                return (
                                  <li className="text-left">{item.libelle}</li>
                                );
                              })}
                          </ul>
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                  </MDBContainer>
                </MDBContainer>
                <MDBRow>
                  <MDBCol size="12" className="text-center mb-2 mt-5">
                    <MDBBtn
                      rounded
                      outline
                      color="indigo"
                      onClick={(e) => {
                        gapFunct();
                      }}
                    >
                      Mesurer l'écart avec la recherche (Avec le top 15)
                    </MDBBtn>
                    {loadgaP === 1 && (
                      <div className="pt-5">
                        Traitement en cours
                        <ScaleLoader
                          size={10}
                          color={"#303950"}
                          loading={true}
                        />
                      </div>
                    )}
                  </MDBCol>
                </MDBRow>
                {gapData && (
                  <MDBContainer>
                    <MDBContainer className="md-accordion ">
                      <MDBCard>
                        <MDBCollapseHeader
                          onClick={toggleCollapseGap("gap_1")}
                          tag="h5"
                          ClassName="p-0"
                          tagClassName="indigo-text d-flex justify-content-between align-items-center "
                        >
                          {gapData && gapData.bon && (
                            <CountUp
                              delay={0}
                              start={0}
                              end={gapData.bon.offres.length}
                              duration={3}
                              separator=" "
                              decimals={0}
                              decimal=""
                              prefix=""
                              suffix=" "
                            />
                          )}{" "}
                          Match
                          <MDBIcon
                            icon={
                              stateGap.collapseID === "gap_1"
                                ? "angle-up"
                                : "angle-down"
                            }
                            className="indigo-text"
                            size="2x"
                          />
                        </MDBCollapseHeader>
                        <MDBCollapse id={"gap_1"} isOpen={stateGap.collapseID}>
                          <MDBCardBody class="pt-0">
                            <MDBRow>
                              <MDBCol
                                size="6"
                                className=" mb-2 block-example border-right border-dark"
                              >
                                <h6>Résultat de la recherche</h6>
                                <ul>
                                  {gapData &&
                                    gapData.bon &&
                                    gapData.bon.offres.map(function (item, i) {
                                      return (
                                        <li
                                          className="text-left"
                                          onClick={(e) => {
                                            addSelectedCompences(item);
                                          }}
                                        >
                                          click :{item}
                                        </li>
                                      );
                                    })}
                                </ul>
                              </MDBCol>
                              <MDBCol size="6" className=" mb-2">
                                <h6>Compétences du CV</h6>
                                <ul>
                                  {gapData &&
                                    gapData.bon &&
                                    gapData.bon.cvs.map(function (item, i) {
                                      return (
                                        <li className="text-left">{item}</li>
                                      );
                                    })}
                                </ul>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCollapse>
                      </MDBCard>

                      <MDBCard>
                        <MDBCollapseHeader
                          onClick={toggleCollapseGap("gap_2")}
                          tag="h5"
                          ClassName="p-0"
                          tagClassName="indigo-text d-flex justify-content-between align-items-center "
                        >
                          {gapData && gapData.moyen && (
                            <CountUp
                              delay={0}
                              start={0}
                              end={gapData.moyen.offres.length}
                              duration={3}
                              separator=" "
                              decimals={0}
                              decimal=""
                              prefix=""
                              suffix=" "
                            />
                          )}{" "}
                          Compétences proches
                          <MDBIcon
                            icon={
                              stateGap.collapseID === "gap_2"
                                ? "angle-up"
                                : "angle-down"
                            }
                            className="indigo-text"
                            size="2x"
                          />
                        </MDBCollapseHeader>
                        <MDBCollapse id={"gap_2"} isOpen={stateGap.collapseID}>
                          <MDBCardBody class="pt-0">
                            <MDBRow>
                              <MDBCol
                                size="6"
                                className=" mb-2 block-example border-right border-dark"
                              >
                                <h6>Résultat de la recherche</h6>{" "}
                                <ul>
                                  {gapData &&
                                    gapData.moyen &&
                                    gapData.moyen.offres.map(function (
                                      item,
                                      i
                                    ) {
                                      return (
                                        <li className="text-left">{item}</li>
                                      );
                                    })}
                                </ul>
                              </MDBCol>
                              <MDBCol size="6" className=" mb-2 block-example ">
                                <h6>Compétences du CV</h6>
                                <ul>
                                  {gapData &&
                                    gapData.moyen &&
                                    gapData.moyen.cvs.map(function (item, i) {
                                      return (
                                        <li className="text-left">{item}</li>
                                      );
                                    })}
                                </ul>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCollapse>
                      </MDBCard>

                      <MDBCard>
                        <MDBCollapseHeader
                          onClick={toggleCollapseGap("gap_3")}
                          tag="h5"
                          ClassName="p-0"
                          tagClassName="indigo-text d-flex justify-content-between align-items-center "
                        >
                          {gapData && gapData.mediocre && (
                            <CountUp
                              delay={0}
                              start={0}
                              end={gapData.mediocre.offres.length}
                              duration={3}
                              separator=" "
                              decimals={0}
                              decimal=""
                              prefix=""
                              suffix=" "
                            />
                          )}{" "}
                          Compétences éloignées
                          <MDBIcon
                            icon={
                              stateGap.collapseID === "gap_3"
                                ? "angle-up"
                                : "angle-down"
                            }
                            className="indigo-text"
                            size="2x"
                          />
                        </MDBCollapseHeader>
                        <MDBCollapse id={"gap_3"} isOpen={stateGap.collapseID}>
                          <MDBCardBody class="pt-0">
                            <MDBRow>
                              <MDBCol
                                size="6"
                                className=" mb-2 block-example border-right border-dark"
                              >
                                <h6>Résultat de la recherche</h6>
                                <ul>
                                  {gapData &&
                                    gapData.mediocre &&
                                    gapData.mediocre.offres.map(function (
                                      item,
                                      i
                                    ) {
                                      return (
                                        <li className="text-left">{item}</li>
                                      );
                                    })}
                                </ul>
                              </MDBCol>
                              <MDBCol size="6" className=" mb-2 block-example">
                                <h6>Compétences du CV</h6>{" "}
                                <ul>
                                  {gapData &&
                                    gapData.mediocre &&
                                    gapData.mediocre.cvs.map(function (
                                      item,
                                      i
                                    ) {
                                      return (
                                        <li className="text-left">{item}</li>
                                      );
                                    })}
                                </ul>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCollapse>
                      </MDBCard>
                    </MDBContainer>

                    <MDBContainer>
                      {/* formation */}
                      {selectedCompetences}
                    </MDBContainer>
                  </MDBContainer>
                )}
              </Fragment>
            )
          )}
        </MDBCol>
      </MDBRow>
    </Fragment>
  );
};
export default Cv;
