import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_fr_FR from "@amcharts/amcharts4/lang/fr_FR";
import { useNavFiltre } from "../../Contextes/navfiltreContext";
//import { useNavFiltre } from "../../Contextes/navfiltreContext";

am4core.useTheme(am4themes_animated);

const AmChartLine = (props) => {
  //filtre global
  const { addToFiltre } = useNavFiltre();

  const chart = useRef(null);

  useLayoutEffect(() => {
    /* init chart */
    let x = am4core.create(props.id, am4charts.XYChart);
    x.language.locale = am4lang_fr_FR;
    x.paddingRight = 20;

    /*title*/
    let title = x.titles.create();
    title.text = props.title;
    title.align = "left";
    title.fontSize = 15;
    title.fontWeight = 600;
    title.marginBottom = 10;
    title.fill = "#303950";

    /*label*/
    let label = x.chartContainer.createChild(am4core.Label);
    label.text = props.label;
    label.align = "center";
    label.fontSize = 15;
    label.fontWeight = 600;
    label.marginBottom = 10;
    label.fill = "#303950";

    /* export data
    x.exporting.title = props.title;
    x.exporting.menu = new am4core.ExportMenu();
     */

    /*
    let data = [];
    props.data.forEach((element, i) => {
      data.push({
        date: element.key,
        value: element.doc_count,
      });
    });
*/

    x.data = props.data.reverse();
    // console.log("x.data");
    // console.log(x.data);
    let categoryAxis = x.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "key";
    //categoryAxis.renderer.inside = true;
    //categoryAxis.renderer.maxLabelPosition = 0.99;
    categoryAxis.renderer.minGridDistance = 3;
    // categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minWidth = 150;
    categoryAxis.fontSize = 12;
    let labelCategoryAxis = categoryAxis.renderer.labels.template;
    labelCategoryAxis.truncate = true;
    labelCategoryAxis.maxWidth = 400;
    labelCategoryAxis.align = "right";
    labelCategoryAxis.fontSize = 12;
    labelCategoryAxis.fontWeight = 400;

    let valueAxis = x.xAxes.push(new am4charts.ValueAxis());
    valueAxis.fontSize = 12;
    // valueAxis.renderer.minWidth = 35;

    let series = x.series.push(new am4charts.ColumnSeries());
    series.fill = props.fill;
    series.dataFields.categoryY = "key";
    series.dataFields.valueX = "doc_count";
    series.tooltipText = "{valueY.value}";
    x.cursor = new am4charts.XYCursor();
    series.fontSize = 8;
    // let scrollbarX = new am4charts.XYChartScrollbar();
    // scrollbarX.series.push(series);
    // x.scrollbarX = scrollbarX;
    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    series.columns.template.events.on("hit", function (ev) {
      if (props.typeData !== "softskills") {
        let a = ev.target;
        let value = a._dataItem.dataContext["key"];
        addToFiltre(props.typeData, value);
      }
    });
    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  return <div id={props.id} style={{ width: "95%", height: "400px" }}></div>;
};

export default AmChartLine;
