import React, {useState} from "react";
import axios from "axios";
import {
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBBtn,
    MDBInput,
} from "mdbreact";
import {submit_hubspot_form} from "../../utils/hubspot";

const Contact = () => {
    const [message, setMessage] = useState("");
    const [data, setData] = useState({
        firstname: null,
        lastname: null,
        email: null,
        message: null,
    });

    const submitHandlerLogin = async (event) => {
        event.preventDefault();
        event.target.className += " was-validated";

        if (event.target.reportValidity()) {
            const {firstname, lastname, email, message} = data;
            await submit_hubspot_form(email, firstname, lastname, message)
            setMessage("Merci, votre message a été envoyé");

            setData({
                firstname: null,
                lastname: null,
                email: null,
                message: null,
            });
            // axios
            //   .post(`${process.env.REACT_APP_API_URL}/public/contacter`, {
            //     data: data,
            //   })
            //   .then(
            //     (response) => {

            //     },
            //     (error) => {
            //       alert("error");
            //     }
            //   );
        }
    };
    return (
        <section className="mt-5 my-5">
            <MDBRow>
                <MDBCol lg="5">
                    <MDBCard>
                        <MDBCardBody>
                            <div className="form-header dream accent-1">
                                <h4 className="mt-2">
                                    <MDBIcon icon="envelope" className="white-text"/> Contact
                                </h4>
                            </div>
                            {message ? (
                                <h4 className="my-5">{message}</h4>
                            ) : (
                                <form
                                    id="create-course-form"
                                    className="needs-validation"
                                    onSubmit={(e) => {
                                        submitHandlerLogin(e);
                                    }}
                                    noValidate
                                >
                                    <div className="md-form">
                                        <MDBInput
                                            icon="user"
                                            hint="Prénom"
                                            iconClass=""
                                            type="text"
                                            id="form-name"
                                            required
                                            onChange={(e) => {
                                                setData({...data, firstname: e.target.value});
                                            }}
                                        />
                                    </div>
                                    <div className="md-form">
                                        <MDBInput
                                            icon="tag"
                                            hint="Nom de famille"
                                            iconClass=""
                                            type="text"
                                            id="form-subject"
                                            required
                                            onChange={(e) => {
                                                setData({...data, lastname: e.target.value});
                                            }}
                                        />
                                    </div>
                                    <div className="md-form">
                                        <MDBInput
                                            icon="envelope"
                                            hint="Votre email"
                                            iconClass=""
                                            type="email"
                                            id="form-email"
                                            required
                                            onChange={(e) => {
                                                setData({...data, email: e.target.value});
                                            }}
                                        />
                                    </div>
                                    <div className="md-form">
                                        <MDBInput
                                            icon="pencil-alt"
                                            hint="Votre message"
                                            iconClass=""
                                            type="textarea"
                                            id="form-text"
                                            required
                                            onChange={(e) => {
                                                setData({...data, message: e.target.value});
                                            }}
                                        />
                                    </div>
                                    <div className="text-center">
                                        <MDBBtn color="success" type="submit" rounded>
                                            Envoyer{" "}
                                        </MDBBtn>
                                    </div>
                                </form>
                            )}
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol lg="7">
                    <div
                        id="map-container"
                        className="rounded z-depth-1-half map-container"
                        style={{height: "370px"}}
                    >
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.208926473108!2d2.310714915050068!3d48.873293579288884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66fc8bd037d4b%3A0x3393160f5312a17a!2sLe%20Village%20by%20CA!5e0!3m2!1sfr!2sfr!4v1599126379834!5m2!1sfr!2sfr"
                            title="This is a unique title"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            style={{border: 0}}
                        />
                    </div>
                    <br/>
                    <MDBRow className="text-center">
                        <MDBCol md="6">
                            <MDBBtn tag="a" floating color="" className="dream accent-1">
                                <MDBIcon icon="map-marker-alt"/>
                            </MDBBtn>
                            <p>55 Rue la Boétie, 75008 Paris, France</p>
                        </MDBCol>
                        <MDBCol md="6">
                            <MDBBtn tag="a" floating color="" className="dream accent-1">
                                <MDBIcon icon="envelope"/>
                            </MDBBtn>
                            <p>contact@trouvetavoie.io</p>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
        </section>
    );
};

export default Contact;
