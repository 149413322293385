import React, { Fragment, useState, useEffect } from "react";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBListGroup,
  MDBListGroupItem,
  toast,
  MDBTooltip,
  MDBInputGroup,
  MDBIcon,
} from "mdbreact";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import SelectSearch from "react-select-search";
import Slider from "@material-ui/core/Slider";
import { useNavFiltre } from "../../Contextes/navfiltreContext";
import NavFilter from "../widget/NavFilter";
const SideBar = (props) => {
  //filtre global
  const { filtre, setFiltre, filtreRAM, setFiltreRAM } = useNavFiltre();
  /************************************************************************************/
  /* for used for search  */
  // mot  ou phrase a recherché
  const [search, setSearch] = useState("");
  //  champs de rechrches
  const [intitule, setIntitule] = useState(true);
  const [description, SetDescription] = useState(true);
  //periode
  const [dateFilter, setDateFilter] = useState([]);
  //regions
  const [search_regions, setSearchRegion] = useState([]);
  //TypesContrat
  const [search_types_contrat, setSearchTypesContrat] = useState([]);
  //NiveauEtude
  const [search_niveau_etude, setSearchNiveauEtude] = useState([]);
  //DureeTravail
  const [search_duree_travail, setSearchDureeTravail] = useState([]);
  //Salaire
  const [search_salaire, setSearchSalaire] = useState([]);

  //alternance
  const [alternance, setAlternance] = useState(false);

  /*end  for used for search  */
  /************************************************************************************/

  const [regions, setRegions] = useState([
    { name: "Provence-Alpes-Côte d'Azur", value: "93" },
    { name: "Pays de la Loire", value: "52" },
    { name: "Occitanie", value: "76" },
    { name: "Nouvelle-Aquitaine", value: "75" },
    { name: "Normandie", value: "28" },
    { name: "Mayotte", value: "06" },
    { name: "Martinique", value: "02" },
    { name: "La Réunion", value: "04" },
    { name: "Ile-de-France", value: "11" },
    { name: "Hauts-de-France", value: "32" },
    { name: "Guyane", value: "03" },
    { name: "Guadeloupe", value: "01" },
    { name: "Grand Est", value: "44" },
    { name: "Corse", value: "94" },
    { name: "Centre-Val de Loire", value: "24" },
    { name: "Bretagne", value: "53" },
    { name: "Bourgogne-Franche-Comté", value: "27" },
    { name: "Auvergne-Rhône-Alpes", value: "84" },
  ]);

  // Init function
  useEffect(() => {
    // alert("datachange");
    setSearch(filtre.search);
    setDateFilter(filtre.period);
    setSearchSalaire(filtre.salaire);
    //   alert("ipdate");
  }, [filtre]);

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      updateFiltre();
    }
  };

  // const reset

  const reset = () => {
    var now = new Date();
    setIntitule(true);
    SetDescription(true);
    setFiltreRAM({
      ...filtreRAM,
      competences: [],
      formations: [],
      secteurs: [],
      metiers: [],
      romes: [],
      entreprises: [],
    });
    setFiltre({
      ...filtre,
      alternance: false,
      search: "",
      fields: ["intitule", "description"],
      period: [new Date(now.setDate(now.getDate() - 180)), new Date()],
      regions: [],
      typeContrat: [],
      niveauEtude: [],
      DureeTravail: [],
      salaire: [0, 15000],
      competences: [],
      formations: [],
      secteurs: [],
      metiers: [],
      romes: [],
      entreprises: [],
    });
  };
  // function to exist when click to serach
  const updateFiltre = () => {
    //field to search
    let fields = [];
    if (intitule) fields.push("intitule");
    if (description) fields.push("description");

    //check if max date > 3mois
    let oldDate = new Date();
    let mois = new Date(
      oldDate.getFullYear(),
      oldDate.getMonth() - 6,
      oldDate.getDate()
    );
    if (mois > dateFilter[0]) {
      toast.warn(
        "Cette version vous permet d'analyser la tendance seulement sur une période de trois mois à partir de ce jour",
        {
          closeButton: true,
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );

      // alert("max les 3 dernier mois");
      return false;
    }

    //update serach key
    setFiltre({
      ...filtre,
      alternance: alternance,
      period: dateFilter,
      search: search,
      fields: fields,
      regions: search_regions,
      typeContrat: search_types_contrat,
      niveauEtude: search_niveau_etude,
      DureeTravail: search_duree_travail,
      salaire: search_salaire,
      competences: filtreRAM.competences,
      formations: filtreRAM.formations,
      secteurs: filtreRAM.secteurs,
      metiers: filtreRAM.metiers,
      romes: filtreRAM.romes,
      entreprises: filtreRAM.entreprises,
    });
    //  setFiltre({ ...filtre, fields: search_place });
  };

  //modal logic
  const [modalOpened, setModalOpened] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalType, setModalType] = useState("");
  function openModal() {
    setModalOpened(!modalOpened);
  }
  const loadModalData = (titre, type) => {
    // alert(type);
    setModalTitle(titre);
    setModalType(type);
    openModal();
  };

  return (
    <div className=" px-2 pt-3 dream-text sidenav">
      {/* Modal */}
      <MDBModal
        size="md"
        position="bottom-left"
        isOpen={modalOpened}
        toggle={openModal}
      >
        <MDBModalHeader
          toggle={openModal}
          className="dream white-text pt-2 pb-2"
        >
          {modalTitle}
        </MDBModalHeader>
        <MDBModalBody>
          <NavFilter type={modalType} openModal={openModal}></NavFilter>
        </MDBModalBody>
      </MDBModal>
      {/* Modal end */}
      <MDBRow>
        <MDBCol md="12" className="">
          <div style={{}}>
            <MDBRow>
              <MDBCol md="12" className="dream-text">
                <small> Période</small>
                <DateRangePicker onChange={setDateFilter} value={dateFilter} />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="12">
                <MDBRow>
                  <MDBCol md="11 " className="pt-2">
                    <div>
                      <MDBInputGroup
                        material
                        className="dream-text sidebar_recherche_input"
                        onKeyDown={_handleKeyDown}
                        onChange={(e) => setSearch(e.target.value)}
                        hint="Votre recherche"
                        type="text"
                        value={search}
                        append={
                          <small>
                            <MDBTooltip placement="top">
                              <MDBBtn color="" className="infobull" size="sm">
                                <MDBIcon icon="info-circle"></MDBIcon>
                              </MDBBtn>
                              <div>
                                Vous pouvez utiliser AND ou OR pour effectuer
                                une recherche combinée
                              </div>
                            </MDBTooltip>
                          </small>
                        }
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md="1 " className="pt-2"></MDBCol>
                </MDBRow>
                {/* Material checked */}
                <MDBRow>
                  <MDBCol md="4" className="pt-2">
                    {/* Material checked */}
                    <MDBInput
                      label="Intitulé"
                      checked={intitule}
                      onChange={(e) => {
                        setIntitule(!intitule);
                      }}
                      type="checkbox"
                      id="intitule"
                    />{" "}
                  </MDBCol>
                  <MDBCol md="4" className="pt-2">
                    <MDBInput
                      type="checkbox"
                      label="Description"
                      id="description"
                      checked={description}
                      onChange={(e) => {
                        SetDescription(!description);
                      }}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="4" className="pt-2">
                {/* Material checked */}
                <MDBInput
                  label="Alternances"
                  checked={alternance}
                  onChange={(e) => {
                    setAlternance(!alternance);
                  }}
                  type="checkbox"
                  id="alternance"
                />{" "}
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="12">
                <div className="mb-2 mt-2">
                  <SelectSearch
                    onChange={setSearchRegion}
                    closeOnSelect={false}
                    printOptions="on-focus"
                    multiple
                    placeholder="Région"
                    options={regions.reverse()}
                  />
                </div>
                <div className="mb-2">
                  <SelectSearch
                    onChange={setSearchTypesContrat}
                    closeOnSelect={false}
                    printOptions="on-focus"
                    multiple
                    placeholder="Types de Contrat"
                    options={[
                      { name: "Contrat à durée indéterminée", value: "CDI" },
                      { name: "Contrat à durée déterminée", value: "CDD" },
                      { name: "CDD sénior", value: "CDS" },
                      {
                        name: "Contrat travail temporaire insertion",
                        value: "TTI",
                      },
                      {
                        name: "Contrat durée déterminée insertion",
                        value: "DDI",
                      },
                      { name: "CDI Intérimaire", value: "DIN" },

                      { name: "Contrat travail saisonnier", value: "SAI" },
                      { name: "Franchise", value: "FRA" },
                      { name: "Mission intérimaire", value: "MIS" },
                      { name: "Profession commerciale", value: "CCE" },
                      { name: "Profession libérale", value: "LIB" },
                      { name: "Reprise d'entreprise", value: "REP" },
                    ]}
                  />
                </div>{" "}
                <div className="mb-2">
                  <SelectSearch
                    onChange={setSearchNiveauEtude}
                    closeOnSelect={false}
                    printOptions="on-focus"
                    multiple
                    placeholder="Niveau d'étude"
                    options={[
                      {
                        name: "Aucune formation scolaire",
                        value: "Aucune formation scolaire",
                      },
                      { name: "Primaire à 4ème", value: "Primaire à 4ème" },
                      { name: "4ème achevée", value: "4ème achevée" },
                      {
                        name: "3ème achevée ou Brevet",
                        value: "3ème achevée ou Brevet",
                      },
                      {
                        name: "2nd ou 1ère achevée",
                        value: "2nd ou 1ère achevée",
                      },
                      {
                        name: "CAP, BEP et équivalents",
                        value: "CAP, BEP et équivalents",
                      },
                      { name: "Niveau Bac", value: "Niveau Bac" },
                      {
                        name: "Bac ou équivalent",
                        value: "Bac ou équivalent",
                      },
                      {
                        name: "Bac+2 ou équivalents",
                        value: "Bac+2 ou équivalents",
                      },
                      {
                        name: "Bac+3, Bac+4 ou équivalents",
                        value: "Bac+3, Bac+4 ou équivalents",
                      },
                      {
                        name: "Bac+5 et plus ou équivalents",
                        value: "Bac+5 et plus ou équivalents",
                      },
                    ]}
                  />
                </div>
                <div className="mb-2">
                  <SelectSearch
                    onChange={setSearchDureeTravail}
                    closeOnSelect={false}
                    printOptions="on-focus"
                    multiple
                    placeholder="Durée de travail"
                    options={[
                      {
                        name: "20H Horaires normaux",
                        value: "20H Horaires normaux",
                      },
                      {
                        name: "24H Horaires normaux",
                        value: "24H Horaires normaux",
                      },
                      {
                        name: "30H Horaires normaux",
                        value: "30H Horaires normaux",
                      },
                      {
                        name: "35H Horaires normaux",
                        value: "35H Horaires normaux",
                      },
                      {
                        name: "35H Horaires variables",
                        value: "35H Horaires variables",
                      },
                      {
                        name: "35H Travail le samedi",
                        value: "35H Travail le samedi",
                      },
                      {
                        name: "35H Travail samedi et dimanche",
                        value: "35H Travail samedi et dimanche",
                      },
                      {
                        name: "39H Horaires normaux",
                        value: "39H Horaires normaux",
                      },
                    ]}
                  />
                </div>
                <hr className=""></hr>
                <div className="">
                  <small>Salaire mensuel brut</small>
                  <MDBRow className="m-0 p-0">
                    <MDBCol size="2 pl-0 ml-0">
                      {" "}
                      <small>
                        {Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        }).format(search_salaire[0])}
                      </small>
                    </MDBCol>
                    <MDBCol size="7 m-0 p-0 pr-2 pl-4">
                      <Slider
                        className=""
                        value={search_salaire}
                        min={0}
                        max={15000}
                        onChange={(e, newValue) => {
                          setSearchSalaire(newValue);
                        }}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                      />
                    </MDBCol>
                    <MDBCol size="3 m-0 p-0 ">
                      <small>
                        {Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        }).format(search_salaire[1])}
                      </small>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <small>Filtres de catégories</small>
                  <MDBRow>
                    <MDBCol size="6" className="pr-0">
                      <MDBListGroup>
                        <MDBListGroupItem
                          className={`p-0 pointer pl-1  ${
                            filtreRAM.secteurs.length > 0 && " selected_filtre"
                          }`}
                          onClick={(e) => {
                            loadModalData("Secteurs", "secteurs");
                          }}
                        >
                          <small>
                            {filtreRAM.secteurs.length > 0 &&
                              filtreRAM.secteurs.length}{" "}
                            Secteurs
                          </small>
                        </MDBListGroupItem>
                        <MDBListGroupItem
                          className={`p-0 pointer pl-1  ${
                            filtreRAM.metiers.length > 0 && " selected_filtre"
                          }`}
                          onClick={(e) => {
                            loadModalData("Métiers", "metiers");
                          }}
                        >
                          <small>
                            {filtreRAM.metiers.length > 0 &&
                              filtreRAM.metiers.length}{" "}
                            Métiers
                          </small>
                        </MDBListGroupItem>
                        <MDBListGroupItem
                          className={`p-0 pointer pl-1  ${
                            filtreRAM.romes.length > 0 && " selected_filtre"
                          }`}
                          onClick={(e) => {
                            loadModalData("Romes", "romes");
                          }}
                        >
                          {" "}
                          <small>
                            {filtreRAM.romes.length > 0 &&
                              filtreRAM.romes.length}{" "}
                            Romes
                          </small>
                        </MDBListGroupItem>
                      </MDBListGroup>
                    </MDBCol>
                    <MDBCol size="6" className="pl-0">
                      <MDBListGroup>
                        <MDBListGroupItem
                          className={`p-0 pointer pl-1  ${
                            filtreRAM.entreprises.length > 0 &&
                            " selected_filtre"
                          }`}
                          onClick={(e) => {
                            loadModalData("Entreprises", "entreprises");
                          }}
                        >
                          <small>
                            {filtreRAM.entreprises.length > 0 &&
                              filtreRAM.entreprises.length}{" "}
                            Entreprises
                          </small>
                        </MDBListGroupItem>
                        <MDBListGroupItem
                          className={`p-0 pointer pl-1  ${
                            filtreRAM.competences.length > 0 &&
                            " selected_filtre"
                          }`}
                          onClick={(e) => {
                            loadModalData("Compétences", "competences");
                          }}
                        >
                          {" "}
                          <small>
                            {filtreRAM.competences.length > 0 &&
                              filtreRAM.competences.length}{" "}
                            Compétences
                          </small>
                        </MDBListGroupItem>
                        <MDBListGroupItem
                          className={`p-0 pointer pl-1  ${
                            filtreRAM.formations.length > 0 &&
                            " selected_filtre"
                          }`}
                          onClick={(e) => {
                            loadModalData("Formations", "formations");
                          }}
                        >
                          {" "}
                          <small>
                            {filtreRAM.formations.length > 0 &&
                              filtreRAM.formations.length}{" "}
                            Formations
                          </small>
                        </MDBListGroupItem>
                      </MDBListGroup>
                    </MDBCol>
                  </MDBRow>
                </div>
                <div className="text-center mt-2">
                  <MDBBtn
                    // onKeyDown={_handleKeyDown}
                    rounded
                    outline
                    color="success"
                    size="sm"
                    className=" animated  animated  bounceIn block"
                    onClick={updateFiltre}
                  >
                    Recherche
                  </MDBBtn>

                  <MDBBtn
                    rounded
                    outline
                    color="danger"
                    size="sm"
                    className="ml-3 animated  bounceIn "
                    onClick={reset}
                  >
                    Reset
                  </MDBBtn>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default SideBar;
