import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import am4lang_fr_FR from "@amcharts/amcharts4/lang/fr_FR";

import { useNavFiltre } from "../../Contextes/navfiltreContext";
am4core.useTheme(am4themes_animated);
const TreeMap = (props) => {
  //filtre global
  const { addToFiltre } = useNavFiltre();
  const chart_draw = useRef(null);

  useLayoutEffect(() => {
    // create chart
    var chart = am4core.create(props.id, am4charts.TreeMap);
    chart.hiddenState.properties.opacity = 0;

    props.data.forEach((element) => {
      let rows = [];
      element["data"]["buckets"].forEach((e) => {
        rows.push({ name: e.key, value: e.doc_count });
      });
      chart.data.push({ name: element.key, children: rows });
    });

    chart.colors.step = 2;

    // define data fields
    chart.dataFields.value = "value";
    chart.dataFields.name = "name";
    chart.dataFields.children = "children";

    chart.zoomable = false;
    var bgColor = new am4core.InterfaceColorSet().getFor("background");

    // level 0 series template
    var level0SeriesTemplate = chart.seriesTemplates.create("0");
    var level0ColumnTemplate = level0SeriesTemplate.columns.template;

    level0ColumnTemplate.column.cornerRadius(10, 10, 10, 10);
    level0ColumnTemplate.fillOpacity = 0;
    level0ColumnTemplate.strokeWidth = 4;
    level0ColumnTemplate.strokeOpacity = 0;

    // level 1 series template
    var level1SeriesTemplate = chart.seriesTemplates.create("1");
    var level1ColumnTemplate = level1SeriesTemplate.columns.template;

    level1SeriesTemplate.tooltip.animationDuration = 0;
    level1SeriesTemplate.strokeOpacity = 1;

    level1ColumnTemplate.column.cornerRadius(10, 10, 10, 10);
    level1ColumnTemplate.fillOpacity = 1;
    level1ColumnTemplate.strokeWidth = 4;
    level1ColumnTemplate.stroke = bgColor;
    level1SeriesTemplate.columns.template.cursorOverStyle =
      am4core.MouseCursorStyle.pointer;
    level1SeriesTemplate.columns.template.events.on("hit", function (ev) {
      let value = ev.target.dataItem.dataContext["name"];
      addToFiltre(props.typeData, value);
    });
    var bullet1 = level1SeriesTemplate.bullets.push(
      new am4charts.LabelBullet()
    );
    bullet1.locationY = 0.5;
    bullet1.locationX = 0.5;
    bullet1.label.text = "{name}";
    bullet1.label.fill = am4core.color("#ffffff");
    bullet1.label.fontSize = 12;
    chart.maxLevels = 2;
    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.fontSize = 12;
    /*title*/
    let title = chart.titles.create();
    title.text = props.title;
    title.align = "left";
    title.fontSize = 15;
    title.fontWeight = 600;
    title.marginBottom = 10;
    title.fill = "#303950";
    chart_draw.current = chart;

    return () => {
      chart.dispose();
    };
  }, []);

  return <div id={props.id} style={{ width: "95%", height: "400px" }}></div>;
};

export default TreeMap;
