import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_fr_FR from "@amcharts/amcharts4/lang/fr_FR";
am4core.useTheme(am4themes_animated);
const ContratGraph = (props) => {
  //console.log("data");
  //console.log(props.data);
  const chart_draw = useRef(null);

  useLayoutEffect(() => {
    var chart = am4core.create(props.id, am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    let max = 0;
    chart.maskBullets = false;
    /*title*/
    let title = chart.titles.create();
    title.text = props.title;
    title.align = "left";
    title.fontSize = 15;
    title.fontWeight = 600;
    title.marginBottom = 10;
    title.fill = "#303950";
    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

    xAxis.dataFields.category = "x";
    yAxis.dataFields.category = "y";
    yAxis.fontSize = 12;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 40;
    xAxis.fontSize = 12;

    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.inversed = true;
    yAxis.renderer.minGridDistance = 10;

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "x";
    series.dataFields.categoryY = "y";
    series.dataFields.value = "value";
    series.sequencedInterpolation = true;
    series.defaultState.transitionDuration = 3000;

    // Set up column appearance
    var column = series.columns.template;
    column.strokeWidth = 2;
    column.strokeOpacity = 1;
    column.stroke = am4core.color("#ffffff");
    column.tooltipText = "{x}, {y}: {value.workingValue.formatNumber('#.')}";
    column.width = am4core.percent(100);
    column.height = am4core.percent(100);
    column.column.cornerRadius(6, 6, 6, 6);
    column.propertyFields.fill = "color";

    var bullet2 = series.bullets.push(new am4charts.LabelBullet());
    bullet2.label.text = "{value}";
    bullet2.label.fill = am4core.color("#fff");
    bullet2.zIndex = 1;
    bullet2.fontSize = 14;
    bullet2.fontWeight = 400;
    bullet2.interactionsEnabled = true;

    // define colors
    var colors = {
      bad: "#aed581",
      medium: "#9ccc65",
      good: "#689f38",
      verygood: "#0b7d03",
    };

    props.data.forEach((element, i) => {
      element["data"]["buckets"].forEach((e, ii) => {
        if (e.doc_count > max) max = e.doc_count;
      });
    });

    let data = [];
    props.data.forEach((element, i) => {
      element["data"]["buckets"].forEach((e, ii) => {
        let color = colors.medium;
        if (e.doc_count <= max / 4) color = colors.bad;
        if (e.doc_count <= max / 2 && e.doc_count > max / 4)
          color = colors.medium;
        if (e.doc_count <= max / 3 && e.doc_count > max / 2)
          color = colors.good;
        if (e.doc_count > max / 3) color = colors.verygood;

        chart.data.push({
          y: e.key,
          x: element.key,
          color: color,
          value: e.doc_count,
        });
      });
    });

    // chart.data = data;

    var baseWidth = Math.min(
      chart.plotContainer.maxWidth,
      chart.plotContainer.maxHeight
    );

    chart.plotContainer.events.on("maxsizechanged", function () {
      var side = Math.min(
        chart.plotContainer.maxWidth,
        chart.plotContainer.maxHeight
      );
    });
    chart_draw.current = chart;

    return () => {
      chart.dispose();
    };
  }, [props]);

  return <div id={props.id} style={{ width: "95%", height: "400px" }}></div>;
};

export default ContratGraph;
