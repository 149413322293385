import React, { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import CountUp from "react-countup";
import ImageUploader from "react-images-upload";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavFiltre } from "../Contextes/navfiltreContext";
import "./cv1.css";
import { Document, Page, pdfjs } from "react-pdf";
import {
  MDBInput,
  MDBTooltip,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBCardBody,
  MDBCard,
  MDBModalHeader,
  MDBContainer,
  MDBModalBody,
  MDBIcon,
  MDBModalFooter,
  MDBBtnGroup,
  MDBCollapseHeader,
  MDBCollapse,
  MDBCardTitle,
  MDBCardText,
  MDBListGroup,
  MDBListGroupItem,
  MDBBadge,
} from "mdbreact";
import Formation from "../components/offre/Formation";
const Cv1 = (props) => {
  const [selectedCompetences, setSelectedCompetences] = useState([]);
  const [formations, setFormations] = useState([]);
  const { filtre } = useNavFiltre();
  const [Cvs, setCvs] = useState([]);
  const [cvFile, setCvFile] = useState("");
  const [CvText, setCvText] = useState("");
  const [load, setLoad] = useState(false);
  const [loadgaP, setLoadgaP] = useState(0);
  const [gapData, setGapData] = useState("");
  const [jobPredictRows, setJobPredictRows] = useState([]);
  const [competencesProchesCV, setCompetencesProchesCV] = useState([]);
  //const [state, setState] = useState({ modal: false });
  const [
    competenceOperationnellesGrouped,
    setCompetenceOperationnellesGrouped,
  ] = useState([]);
  const [competenceOperationnelles, setCompetenceOperationnelles] = useState(
    []
  );
  const [state, setState] = useState({
    collapseID: "1",
  });
  const [openModal, setOpenModal] = useState(false);
  const [stateGap, setStateGap] = useState({
    collapseID: "gap_4",
  });
  const showCompetenceCv = (i) => {
    setCompetencesProchesCV([...jobPredictRows[i].competences_filtre]);
    setOpenModal(true);
  };
  const toggleCollapse = (collapseID) => () =>
    setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  const toggleCollapseGap = (collapseID) => () =>
    setStateGap((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  function selectedCompence(comp) {
    let arrayDATA = [...selectedCompetences];
    return arrayDATA.includes(comp);
  }

  function addSelectedCompences(comp) {
    let arrayDATA = [...selectedCompetences];
    if (arrayDATA.includes(comp)) {
      // arrayDATA = arrayDATA.remove(comp);
      const index = arrayDATA.indexOf(comp, 0);
      if (index > -1) {
        arrayDATA.splice(index, 1);
      }

      setSelectedCompetences([...arrayDATA]);
    } else {
      setSelectedCompetences([...selectedCompetences, comp]);
    }
  }
  function netoyagetext(text) {
    let output = text;
    let regex = /\\n/i;
    output = output.replace(regex, "");
    regex = /(\s+et\s*)$/i;
    output = output.replace(regex, "");
    return output;
  }
  function remoElement(type, textId, libelleId) {
    let elemens = competenceOperationnellesGrouped;
    if (type === "text") {
      elemens.splice(textId, 1);
    }
    if (type === "libelle") {
      elemens[textId].libelles.splice(libelleId, 1);
      // let libelles = [...elemens[textId]];
      // libelles.splice(libelleId, 1);
      // elemens[textId] = libelles;
    }
    setCompetenceOperationnellesGrouped([...elemens]);
  }

  useEffect(() => {
    if (CvText) gapFunct();
    setCompetenceOperationnelles([]);
  }, [filtre]);
  async function onDrop(pictureFiles, pictureDataURLs) {
    setGapData(null);
    setGapData(null);
    setCvText({});
    setLoad(true);
    let cvTXT = await upload([pictureFiles]);
    // alert(`${process.env.REACT_APP_API_URL}/cv/${cvTXT.fileName}`);
    setCvFile(`${process.env.REACT_APP_API_URL}/cv/${cvTXT.fileName}`);
    setCompetenceOperationnellesGrouped([
      ...cvTXT.data.cvNorm.competence_operationnelle,
    ]);
    setLoad(false);
    setCvText(cvTXT.data.cvNorm);
  }

  const removeCompetenceOperationnelle = (i) => {};

  async function jobPredict() {
    setLoadgaP(1);

    let competences_operationelles_CV = [];
    competenceOperationnellesGrouped.forEach((element) => {
      element.libelles.forEach((e) => {
        competences_operationelles_CV.push(e.libelle);
      });
    });
    // send  comptence cv  and  competences_filtre
    const gapDATABrut = await axios.post(
      `${process.env.REACT_APP_API_URL}/dashbord/jobPredict`,
      {
        competences_operationelles_CV: competences_operationelles_CV,
        filtre: filtre,
      }
    );
    setLoadgaP(3);
    setJobPredictRows([...gapDATABrut.data]);
  }
  async function trouverFormation() {
    // send formations
    const formationsBrut = await axios.post(
      `${process.env.REACT_APP_API_URL}/dashbord/formationMatch`,
      {
        selectedCompetences: selectedCompetences,
      }
    );
    setFormations([...formationsBrut.data]);
  }
  async function gapFunct() {
    setLoadgaP(1);
    let competences_operationelles_CV = [];

    competenceOperationnellesGrouped.forEach((element) => {
      element.libelles.forEach((e) => {
        competences_operationelles_CV.push(e.libelle);
      });
    });
    console.log(competences_operationelles_CV);
    setCompetenceOperationnelles([...competences_operationelles_CV]);

    const dataCompetenceBrut = await axios.post(
      `${process.env.REACT_APP_API_URL}/dashbord/competenceTableAggr`,
      {
        filtre: filtre,
      }
    );
    let competences_filtre = dataCompetenceBrut.data.map((a) => a.key);

    // send  comptence cv  and  competences_filtre
    const gapDATABrut = await axios.post(
      `${process.env.REACT_APP_API_URL}/dashbord/cvGap`,
      {
        competences_operationelles_CV: competences_operationelles_CV,
        competences_filtre: competences_filtre,
      }
    );
    setLoadgaP(2);
    setGapData(gapDATABrut.data);
  }
  //upload files
  async function upload(files) {
    const formData = new FormData();

    formData.append("cv", files[0][0]);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/dashbord/uploadCvNorm1`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    return response.data;
  }

  async function analyseCV() {}
  return (
    <Fragment>
      <MDBContainer>
        {/* MODAL */}
        <MDBModal
          isOpen={openModal}
          toggle={(e) => setOpenModal(!openModal)}
          full-height
          position="left"
        >
          <MDBModalHeader
            toggle={(e) => setOpenModal(!openModal)}
            className="pt-1 pb-1 "
            style={{ backgroundColor: "#2b385a70", color: "white" }}
          >
            Compétences proches du CV
          </MDBModalHeader>
          <MDBModalBody>
            <ul>
              {competencesProchesCV &&
                competencesProchesCV.map(function (c, i) {
                  return <li>{c}</li>;
                })}
            </ul>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>

      <MDBRow>
        <MDBCol size="12" className="text-center mb-2">
          <ImageUploader
            accept="*"
            fileContainerStyle={{ width: "100%" }}
            withLabel={false}
            singleImage={true}
            withPreview={false}
            withIcon={false}
            buttonText="Télécharger un CV"
            onChange={(e, a) => {
              onDrop(e, a);
            }}
            imgExtension={[".pdf"]}
            maxFileSize={155242880}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size="12" className="text-center mb-2">
          {load ? (
            <div>
              Traitement en cours
              <ScaleLoader size={10} color={"#303950"} loading={true} />
            </div>
          ) : (
            CvText && (
              <Fragment>
                {/* <Document file={cvFile} onLoadError={console.error} /> */}
                <MDBContainer className="pt-0 mt-0">
                  <MDBContainer className="md-accordion ">
                    <MDBCard>
                      <MDBCollapseHeader
                        onClick={toggleCollapse(1)}
                        tag="h5"
                        ClassName="p-0"
                        tagClassName="indigo-text d-flex justify-content-between align-items-center "
                      >
                        {/* {CvText && CvText.competence_operationnelle && (
                          <CountUp
                            delay={0}
                            start={0}
                            end={0}
                            duration={0}
                            separator=" "
                            decimals={0}
                            decimal=""
                            prefix=""
                            suffix=" "
                          />
                        )} */}
                        Compétences opérationnelles{" "}
                        <span
                          className="float-right"
                          style={{ marginLeft: "-58px" }}
                        >
                          Nombre détecté :{" "}
                          {CvText &&
                            competenceOperationnellesGrouped &&
                            competenceOperationnellesGrouped.length}
                        </span>
                        <MDBIcon
                          icon={
                            state.collapseID === 1 ? "angle-up" : "angle-down"
                          }
                          className="indigo-text"
                          size="2x"
                        />
                      </MDBCollapseHeader>
                      <MDBCollapse id={1} isOpen={state.collapseID}>
                        <MDBCardBody class="pt-0">
                          <table className="tableCompetences table table-striped">
                            <tr className="trHeader">
                              <td colspan="2" className="">
                                Phrase détectée par l’IA (correspondant à une
                                compétence)
                              </td>

                              <td
                                style={{
                                  border: "5px solid white !important",
                                }}
                              >
                                <MDBRow>
                                  <MDBCol>
                                    {" "}
                                    Compétences normalisées (du référentiel
                                    ROME) attribuées à chaque phrase détectée
                                  </MDBCol>
                                  <MDBCol>
                                    Score de correspondance entre la compétence
                                    normalisée et la phrase du CV
                                  </MDBCol>
                                </MDBRow>
                              </td>
                            </tr>
                            {CvText &&
                              competenceOperationnellesGrouped &&
                              competenceOperationnellesGrouped.map(function (
                                item,
                                i
                              ) {
                                return (
                                  <tr className="">
                                    <td>{i + 1}</td>
                                    <td className="text-left">
                                      <MDBIcon
                                        far
                                        icon="times-circle"
                                        className="mr-2 pointer"
                                        onClick={(e) => {
                                          remoElement("text", i, 0);
                                        }}
                                      />{" "}
                                      {netoyagetext(item.text)}
                                    </td>
                                    <td>
                                      {item.libelles.map(function (itemj, j) {
                                        return (
                                          <MDBRow
                                            style={
                                              j < item.libelles.length - 1
                                                ? {
                                                    borderBottom:
                                                      "1px solid #556fb440",
                                                  }
                                                : {}
                                            }
                                          >
                                            <MDBCol
                                              className="trHeaderTD text-left"
                                              style={{
                                                borderRight:
                                                  "1px solid #556fb440",
                                              }}
                                            >
                                              {" "}
                                              <MDBIcon
                                                className="mr-2 pointer"
                                                far
                                                icon="times-circle"
                                                onClick={(e) => {
                                                  remoElement("libelle", i, j);
                                                }}
                                              />
                                              {netoyagetext(itemj.libelle)}
                                            </MDBCol>
                                            <MDBCol>
                                              {" "}
                                              {(itemj.degre * 100).toFixed(1)} %
                                            </MDBCol>
                                          </MDBRow>
                                        );
                                      })}
                                    </td>
                                  </tr>
                                );
                              })}
                          </table>
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>

                    <MDBCard>
                      <MDBCollapseHeader
                        onClick={toggleCollapse(3)}
                        tag="h5"
                        ClassName="p-0"
                        tagClassName="indigo-text 
                        d-flex justify-content-between align-items-center 
                        "
                      >
                        Autres compétences
                        <span className="float-right">
                          {"   "}Nombre détecté :{" "}
                          {CvText &&
                            CvText.programation &&
                            CvText.programation.length}
                        </span>
                        <MDBIcon
                          icon={
                            state.collapseID === 3 ? "angle-up" : "angle-down"
                          }
                          className="indigo-text"
                          size="2x"
                        />
                      </MDBCollapseHeader>
                      <MDBCollapse id={3} isOpen={state.collapseID}>
                        <MDBCardBody class="pt-0">
                          <table className="table table-striped" width="100%">
                            {CvText &&
                              CvText.programation &&
                              CvText.programation.map(function (item, i) {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{item}</td>
                                  </tr>
                                );
                              })}
                          </table>
                        </MDBCardBody>
                      </MDBCollapse>
                    </MDBCard>
                  </MDBContainer>
                </MDBContainer>
                <MDBRow>
                  <MDBCol size="12" className="text-center mb-2 mt-5">
                    <MDBBtn
                      rounded
                      outline
                      color="indigo"
                      onClick={(e) => {
                        jobPredict();
                      }}
                    >
                      Afficher les métiers proches
                    </MDBBtn>
                    <MDBBtn
                      rounded
                      outline
                      color="indigo"
                      onClick={(e) => {
                        gapFunct();
                      }}
                    >
                      Mesurer l'écart avec la recherche
                    </MDBBtn>
                    <MDBTooltip placement="top" style={{ zIndex: 1000 }}>
                      <MDBBtn color="" className="ml-2 infobull" size="sm">
                        <MDBIcon icon="info-circle"></MDBIcon>
                      </MDBBtn>
                      <div style={{ zIndex: 20000 }}>
                        L'écart est mesuré par rapport au top 15 des compétences
                        opérationnelles
                      </div>
                    </MDBTooltip>
                    {loadgaP === 1 && (
                      <div className="pt-5">
                        Traitement en cours
                        <ScaleLoader
                          size={10}
                          color={"#303950"}
                          loading={true}
                        />
                      </div>
                    )}
                  </MDBCol>
                </MDBRow>
                {loadgaP === 2 && (
                  <Fragment>
                    <MDBContainer>
                      <MDBContainer className="md-accordion ">
                        <MDBCard>
                          <MDBCollapseHeader
                            onClick={toggleCollapseGap("gap_1")}
                            tag="h5"
                            ClassName="p-0"
                            tagClassName="indigo-text d-flex justify-content-between align-items-center "
                          >
                            {gapData && gapData.bon && (
                              <CountUp
                                delay={0}
                                start={0}
                                end={gapData.bon.offres.length}
                                duration={3}
                                separator=" "
                                decimals={0}
                                decimal=""
                                prefix=""
                                suffix=" "
                              />
                            )}{" "}
                            Match
                            <MDBIcon
                              icon={
                                stateGap.collapseID === "gap_1"
                                  ? "angle-up"
                                  : "angle-down"
                              }
                              className="indigo-text"
                              size="2x"
                            />
                          </MDBCollapseHeader>
                          <MDBCollapse
                            id={"gap_1"}
                            isOpen={stateGap.collapseID}
                          >
                            <MDBCardBody class="pt-0">
                              <MDBRow>
                                <MDBCol size="6" className=" mb-2">
                                  <h6>Compétences du CV</h6>
                                  <ul>
                                    {gapData &&
                                      gapData.bon &&
                                      gapData.bon.cvs.map(function (item, i) {
                                        return (
                                          <li
                                            className={
                                              "text-left pointer" +
                                              (selectedCompence(item)
                                                ? " blue-text"
                                                : "")
                                            }
                                            onClick={(e) => {
                                              addSelectedCompences(item);
                                            }}
                                          >
                                            {item}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </MDBCol>

                                <MDBCol
                                  size="6"
                                  className=" mb-2 block-example border-right border-dark"
                                >
                                  <h6>Résultat de la recherche</h6>
                                  <ul>
                                    {gapData &&
                                      gapData.bon &&
                                      gapData.bon.offres.map(function (
                                        item,
                                        i
                                      ) {
                                        return (
                                          <li
                                            className={
                                              "text-left pointer" +
                                              (selectedCompence(item)
                                                ? " blue-text"
                                                : "")
                                            }
                                            onClick={(e) => {
                                              addSelectedCompences(item);
                                            }}
                                          >
                                            {item}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </MDBCol>
                              </MDBRow>
                            </MDBCardBody>
                          </MDBCollapse>
                        </MDBCard>

                        <MDBCard>
                          <MDBCollapseHeader
                            onClick={toggleCollapseGap("gap_2")}
                            tag="h5"
                            ClassName="p-0"
                            tagClassName="indigo-text d-flex justify-content-between align-items-center "
                          >
                            {gapData && gapData.moyen && (
                              <CountUp
                                delay={0}
                                start={0}
                                end={gapData.moyen.offres.length}
                                duration={3}
                                separator=" "
                                decimals={0}
                                decimal=""
                                prefix=""
                                suffix=" "
                              />
                            )}{" "}
                            Compétences proches
                            <MDBIcon
                              icon={
                                stateGap.collapseID === "gap_2"
                                  ? "angle-up"
                                  : "angle-down"
                              }
                              className="indigo-text"
                              size="2x"
                            />
                          </MDBCollapseHeader>
                          <MDBCollapse
                            id={"gap_2"}
                            isOpen={stateGap.collapseID}
                          >
                            <MDBCardBody class="pt-0">
                              <MDBRow>
                                <MDBCol
                                  size="6"
                                  className=" mb-2 block-example "
                                >
                                  <h6>Compétences du CV</h6>
                                  <ul>
                                    {gapData &&
                                      gapData.moyen &&
                                      gapData.moyen.cvs.map(function (item, i) {
                                        return (
                                          <li
                                            className={
                                              "text-left pointer" +
                                              (selectedCompence(item)
                                                ? " blue-text"
                                                : "")
                                            }
                                            onClick={(e) => {
                                              addSelectedCompences(item);
                                            }}
                                          >
                                            {item}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </MDBCol>

                                <MDBCol
                                  size="6"
                                  className=" mb-2 block-example border-right border-dark"
                                >
                                  <h6>Résultat de la recherche</h6>{" "}
                                  <ul>
                                    {gapData &&
                                      gapData.moyen &&
                                      gapData.moyen.offres.map(function (
                                        item,
                                        i
                                      ) {
                                        return (
                                          <li
                                            className={
                                              "text-left pointer" +
                                              (selectedCompence(item)
                                                ? " blue-text"
                                                : "")
                                            }
                                            onClick={(e) => {
                                              addSelectedCompences(item);
                                            }}
                                          >
                                            {item}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </MDBCol>
                              </MDBRow>
                            </MDBCardBody>
                          </MDBCollapse>
                        </MDBCard>

                        <MDBCard>
                          <MDBCollapseHeader
                            onClick={toggleCollapseGap("gap_3")}
                            tag="h5"
                            ClassName="p-0"
                            tagClassName="indigo-text d-flex justify-content-between align-items-center "
                          >
                            {gapData && gapData.mediocre && (
                              <CountUp
                                delay={0}
                                start={0}
                                end={gapData.mediocre.offres.length}
                                duration={3}
                                separator=" "
                                decimals={0}
                                decimal=""
                                prefix=""
                                suffix=" "
                              />
                            )}{" "}
                            Compétences éloignées
                            <MDBIcon
                              icon={
                                stateGap.collapseID === "gap_3"
                                  ? "angle-up"
                                  : "angle-down"
                              }
                              className="indigo-text"
                              size="2x"
                            />
                          </MDBCollapseHeader>
                          <MDBCollapse
                            id={"gap_3"}
                            isOpen={stateGap.collapseID}
                          >
                            <MDBCardBody class="pt-0">
                              <MDBRow>
                                <MDBCol
                                  size="6"
                                  className=" mb-2 block-example"
                                >
                                  <h6>Compétences du CV</h6>{" "}
                                  <ul>
                                    {gapData &&
                                      gapData.mediocre &&
                                      gapData.mediocre.cvs.map(function (
                                        item,
                                        i
                                      ) {
                                        return (
                                          <li
                                            className={
                                              "text-left pointer" +
                                              (selectedCompence(item)
                                                ? " blue-text"
                                                : "")
                                            }
                                            onClick={(e) => {
                                              addSelectedCompences(item);
                                            }}
                                          >
                                            {item}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </MDBCol>
                                <MDBCol
                                  size="6"
                                  className=" mb-2 block-example border-right border-dark"
                                >
                                  <h6>Résultat de la recherche</h6>
                                  <ul>
                                    {gapData &&
                                      gapData.mediocre &&
                                      gapData.mediocre.offres.map(function (
                                        item,
                                        i
                                      ) {
                                        return (
                                          <li
                                            className={
                                              "text-left pointer" +
                                              (selectedCompence(item)
                                                ? " blue-text"
                                                : "")
                                            }
                                            onClick={(e) => {
                                              addSelectedCompences(item);
                                            }}
                                          >
                                            {item}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </MDBCol>
                              </MDBRow>
                            </MDBCardBody>
                          </MDBCollapse>
                        </MDBCard>
                      </MDBContainer>
                    </MDBContainer>

                    {selectedCompetences && selectedCompetences.length > 0 && (
                      <MDBContainer>
                        <MDBRow>
                          <MDBCol size="12" className="text-center mb-2 mt-5">
                            <MDBBtn
                              rounded
                              outline
                              color="indigo"
                              onClick={(e) => {
                                trouverFormation();
                              }}
                            >
                              Trouver une formation
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol size="12" className="text-center mb-2 mt-5">
                            <MDBContainer>
                              {formations.length > 0 && (
                                <Formation data={formations}></Formation>
                              )}
                            </MDBContainer>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    )}
                  </Fragment>
                )}
                {loadgaP === 3 && (
                  <MDBContainer>
                    <MDBContainer>
                      <div className="float-left">
                        <MDBTooltip placement="right">
                          <MDBBtn color="" className="infobull" size="sm">
                            <MDBIcon icon="info-circle"></MDBIcon>
                          </MDBBtn>
                          <div>
                            Index de match : La valeur 100 correspond au métier
                            qui se rapproche le plus du CV sur le plan des
                            compétences. Les valeurs inférieures sont calculées
                            relativement à cette valeur maximale et représentent
                            le degré de match. La fonctionnalité affiche le top
                            10 des métiers qui se rapprochent le mieux avec le
                            CV analysé.
                          </div>
                        </MDBTooltip>
                      </div>
                      <div className="float-right">
                        <MDBTooltip placement="left">
                          <MDBBtn color="" className="infobull" size="sm">
                            <MDBIcon icon="info-circle"></MDBIcon>
                          </MDBBtn>
                          <div>
                            Index de la demande : La valeur 100 correspond au
                            métier le plus demandé parmi la liste des métiers
                            qui se rapprochent au CV, sur la période spécifiée
                            dans le filtre. Les valeurs inférieures sont
                            calculées relativement à cette valeur maximale et
                            représentent le degré de demande sur le marché.
                          </div>
                        </MDBTooltip>
                      </div>{" "}
                      <MDBListGroup>
                        {jobPredictRows &&
                          jobPredictRows.map(function (e, i) {
                            return (
                              <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                <span className="badge blue-text">
                                  {e.match_index}
                                </span>{" "}
                                <p
                                  className="pointer pt-1"
                                  onClick={(e) => showCompetenceCv(i)}
                                >
                                  {e.matched_occupation}
                                </p>
                                <MDBBadge color="primary" pill>
                                  {e.indexOffre}
                                </MDBBadge>
                              </MDBListGroupItem>
                            );
                          })}
                      </MDBListGroup>
                    </MDBContainer>
                  </MDBContainer>
                )}
              </Fragment>
            )
          )}
        </MDBCol>
      </MDBRow>
    </Fragment>
  );
};
export default Cv1;
