import React, {Fragment, useState, useEffect, useRef} from "react";
import {useNavFiltre} from "./../Contextes/navfiltreContext";
import TendanceGraph from "../components/chart/TendanceGraph";
import SelectSearch from "react-select-search";
import Indicateur from "../components/widget/Indicateur";
import axios from "axios";

const Tendance = () => {
    // filtre
    const {filtre} = useNavFiltre();
    const [graph, setGraph] = useState([]);
    const [pasTendance, setPasTendance] = useState("M");
    const [options, setOptions] = useState([
        {name: "Mensuel", value: "M"},
        {name: "Hebdomadaire", value: "w"},

    ]);
    useEffect(() => {
        const role = localStorage.getItem("role")
        if (role === '"admin"') {
            setOptions([
                {name: "Mensuel", value: "M"},
                {name: "Hebdomadaire", value: "w"},
                {name: "Quotidien", value: "d"},
            ])
        }
    }, [])
    //get tendence aggr
    const getTendanceAggr = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/dashbord/tendancesAggr`, {
                headers: {
                    // Authorization: `Basic ${existingTokens}`,
                },
                filtre: filtre,
                pasTendance: pasTendance,
            })
            .then((resp) => {
                let data = resp.data.aggregations.tendance.buckets;
                //  console.log(data);
                setGraph(data);
            });
    };

    // Init function
    useEffect(() => {
        getTendanceAggr();
    }, [filtre, pasTendance]);

    return (
        <Fragment>
            {" "}
            <Indicateur></Indicateur>
            <div className="width-10">
                <SelectSearch
                    onChange={setPasTendance}
                    closeOnSelect={true}
                    printOptions="on-focus"
                    placeholder={options[0].name}
                    value={pasTendance}
                    options={options}
                />
            </div>
            {graph.length > 0 && <TendanceGraph tendence={graph}></TendanceGraph>}
            {/*
      <table>
        <tr>Recherché :{filtre.search}</tr>
        <tr>Period:{filtre.period.toString()}</tr>
        <tr>Champs:{filtre.fields}</tr>
        <tr>régions:{filtre.regions}</tr>
        <tr>typeContrat: {filtre.typeContrat}</tr>
        <tr> niveauEtude: {filtre.niveauEtude}</tr>
        <tr>DureeTravail:{filtre.DureeTravail}</tr>
        <tr>salaire: {filtre.salaire}</tr>
        <tr>competences: {filtre.competences}</tr>
        <tr>formations:{filtre.formation},</tr>
        <tr>secteurs: {filtre.secteurs}</tr>
        <tr>metiers:{filtre.metiers},</tr>
        <tr>romes: {filtre.romes}</tr>
        <tr>entreprises: {filtre.entreprises}</tr>
        
      </table>
      */}
        </Fragment>
    );
};

export default Tendance;
