import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_fr_FR from "@amcharts/amcharts4/lang/fr_FR";
am4core.useTheme(am4themes_animated);
const SalaireGraph = (props) => {
  const chart = useRef(null);

  useLayoutEffect(() => {
    let x = am4core.create("chartEvolutionSalaire", am4charts.XYChart);
    x.language.locale = am4lang_fr_FR;
    x.paddingRight = 20;

    let title = x.titles.create();
    title.text = "Evolution des salaires bruts mensuels";
    title.align = "left";
    title.fontSize = 15;
    title.fontWeight = 600;
    title.marginBottom = 10;
    title.fill = "#303950";

    let label = x.chartContainer.createChild(am4core.Label);
    label.text = "";
    label.align = "center";

    let data = [];

    props.data.forEach((element, i) => {
      data.push({
        date: element.key_as_string,

        value: element.min.value ? Number(element.min.value.toFixed(0)) : null,
        value2: element.max.value ? Number(element.max.value.toFixed(0)) : null,
        value3: element.median.values["50.0"]
          ? Number(element.median.values["50.0"].toFixed(0))
          : null,
      });
    });

    x.data = data;

    let dateAxis = x.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.fontSize = 12;
    let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = false;
    valueAxis.renderer.minWidth = 35;
    valueAxis.fontSize = 12;
    let min = x.series.push(new am4charts.LineSeries());
    min.dataFields.dateX = "date";
    min.dataFields.valueY = "value";
    min.tooltipText = "Min  {valueY.value}  €";
    min.name = "Min";
    min.stroke = am4core.color("#27d545");
    min.fill = am4core.color("#27d545");

    let max = x.series.push(new am4charts.LineSeries());
    max.dataFields.dateX = "date";
    max.dataFields.valueY = "value2";
    max.tooltipText = "Max  {valueY.value}  €";
    max.name = "Max";
    max.stroke = am4core.color("#ec0800");
    max.fill = am4core.color("#ec0800");
    let median = x.series.push(new am4charts.LineSeries());
    median.dataFields.dateX = "date";
    median.dataFields.valueY = "value3";
    median.tooltipText = "Médiane  {valueY.value}  €";
    median.name = "Médiane";
    median.legend = "Médiane";

    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(min);
    scrollbarX.series.push(max);
    scrollbarX.series.push(median);

    x.scrollbarX = scrollbarX;
    x.scrollbarX.background.fill = am4core.color("#ffffff");
    x.cursor = new am4charts.XYCursor();
    x.legend = new am4charts.Legend();
    // x.exporting.title = "Tendence temps réel";
    //  x.exporting.menu = new am4core.ExportMenu();
    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [props]);

  return (
    <div
      id="chartEvolutionSalaire"
      style={{ width: "95%", height: "400px" }}
    ></div>
  );
};

export default SalaireGraph;
