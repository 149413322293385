import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavFiltre } from "./../Contextes/navfiltreContext";
import NestedDonut from "../components/chart/NestedDonut";
import axios from "axios";
import TimelineIcon from "@material-ui/icons/Timeline";
import TableChartIcon from "@material-ui/icons/TableChart";
import Indicateur from "../components/widget/Indicateur";
import CountUp from "react-countup";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBCardBody,
  MDBCard,
  MDBModalHeader,
  MDBContainer,
  MDBModalBody,
  MDBIcon,
  MDBModalFooter,
  MDBBtnGroup,
} from "mdbreact";
const Competence = (props) => {
  // filtre
  const { filtre } = useNavFiltre();
  const [graph, setGraph] = useState([]);
  const [table, setTable] = useState([]);
  const [view, setView] = useState("graph");
  const bruit = [
    "Langage des fleurs",
    "Langage informatique HTML",
    "Langage informatique Javascript",
    "Langage Morse",
    "Langages de programmation pour développements mobiles",
    "Langage informatique XML",
    "Langage informatique Basic",
    "Langage informatique Delphi",
    "Langage informatique Prolog",
    "Langage informatique Python",
    "Langage informatique VB.NET",
    "Langues régionales",
    "Langue étrangère - Anglais",
    "Langue étrangère - Italien",
    "Langue étrangère - Espagnol",
    "Langue étrangère - Allemand",
    "Avions de tourisme, hélicoptères",
    "Langue étrangère - Albanais",
    "Langue étrangère - Arabe",
    "Langue étrangère - Araméen",
    "Langue étrangère - Basque",
    "Langue étrangère - Bulgare",
    "Langue étrangère - Catalan",
    "Langue étrangère - Chinois",
    "Langue étrangère - Coréen",
    "Langue étrangère - Croate",
    "Langue étrangère - Danois",
    "Langue étrangère - Espéranto",
    "Langue étrangère - Estonien",
    "Langue étrangère - Finlandais",
    "Langue étrangère - Frioulan",
    "Langue étrangère - Galicien",
    "Langue étrangère - Géorgien",
    "Langue étrangère - Grec",
    "Langue étrangère - Hongrois",
    "Langue étrangère - Japonais",
    "Langue étrangère - Langue Macédoine",
    "Langue étrangère - Lituanien",
    "Langue étrangère - Moldave",
    "Langue étrangère - Néerlandais",
    "Langue étrangère - Norvégien",
    "Langue étrangère - Polonais",
    "Langue étrangère - Portugais",
    "Langue étrangère - Russe",
    "Langue étrangère - Serbe (version iékavienne)",
    "Langue étrangère - Slovaque",
    "Langue étrangère - Slovène",
    "Langue étrangère - Suédois",
    "Langue étrangère - Tchèque",
    "Langue étrangère - Turc",
    "Langue étrangère - Ukrainien",
    "Langage informatique MySQL",
    "Framework Apache Struts",
    "Microsoft Active Directory",
    "Microsoft Exchange Server",
    "Langues anciennes",
    "Permis bateau de plaisance option côtière",
    "Permis plaisance option côtière - Extension hauturière",
    "Permis bateau de plaisance option eaux intérieures",
    "Permis Carte mer",
    "Permis de conduire A",
    "Permis de conduire B",
    "Permis de conduire D",
    "Permis de conduire E",
    "Permis de conduire E (C)",
    "Permis de conduire E (D)",
    "Habilitation gaz PGN",
    "Habilitation gaz PGP (Gaz propane)",
    "Brevet National de Pisteur Secouriste 1er degré (BNPS)",
    "Brevet National de Pisteur Secouriste 2eme degré (BNPS)",
    "Brevet National de Pisteur Secouriste 3eme degré (BNPS)",
    "Brevet National de Sécurité et de Sauvetage Aquatique (BNSSA)",
    "Habilitation agent cynophile de sécurité",
    "Habilitation Contrôle Non Destructif (CND)",
    "Habilitation Premiers secours en équipe de niveau 1 (PSE1)",
    "Habilitation Prévention et Secours Civiques de niveau 1 (PSC1)",
    "Attestation Coordination Sécurité et Protection de la Santé (CSPS) niveau 1",
    "Attestation Coordination Sécurité et Protection de la Santé (CSPS) niveau 2",
    "Attestation Coordination Sécurité et Protection de la Santé (CSPS) niveau 3",
    "Attestation Ordonnancement, Pilotage et Coordination de chantier (OPC)",
    "Certificat d'Aptitude au Travail sur Corde (CATSC)",
    "Sciences physico-chimiques et expérimentales",
    "Traitement des déchets spéciaux",
    "Procédures de traitement des déchets",
    "Traitement des déchets urbains",
    "Caractéristiques de la chaîne logistique (Supply Chain)",
    "Équipements Sous Pression (ESP)",
    "Culture sportive",
    "Outils de fabrication numérique",
    "Protection des données numériques",
    "Droit de l'internet",
    "Travail des métaux (laiton, cuivre, étain, bronze)",
    "Creusage de bois",
    "Achevage et réglage",
    "Commande numérique",
    "Ouvrages d'art",
    "Système de Management Environnement (SME)",
    "Procédés de valorisation des déchets",
    "Traitement des eaux potables",
    "Travaux neufs",
    "Chaîne cinématique",
    "Boulage des pâtons",
    "Embarcation à moteur",
    "Embarcation à rames",
    "Protection des biens",
    "Protection des personnes",
    "Protection de données sensibles",
    "Sécurité incendie",
    "Sécurité aéroportuaire",
    "Sécurité du travail",
    "Prévention incendie",
    "Techniques de service plat sur table",
    "Licence JAR 66 B2 - entretien en ligne sur les systèmes avioniques et électriques",
    "Techniques de storytelling - communication narrative",
    "Licence JAR 66 C - visite d'entretien en base principale",
    "Appareils électroniques de navigation",
    "Équipements de sécurité",
    "Équipements et systèmes médico-chirurgicaux",
    "Bain de traitement de surface",
    "Robot de traitement",
    "Cabine de peinture",
    "Équipements de vol",
    "Groupes propulsifs",
    "Installations climatiques",
    "Conditionnement d'air",
    "Installations frigorifiques",
    "Moteurs bicylindres",
    "Moteurs hors-bord",
    "Moteurs monocylindres",
    "Moteurs in-bord",
    "Centrale hydraulique",
    "Techniques de service à l'anglaise",
    "Techniques de service à la russe (au guéridon)",
    "Techniques de service à la française",
    "Techniques de service à l'assiette clochée",
    "Langue étrangère - Arménien",
    "Installations surveillance-intrusion",
    "Systèmes de sécurité-incendie",
    "Procédés de trituration",
    "Techniques de calandrage",
    "Utilisation de fours, d'étuves",
    "Posage et emboitage en horlogerie",
    "Habillage de pièces d'horlogerie",
    "Façonnage de matériaux organiques",
    "Étirage des métaux",
    "Techniques de forgeage",
    "Techniques de laminage",
    "Techniques de martelage",
    "Techniques de planage",
    "Repoussage",
    "Dorure à la feuille",
    "Gravure au pointillé",
    "Gravure au stylet",
    "Gravure de lettres et chiffres",
    "Gravure de motifs géométriques",
    "Gravure décorative",
    "Gravure en creux",
    "Gravure en relief",
    "Gravure héraldique",
    "Building Information Modelling (BIM)",
    "Hygiène hospitalière",
    "Nettoyage mécanique ou motorisé",
    "Caractéristiques d'autolaveuse",
    "Machine de nettoyage haute pression",
    "Machine de nettoyage à injection ou extraction",
    "Machine de nettoyage électromécanique",
    "Caractéristiques des articles chaussants",
    "Ligne de traitement de surface",
    "Caractéristiques des turbines à gaz",
    "Caractéristiques des turbines à vapeur",
    "Caractéristiques des turbines hydroélectriques",
    "Fiche de vie d'un matériel ou équipement",
    "Sérigraphie",
    "Gravure sur verre à la meule, à la roue",
    "Grisaille, cémentation sur verre",
    "Métallisation, dorure, argenture de verre",
    "Peinture sur verre",
    "Polissage, dépolissage de verre",
    "Techniques de sablage de verre",
    "Techniques de taille du verre",
    "Caractéristiques des articles de lingerie/balnéaire/corseterie",
    "Maltage de céréales",
    "Brassage de céréales",
    "Fermentation de fromage",
    "Vinification",
    "Techniques de distillation",
    "Transformation du lait",
    "Types de lait",
    "Cuves de fermentation",
    "Matériel vinicole",
    "Droit international",
    "Prospection commerciale",
    "Éthique des affaires",
    "Finance d'entreprise",
    "Administration des ventes",
    "Calculs commerciaux",
    "Techniques d'ébranchage",
    "Gestion des flux de production",
    "Manipulation de fromages",
    "Certificat de Préposé au Tir (CPT)",
    "Législation sur le tabac",
    "Sténotypie",
    "Science alimentaire",
    "Contrôle microbiologique",
    "Analyse physico-chimique environnementale",
    "Gestion des Déclarations Sociales",
    "Indicateurs des Ressources Humaines",
    "Accrochage de carcasses / viandes",
    "Anesthésie animale",
    "Étourdissement d'un animal par électrocution/électronarcose",
    "Éviscération animale",
    "Classification de carcasses",
    "Techniques de dépouillement d'un animal",
    "Techniques de saignée d'un animal",
    "Techniques de drainage",
    "Techniques de terrassement",
    "Techniques d'engazonnement",
    "Gestion juridique des contentieux",
    "Tronçonnage",
    "Techniques de taille des arbustes",
    "Gestion juridique des précontentieux",
    "Rédaction d'actes juridiques",
    "Procédure civile judiciaire",
    "Règles pénitentiaires européennes",
    "Protection de l'enfance",
    "Droit à l'image",
    "Droit de la presse",
    "Sciences de l'information et des bibliothèques",
    "Logiciel de gestion de version CVS (Concurrent Versions System)",
    "Logiciel de gestion de version GIT",
    "Recherche iconographique",
    "Logiciel de gestion de version SVN (Apache Subversion)",
    "Chaîne d'abattage",
    "Techniques de taille de fructification",
    "Techniques d'ébourgeonnage",
    "Techniques d'effeuillage",
    "Techniques de rempotage",
    "Techniques de désherbage",
    "Techniques de palissage",
    "Techniques de fauchage",
    "Techniques de moissonnage",
    "Techniques de pressage",
    "Caséologie",
    "Techniques de salage d'un fromage",
    "Caractéristiques des agents affinants",
    "Caractéristiques des matières",
    "Réglementation des bioprocédés",
    "Génie chimique",
    "Modalités de démarrage d'une installation chimique",
    "Chimie minérale",
    "Procédures de test chimique ou thermique",
    "Infrastructure",
    "Voirie Réseaux Divers (VRD)",
    "Foreuse mobile",
    "Caractéristiques des haveuses",
    "Foreuse jumbo",
    "Caractéristiques des scies de chantier",
    "Techniques du badigeon",
    "Réglementation de l'assurance Responsabilité Civile",
    "Réglementation de l'assurance Construction dommages-ouvrage",
    "Réglementation de l'assurance-crédit",
    "Réglementation de l'assurance des risques industriels",
    "Droit de l'informatique",
    "Droit de la propriété industrielle",
    "Roches ornementales",
    "Chauffage solaire",
    "Gestion administrative des contrats de travail",
    "Caractéristiques des toitures en pierre",
    "Caractéristiques des toitures en bois",
    "Caractéristiques des toitures en chaume",
    "Caractéristiques des toitures métalliques",
    "Analyse chimique",
    "Contrôle biochimique",
    "Marché des entreprises",
    "Électricité du domaine des Voix, Données, Images (VDI)",
    "Électricité industrielle",
    "Symboles des éléments électriques",
    "Technologie des équipements électriques",
    "Traitement numérique",
    "Principes de la relation client et fournisseur",
    "Caractéristiques de la documentation médicale",
    "Caractéristiques des supports textiles",
    "Caractéristiques des vêtements professionnels et de cérémonie",
    "Caractéristiques des linges de maison",
    "Techniques de cristallisation de surfaces/sols",
    "Techniques de décapage de surfaces/sols",
    "Techniques de cirage de surfaces/sols",
    "Techniques de lustrage de surfaces/sols",
    "Techniques de grésage de surfaces/sols",
    "Techniques de récurage de surfaces/sols",
    "Techniques de shampouinage de surfaces/sols",
    "Techniques de surfaçage de surfaces/sols",
    "Protection et sauvegarde du milieu naturel",
    "Caractéristiques des dossiers de commande",
    "Caractéristiques des fiches d'intervention",
    "Système de rétention d'un arbre",
    "Bombage du verre",
    "Coulage du verre dans moule, casting",
    "Coulage en sable",
    "Filage du verre",
    "Fonte de pâte de verre",
    "Étirement du verre",
    "Techniques de sculptage du verre par forme pleine",
    "Techniques de soufflage du verre à main levée",
    "Techniques de soufflage du verre au chalumeau",
    "Techniques de soufflage du verre au moule",
    "Thermoformage du verre, fusing",
    "Design transports aérien et maritime",
    "Techniques de patine",
    "Contrôle technique",
    "Marché des capitaux",
    "Analyse stratégique",
    "Arbitrage de process budgétaire",
    "Contrôle budgétaire",
    "Méthode de l'imputation rationnelle",
    "Écarts sur charges directes",
    "Écarts sur charges indirectes",
    "Techniques de calibration",
    "Polissage manuel",
    "Techniques de désenvasement",
    "Techniques de vernissage",
    "Polissage mécanique",
    "LBO (Leverage Buy-Out)",
    "Techniques de ravalement",
    "Droits d'enregistrement",
    "Responsabilité limitée / illimitée",
    "Magie de rue",
    "Grande illusion",
    "Magie rapprochée (close-up)",
    "Techniques d'escamotage",
    "Escapologie",
    "Mentalisme",
    "Techniques de gravure",
    "Techniques de ciselure",
    "Stérilisation de matériel",
    "Méthodes de décontamination de matériel",
    "Énergie renouvelable",
    "Contrôle par brouillards",
    "Contrôle par caméra",
    "Contrôle qualité non destructif (CND)",
    "Contrôle visuel",
    "Contrôle par électromagnétisme",
    "Contrôle par fluorescence",
    "Contrôle par magnétoscopie",
    "Contrôle par radiographie",
    "Contrôle par réaction chimique",
    "Contrôle par résistance et essai mécanique",
    "Contrôle par ressuage",
    "Contrôle par spectrométrie",
    "Contrôle par ultrasons",
    "Contrôle spécifique d'étanchéité de tuyauterie",
    "Contrôle spécifique de ressuage de tuyauterie",
    "Appareil de production électrique ou hydraulique",
    "Appareil de propulsion à moteur",
    "Appareil de propulsion à vapeur",
    "Climatisation",
    "Neige de culture",
    "Mandat ad hoc",
    "Procédure de conciliation",
    "Procédure de sauvegarde",
    "Budget de trésorerie",
    "Déconditionnement",
    "Habilitation Dépollution pyrotechnique",
    "Gestion de trésorerie internationale",
    "Techniques de dépigeonnisation",
    "Dératisation",
    "Techniques de désinsectisation",
    "Traitement de l'humidité",
    "Traitement des xylophages",
    "Traitement anti-tâche",
    "Techniques d'imperméabilisation",
    "Certificat d'Initiation Nautique",
    "Permis de conduire de moteur marin",
    "Habilitation Agent de prévention et de sécurité",
    "Business Objects Designer",
    "Recommandations AMF",
    "Fabrication manuelle",
    "Stérilisation",
    "Radar optique",
    "Radar de poursuite",
    "Délignage",
    "Pupitre de commande",
    "Armoires à relais",
    "Langages de programmation de Commande Numérique (CN)",
    "Données de contrôle",
    "Caractéristiques des microscopes",
    "Caractéristiques des PH-mètres",
    "Outil de contrôle",
    "Appareil de contrôle de programmation",
    "Équipement de commande",
    "Techniques de marqueterie",
    "Banc de contrôle",
    "Console de contrôle",
    "Machines à commandes numériques",
    "Approvisionnement de distributeurs automatiques de consommables",
    "Organisation d'une tournée",
    "Routage",
    "Bon de livraison",
    "Produits d'entretien textile",
    "Produits de détachage",
    "Produits de nettoyage",
    "Symboles d'entretien des textiles",
    "Manipulation de produits chimiques",
    "Techniques de désamiantage",
    "Préparation d'apprêt",
    "Ébénisterie",
    "Paysage dans l'art",
    "Calligraphie",
    "Montage et assemblage en chapellerie",
    "Histoire du chapeau",
    "Techniques de travail à la pédale",
    "Composition florale",
    "Plan de fabrication, montage en menuiserie",
    "Assemblage par bouvetage, queue d'aronde",
    "Assemblage par enture",
    "Assemblage par tourillonnage, tenon-mortaise, pigeonnage",
    "Pose d'éléments de quincaillerie",
    "Tonnellerie",
    "Tournage de bois",
    "Assemblage par vissage, agrafage, connecteur",
    "Paysagisme",
    "Nature morte",
    "Techniques du portrait",
    "Manipulation de marionnettes",
    "Procédures de dépollution",
    "Caractéristiques des apparaux",
    "Techniques de débroussaillage",
    "Techniques de faucardage",
    "Accastillage",
    "Matériel de navigation",
    "Computer To Plate (CTP)",
    "Computer To Film (CTF)",
    "Comptabilité multi devise",
    "Traitement anticorrosion",
    "Assurance-crédit",
    "Pliage textile automatisé",
    "Analyse des coûts",
    "Ratios financiers",
    "Normes US Gaap",
    "Garantie légale de conformité",
    "Supports de charges",
    "Matériel de contrôle embarqué",
    "Matériel d'aide à la conduite",
    "Outils de suivi de marchandises",
    "Carte électronique",
    "Caractéristiques des mécanismes horlogers",
    "Supports de manutention",
    "Techniques de scratching",
    "Techniques de mixage",
    "Caractéristiques des vêtements junior, adolescent",
    "Caractéristiques des vêtements senior",
    "Caractéristiques des vêtements premier âge",
    "Prêt-à-porter",
    "Sportswear",
    "Techniques d'affleurage",
    "Techniques d'arasage",
    "Travail de bois ancien",
    "Travail de bois précieux, exotique",
    "Travail de matériaux d'origine animale (corne, écaille, nacre, os)",
    "Fabrication d'orgue",
    "Haute couture",
    "Prêt-à-porter de luxe",
    "Articles en chaîne et trame",
    "Fonte à la cire perdue",
    "Travail de l'aluminium",
    "Travail de l'acier",
    "Travail du bronze",
    "Lingerie, corsetterie",
    "Gestion administrative du temps de travail",
    "Caractéristiques des dossiers techniques",
    "Caractéristiques des dossiers de maintenance",
    "Transformation industrielle",
    "Médecine du travail",
    "Sciences religieuses et théologie",
    "Transformation de matériaux souples",
    "Sciences humaines et sociales",
    "Charte des FabLabs",
    "Règlementation de l'information et de la protection des consommateurs",
    "Caractéristiques des boucles de régulation",
    "Économie sociale",
    "Sciences exactes",
    "Gestion de l'information",
    "Nouvelles Technologies de l'information et de la Communication (NTIC)",
    "Référencement naturel (SEO)",
    "Veille sanitaire",
    "Techniques de broyage",
    "Techniques de conditionnement",
    "Fumage",
    "Salaison",
    "Tranchage",
    "Architecture du service téléphonique d'entreprise",
    "Bonnes Pratiques Agricoles (BPA)",
    "Standard téléphonique IPBX",
    "Serveur Vocal Interactif (SVI)",
    "Techniques de séchage",
    "Caractéristiques des motoculteurs",
    "Caractéristiques des scarificateurs",
    "Caractéristiques des taille-haies",
    "Caractéristiques des tracteurs",
    "Caractéristiques des débroussailleuses",
    "Caractéristiques des motofaucheuses",
    "Diagramme de Gantt",
    "Caractéristiques des souffleuses",
    "Caractéristiques des tondeuses",
    "Caractéristiques des tronçonneuses",
    "Droit de la formation",
    "Législation du commerce de détail de biens d'occasion",
    "Conchyliologie",
    "Analyse des risques professionnels",
    "Techniques de dessablage",
    "Techniques de filtrage",
    "Techniques de clarification",
    "Élimination des levures",
    "Caractéristiques des spiritueux",
    "Zymologie",
    "Biérologie",
    "Oenologie du cidre",
    "Procédures de liquidation judiciaire",
    "Crédit syndiqué",
    "Équilibrage de comptes",
    "Cash Pooling",
    "Crédits de trésorerie",
    "Négociation bancaire",
    "Caractéristiques des céramiques",
    "Caractéristiques du fer",
    "Caractéristiques des métaux ferreux",
    "Caractéristiques des métaux non ferreux",
    "Caractéristiques des pierres",
    "Arbitrage économique",
    "Analyse médicale",
    "Météorologie aéronautique",
    "Météorologie tropicale",
    "Météorologie maritime",
    "Techniques de modélisation",
    "Numérisation",
    "Physique nucléaire",
    "Méthode RACI",
    "Yield management",
    "Utilisation d'équipements frigorifiques",
    "Utilisation d'équipements de conditionnement d'air",
    "Confection de produits",
    "Outils de veille",
    "Radiocommunication aéronautique",
    "Approvisionnement de fonds numéraires",
    "Techniques de reportage",
    "Principes du béton précontraint",
    "Enrobés",
    "Caractéristiques des granulats",
    "Propriétés du plâtre",
    "Techniques de boisage",
    "Caractéristiques des coffrages",
    "Fusion des métaux",
    "Procédures de contrôle mécanique",
    "Statistiques",
    "Calcul de surface",
    "Caractéristiques de la chaux",
    "Comportement des systèmes mécaniques",
    "Plâtre en poudre",
    "Caractéristiques du staff",
    "Caractéristiques du stuc",
    "Protection du littoral",
    "Sauvegarde d'espèces menacées",
    "Équipements d'imprimerie",
    "Niveaux de maintenance",
    "Manutention ferroviaire",
    "Manutention portuaire",
    "Manutention aéroportuaire",
    "Méthode Merise",
    "Méthode Rétro-Conception",
    "Conduite de projet en intervention sociale",
    "Utilisation de l'outil Maven",
    "Outils de gestion de projet - OBS (Open Broadcaster Software)",
    "Outils de gestion de projet - PERT (Project Evaluation and Review Technique)",
    "Outils de gestion de projet - WBS (Work Breakdown Structure / Structure de découpage du projet)",
    "Outils de planification",
    "Revêtements stratifiés",
    "Techniques de curage",
    "Techniques de dragage",
    "Travaux souterrains",
    "Outils manuels de maçonnerie",
    "Revêtements souples",
    "Habilitation Nucléaire HN2",
    "Habilitation Nucléaire HN1",
    "Habilitation Prévention des Risques PR1",
    "Habilitation Prévention des risques PR 2",
    "Habilitation opérateur de parcours acrobatique en hauteur",
    "Habilitation Soudage à l'arc électrique en pleine eau",
    "Habilitation Qualité Sûreté Prestataires (QSP)",
    "Habilitation Scaphandrier professionnel",
    "Habilitation plongée",
    "Habilitations électriques de travaux hors tension",
    "Habilitations électriques de travaux sous tension",
    "Habilitations hyperbarie",
    "Habilitation N1 (risques chimiques)",
    "Habilitation N2 (risques chimiques)",
    "Habilitation risques biologiques",
    "Attestation d'aptitude à la manipulation des fluides frigorigènes",
    "Techniques du moulage à la toile sur mannequin",
    "Chirurgie",
    "Gériatrie",
    "Gynécologie",
    "Hépato-gastro-entérologie",
    "Pneumologie",
    "Ophtalmologie",
    "Orthophonie",
    "Orthoptie",
    "Psychiatrie",
    "Néphrologie",
    "Neurologie",
    "Kinésithérapie",
    "Néonatologie",
    "Pharmacie à Usage Interne (PUI)",
    "Psychiatrie infantile",
    "Chirurgie infantile",
    "Endoscopie",
    "Caractéristiques du bloc opératoire",
    "Orthogénie",
    "Caractéristiques des chambres d'isolement",
    "Radiologie interventionnelle",
    "Protection Maternelle et Infantile (PMI)",
    "Soins intensifs et réanimation",
    "Hospitalisation à domicile",
    "Soins infirmiers",
    "Caractéristiques d'une salle de Soins Post-Interventionnelle (SSPI)",
    "Chirurgie cardiaque",
    "Chirurgie dentaire",
    "Chirurgie digestive",
    "Chirurgie maxillo-faciale",
    "Chirurgie thoracique",
    "Chirurgie vasculaire",
    "Chirurgie orthopédique",
    "Chirurgie pédiatrique",
    "Chirurgie reconstructive et esthétique",
    "Médecine générale",
    "Chirurgie viscérale",
    "Exploration fonctionnelle",
    "Neurochirurgie",
    "Urologie",
    "Orthopédie",
    "Acupuncture",
    "Techniques de dépeçage",
    "Techniques de décharnage",
    "Techniques d'équarrissage",
    "Matériel d'automatisation",
    "Architecture réseau",
    "Techniques de détachage",
    "Adjoint pilote privé hélicoptère (IATH)",
    "Pilote privé hélicoptère (ITTH)",
    "Pilote professionnel hélicoptère (IPPH)",
    "Qualification de type hélicoptère (TRIH)",
    "Qualification de classe avion (CRIA)",
    "Qualification de type avion (TRIA)",
    "Qualification de vol aux instruments avion (IRIA)",
    "Entraîneur de vol synthétique avion (SFIA)",
    "Qualification de vol avion (FIA)",
    "Qualification de vol aux instruments hélicoptère (IRIH)",
    "Médecine d'urgence",
    "Qualification de vol hélicoptère (FIH)",
    "Programmes de nettoyage",
    "Entraîneur de vol synthétique hélicoptère (SFIH)",
    "Clippage de bijoux",
    "Techniques de brunissage",
    "Exploitation d'un navire de pêche",
    "Appoint de fluides frigorigènes",
    "Contrecollage",
    "Techniques d'enduction",
    "Apprêt textile",
    "Procédés de teinture",
    "Techniques de lavage de textile",
    "Prétraitement textile",
    "Traitement des peaux et cuirs",
    "Chiffrage projet",
    "E-procurement",
    "Cahier des charges",
    "Préparation d'une commande",
    "Conditionnement manuel",
    "Procédures de conditionnement",
    "Techniques de reconditionnement",
    "Règles de rotation de produits alimentaires",
    "Traitement sous vide",
    "Appareil de conditionnement",
    "Conseiller le client sur les différents produits (transports, prestations), effectuer la réservation et établir les documents contractuels",
    "Établir les modalités de règlement d'un litige ou transmettre aux services juridiques, contentieux",
    "Collecter la documentation touristique et organiser l'affichage de l'espace de vente",
    "Intervenir auprès d'une clientèle d'affaires",
    "Établir des titres de transport pour des déplacements en avion",
    "Établir des titres de transport pour des déplacements en bateau",
    "Établir des titres de transport pour des déplacements en train",
    "Mener des actions dans le cadre de centrale de réservation",
    "Mener des actions dans le cadre de centre d'appels",
    "Mener des actions dans le cadre de plateau d'affaires",
    "Vérifier les éléments d'activité du personnel",
    "Déterminer et mettre en place l'organisation de l'exploitation (horaires, accueil du public, utilisation des équipements, ...)",
    "Coordonner les activités d'un casino",
    "Superviser l'élaboration des menus du restaurant et vérifier leur composition, l'équilibre alimentaire et le prix de revient",
    "Cuisiner des plats pour des enfants",
    "Cuisiner des plats pour des personnes âgées",
    "Cuisiner des plats pour des personnes médicalisées",
    "Changer les draps, refaire le lit (mise à blanc, recouche) et remplacer le linge de toilette",
    "Identifier et signaler les dysfonctionnements, les dégradations du mobilier et des équipements de la chambre",
    "Renseigner le client sur les prestations de l'établissement et les modalités de réservation",
    "Accueillir les clients à leur arrivée et effectuer les formalités administratives liées à leur séjour",
    "Préparer et effectuer le service des petits-déjeuners en salle, en chambre",
    "Intervenir auprès de la clientèle pour des demandes particulières d'installation de fauteuil ou lit supplémentaire, ...",
    "Concevoir les menus, les cartes et définir les tarifs des plats",
    "Cuisiner des spécialités régionales françaises",
    "Préparer les viandes et les poissons (brider, barder, vider, trancher, ...)",
    "Renseigner le client sur la composition des produits (salades, sandwichs, ...) et enregistrer sa commande",
    "Préparer des plats simples (salades, assiette de crudités, de fromages, desserts, ...)",
    "Remettre la commande au client (service à l'assiette, emballage à emporter, plateau, ...)",
    "Préparer et/ou réchauffer des pains",
    "Préparer des crêpes (salées, sucrées)",
    "Préparer des salades",
    "Réaliser la plonge",
    "Essuyer et ranger la vaisselle, la verrerie, les ustensiles de cuisine",
    "Préparer, assembler des desserts simples",
    "Préparer, assembler des entrées (crudités, charcuterie, ...)",
    "Conseiller et orienter les clients dans leurs démarches durant leur séjour (choix de visite, achat d'objets particuliers, ...)",
    "Réaliser, confirmer des réservations de billets d'avion, de tickets de spectacle, de restaurant pour les clients de l'établissement",
    "Superviser le service de la bagagerie, le stationnement des véhicules et organiser le transport des bagages",
    "Accueillir le client à son arrivée et à son départ de l'établissement",
    "Surveiller les lieux, les biens et effectuer des rondes de prévention et de détection de risques",
    "Enregistrer les réservations et actualiser le planning d'occupation des chambres, des appartements, des bungalows, ...",
    "Répondre aux demandes des clients durant leur séjour",
    "Prendre la commande des clients",
    "Enregistrer les réservations et actualiser le plan d'occupation des tables, des salles du restaurant",
    "Accueillir le client à son arrivée au restaurant, l'installer à une table et lui présenter la carte",
    "Conseiller le client dans ses choix de plats selon ses goûts, les suggestions du jour et prendre sa commande",
    "Organiser et contrôler le service en salle (dressage des tables, liaison salle-cuisine, ...)",
    "Concevoir la carte des vins et des boissons selon le type de cuisine, la situation géographique",
    "Présenter la carte des vins, conseiller les clients et prendre la commande",
    "Préparer le vin sélectionné (mise en température, décantation, ...), choisir la verrerie adaptée et servir le client",
    "Informer les clients des problèmes techniques relevés et des modifications et réparations à apporter",
    "Déterminer les solutions et préconisations techniques et les communiquer aux clients",
    "Intervenir en instrumentation",
    "Intervenir en mécanique",
    "Élaborer des solutions techniques et financières",
    "Analyser la couleur d'un échantillon client et déterminer les types et dosages de composants selon les caractéristiques du produit",
    "Réaliser la teinte et réaliser les essais de couleurs ou d'effets sur les matières",
    "Définir les quantités de teinture/coloration et les modalités de fabrication",
    "Contrôler les résultats de la teinture/coloration et apporter des ajustements",
    "Actualiser les fiches recettes des teintes pour la production",
    "Calculer et définir les puissances, grandeurs, contraintes physiques de composants, sous-ensembles, ensembles",
    "Étudier et concevoir les circuits imprimés, sous-ensembles ou ensembles",
    "Réaliser et faire évoluer les schémas, les plans de détails, de sous-ensembles ou d'ensembles",
    "Constituer et faire évoluer les nomenclatures des plans, dossiers de définition",
    "Assister techniquement les services de l'entreprise ou les clients",
    "Identifier la demande et réaliser les ébauches, schémas de pièces, systèmes, sous-ensembles ou ensembles",
    "Réaliser les relevés dimensionnels de pièces, sous-ensembles ou ensembles",
    "Étudier et concevoir des pièces, sous-ensembles ou ensembles",
    "Déterminer les spécifications et les cotations des pièces, sous-ensembles ou ensembles",
    "Intervenir dans le domaine de la construction mécanique",
    "Intervenir dans un domaine spécifique : Chaudronnerie, tôlerie, tuyauterie",
    "Intervenir dans le domaine des structures métalliques",
    "Intervenir dans le domaine des outillages et moules industriels",
    "Intervenir dans le domaine des machines spéciales",
    "Intervenir dans le domaine des Plastiques, caoutchouc et composites",
    "Dessiner des avant-projets (roughs, croquis) à partir du concept, des thèmes définis",
    "Intervenir en équipements de loisirs, jeux, jouets",
    "Réaliser des dessins artistiques",
    "Réaliser des travaux de maquettes, prototypes",
    "Adapter un patron/gabarit à partir du croquis",
    "Réaliser les fiches techniques des articles (montage, matières, type de piqûre, ...) ou présenter les caractéristiques techniques aux prototypistes",
    "Détecter les difficultés de montage, les non conformités (esthétique, tomber de vêtement, bien-aller) et apporter des solutions techniques",
    "Réaliser les documents techniques relatifs au patron modèle en vue de l'industrialisation de l'article",
    "Assister techniquement (suivi des têtes de série, explication de schéma de montage) différents intervenants (technicien méthodes, personnel de production)",
    "Réaliser le patron modèle de chaussures et articles chaussants",
    "Réaliser le patron modèle de costumes de scène/costumes à thème",
    "Réaliser le patron modèle d'articles haute couture/prêt à porter de luxe",
    "Réaliser le patron modèle de produits à manches (tailleur, manteau, ...)",
    "Réaliser le patron modèle de vêtements de cérémonie",
    "Réaliser le patron modèle de vêtements professionnels",
    "Définir les méthodes, les moyens d'études et de conception et leur mise en oeuvre",
    "Réaliser des tests et essais, analyser les résultats et déterminer les mises au point du produit, du procédé",
    "Intervenir en recherche",
    "Intervenir en études, ingénierie",
    "Intervenir en développement",
    "Intervenir en essai, validation",
    "Intervenir en industrialisation",
    "Concevoir les spécifications de l'installation automatisée en fonction du cahier des charges",
    "Réaliser l'analyse fonctionnelle de l'installation et la décliner en un programme d'automatisation",
    "Saisir la réinitialisation ou la modification du programme d'automatisation et le transférer vers un automate programmable",
    "Réaliser les dossiers d'équipement de l'installation automatisée (plan, schémas, notices d'exploitation, ...)",
    "Vérifier le programme d'automatisation par une série de tests sur plate-forme d'essais, en atelier, sur site",
    "Effectuer les réglages de mise en service des dispositifs et instruments commandés (vérin, moteur, distributeur, transmetteurs, capteurs, régulateurs, ...)",
    "Sélectionner les composants électroniques, électriques à partir de bases de données, de nomenclatures fournisseurs",
    "Vérifier par simulation, tests, essais, calculs, les fonctionnalités et les caractéristiques du système électrique ou électronique",
    "Réaliser ou faire évoluer les schémas, les plans à partir des fonctionnalités et caractéristiques du système électrique ou électronique",
    "Intervenir en électronique analogique",
    "Intervenir en électronique numérique",
    "Intervenir en électronique de puissance",
    "Préparer des échantillons et réaliser le montage de tests et d'essais selon un protocole",
    "Réaliser les mesures et les analyses, identifier des non-conformités et réaliser des ajustements techniques",
    "Relever les données et les consigner dans les cahiers de laboratoire, les bases de données, les registres, les comptes rendus",
    "Concevoir des consignes, procédures, cahiers des charges, rapports d'études à partir des résultats de tests et d'essais",
    "Vérifier la conformité réglementaire et fonctionnelle des équipements et installations au moyen de contrôles, mesures, essais et relevés",
    "Renseigner les supports de contrôle et de constat (listes, grilles, documents, fiches, ...)",
    "Réaliser les rapports de contrôles et de diagnostics d'anomalie ou de risque et les transmettre aux services production, maintenance, sécurité...",
    "Conseiller et prescrire des solutions techniques correctives, préventives ou d'amélioration des équipements",
    "Suivre des indicateurs",
    "Définir les phases de production ou les modifier en fonction des écarts constatés",
    "Intervenir en méthodes industrialisation",
    "Intervenir en dispatching",
    "Organiser et coordonner le projet de fabrication ou d'industrialisation",
    "Intervenir en méthodes process",
    "Intervenir en méthodes produit",
    "Intervenir en méthodes atelier fabrication",
    "Intervenir en gestion industrielle et logistique",
    "Intervenir en méthodes - industrialisation",
    "Planifier et suivre la réalisation des mesures et analyses et interpréter les résultats",
    "Valider les résultats de mesures et d'analyses et les communiquer aux services qualité, production, aux clients, aux élus, ...",
    "Intervenir dans le domaine de la bactériologie",
    "Intervenir dans le domaine de la biologie et de la microbiologie",
    "Intervenir dans le domaine de la biochimie",
    "Intervenir en radiochimie, dosimétrie et métrologique des rayonnements",
    "Intervenir dans le domaine de la chromatographie",
    "Préparer les produits et les appareils de mesures et d'analyses et contrôler leur conformité d'étalonnage et de fonctionnement",
    "Réceptionner les échantillons ou effectuer les prélèvements de matières, de produits",
    "Mesurer et analyser, relever les données et les transmettre",
    "Consigner les résultats de mesures et d'analyses et renseigner les supports de suivi",
    "Sélectionner le matériel de contrôle ou d'essais et vérifier sa conformité",
    "Analyser les mesures, diagnostiquer les causes de dysfonctionnement et effectuer les modifications de mise en conformité du produit",
    "Renseigner les supports de suivi d'intervention et rédiger les procès-verbaux (essais, contrôles, ...)",
    "Sélectionner les matières premières (arômes naturels, essences, produits de synthèse, ...) auprès de fournisseurs",
    "Vérifier les caractéristiques organoleptiques (successions de sensations, pyramide olfactive, équilibre, stabilité, ...) du produit final",
    "Surveiller l'approvisionnement des machines, des installations et le flux des matières ou des produits",
    "Surveiller le fonctionnement des machines et des équipements et effectuer des interventions simples en cas d'incident",
    "Positionner préalablement les pièces, éléments, sous-ensembles (assises, montants, ...), après traçages (épures, emplacements, ...)",
    "Poser les composants d'assemblage (tourillons, languettes, connecteurs métalliques, ...) et encoller les parties jointives",
    "Vérifier la conformité du montage/assemblage (aspect, stabilité, équerrage, jeu, ...) et procéder aux ajustements (resserrage final, maintien, ...)",
    "Réaliser des opérations d'agencements",
    "Monter des fermettes",
    "Monter des jouets",
    "Monter des meubles",
    "Monter des placages",
    "Monter des sièges",
    "Intervenir sur des produits en aluminium",
    "Intervenir sur des produits en bois et dérivés (panneaux de bois, ...)",
    "Intervenir sur des produits en matériaux synthétiques et composites (plastiques, ...)",
    "Intervenir sur des produits en PVC",
    "Réaliser la pose de joints (isolation, étanchéité, ...) sur des ouvrages en bois",
    "Réaliser des opérations de classement ou tri des bois de placage (aspect, défauts, utilisation, ...)",
    "Réaliser des opérations de découpe de feuilles de placage",
    "Réaliser des opérations d'encollage relatives au placage",
    "Réaliser des opérations de jointage/assemblage de feuilles de placage",
    "Réaliser des opérations de pressage relatives au placage",
    "Vérifier les éléments de sécurité sur les équipements de production (machine-outil, ligne de montage, ...)",
    "Démarrer la machine ou l'équipement et surveiller le déroulement des opérations de production",
    "Réaliser des opérations de corroyage sur des ouvrages en bois",
    "Réaliser des opérations de défonçage sur des ouvrages en bois",
    "Réaliser des opérations de déformation/cintrage sur des ouvrages en bois",
    "Réaliser des opérations de dégauchissage sur des ouvrages en bois",
    "Réaliser des opérations de délignage sur des ouvrages en bois",
    "Réaliser des opérations de façonnage sur des ouvrages en bois",
    "Réaliser des opérations de finition sur des ouvrages en bois",
    "Réaliser des opérations de massicotage sur des ouvrages en bois",
    "Réaliser des opérations de mise en peinture sur des ouvrages en bois",
    "Réaliser des opérations de montage sur des ouvrages en bois",
    "Réaliser des opérations de mortaisage sur des ouvrages en bois",
    "Réaliser des opérations de moulurage sur des ouvrages en bois",
    "Réaliser des opérations de perçage sur des ouvrages en bois",
    "Réaliser des opérations de ponçage sur des ouvrages en bois",
    "Réaliser des opérations de rabotage sur des ouvrages en bois",
    "Réaliser des opérations de sciage sur des ouvrages en bois",
    "Réaliser des opérations de tournage sur des ouvrages en bois",
    "Réaliser des opérations de vernissage sur des ouvrages en bois",
    "Réaliser des opérations de fabrication/d'usinage sur des centres d'usinage",
    "Réaliser des opérations de fabrication/d'usinage sur des chaînes/lignes automatisées",
    "Réaliser des opérations de fabrication/d'usinage sur des équipements individuels automatisés",
    "Réaliser des opérations de fabrication/d'usinage sur des machines-outils à axes multiples",
    "Réaliser des opérations de fabrication/d'usinage sur des machines-outils à commandes numériques",
    "Réaliser des opérations de fabrication/d'usinage sur des machines-outils conventionnelles",
    "Définir et régler les paramètres des machines et équipements (températures, pressions, taux d'humidité, vitesses, dosages des mélanges)",
    "Surveiller la circulation des matières et produits (pâte, mat, bois de placage, ...) et les phases de mise en forme des panneaux (encollage, conformation, pressage, sciage, ...)",
    "Prélever des échantillons sur les panneaux fabriqués et procéder aux tests mécaniques, physiques, ...",
    "Analyser les résultats des tests et ajuster les paramètres de réglage",
    "Renseigner les supports de suivi de fabrication (quantités, incidents, ...)",
    "Intervenir sur la fabrication de panneaux à base de feuilles de bois (contre-plaqués, ...)",
    "Intervenir sur la fabrication de panneaux à base de fibres et particules (agglomérés, mélaminés, ...)",
    "Réaliser des opérations d'écorçage",
    "Réaliser des opérations de déroulage",
    "Identifier la nature des produits et enduits et en déterminer la quantité",
    "Repérer les particularités du montage de la toiture d'origine",
    "Poser des ardoises",
    "Poser des panneaux solaires",
    "Poser des éléments de verre",
    "Réparer les supports maçonnés, les façades avec du ciment, du mortier, ...",
    "Tailler la pièce ou les éléments de l'ensemble",
    "Réaliser des opérations de montage à joints secs",
    "Réaliser des opérations de montage au mortier",
    "Réaliser des opérations de montage au sol, aux murs en intérieur",
    "Réaliser des opérations de montage en façade",
    "Réaliser des opérations de pose de cheminées",
    "Réaliser des opérations de pose de monuments funéraires",
    "Concevoir des cheminées",
    "Concevoir des éléments de décor de spectacle",
    "Concevoir des cadrans solaires",
    "Sécher les surfaces (toitures, terrasses, ...)",
    "Réserver des ouvertures et éléments d'ouvrage prévus (exutoire de désenfumage, aération, ...)",
    "Réaliser la pose de réseaux de canalisations, fourreaux d'évacuation, branchements",
    "Sécuriser le chantier et ses abords (signalisation, balisage, déviations, ...) et établir les modalités de rotation des engins selon les contraintes du terrain",
    "Remblayer l'excavation et compacter les tranchées",
    "Répandre les granulats, les gravillons ou les déblayer",
    "Terrasser et niveler la fondation",
    "Réaliser et poser les coffrages et couler les éléments en béton",
    "Réaliser et lisser les joints",
    "Poser des tôles",
    "Poser des éléments de bardeau bitumé",
    "Réaliser la pose de bandes bitumées",
    "Réaliser la pose de bardeaux",
    "Réaliser la pose d'éléments de couverture",
    "Réaliser la pose de résines de synthèse",
    "Localiser les défaillances de canalisation et en identifier la nature",
    "Identifier la configuration et le dimensionnement d'un moule, le type d'armatures, le mode de coulage et de démoulage selon la pièce à fabriquer",
    "Préparer le matériel (coupe des fils et torons d'acier, ...) et la matière nécessaire à la réalisation du produit (précontraint, pièce béton)",
    "Positionner dans le moule ou le banc de précontrainte les aciers, armatures, inserts, réservations, ...",
    "Réaliser les éléments constitutifs d'un moule (sous ensemble métal, bois, pvc, armature) en vue du coulage du béton",
    "Couler le béton dans le moule ou le banc de précontrainte",
    "Décoffrer la pièce et démonter le moule",
    "Réaliser des pièces de précontrainte au moyen de banc de précontrainte (pré-dalles, poutrelles, dalles alvéolées, pré-linteaux)",
    "Réaliser des pièces de précontrainte au moyen de moules (poutres droites, poutres I, I-pannes)",
    "Conseiller la personne sur des services ou des produits régionaux, des articles souvenirs",
    "Collecter des informations sur l'offre touristique locale et présenter les nouveautés",
    "Intervenir sur une zone communale ou intercommunale",
    "Intervenir sur une zone départementale",
    "Intervenir sur une zone nationale",
    "Intervenir sur une zone régionale",
    "Coordonner des actions de promotion du patrimoine architectural et artistique",
    "Coordonner des actions de promotion du patrimoine gastronomique",
    "Coordonner des actions de promotion du patrimoine industriel",
    "Coordonner des actions de promotion du patrimoine naturel",
    "Recueillir les informations sur la durée, le nombre de participants, les spécificités de la prestation d'accompagnement touristique",
    "Informer les participants sur l'organisation de la prestation d'accompagnement",
    "Réaliser des visites ou conférences nécessitant une carte professionnelle guide conférencier",
    "Intervenir auprès d'un public scolaire",
    "Concevoir le programme des activités selon les spécificités du public",
    "Identifier les attentes du public et l'informer sur la ou les activités d'animation et les modalités d'organisation",
    "Concevoir la séance d'animation et préparer le matériel et l'espace d'animation",
    "Expliquer le mode de réalisation de l'activité (consignes, démonstration, ...), et coordonner les échanges au sein du groupe",
    "Guider les participants lors de la réalisation de l'activité ou proposer des adaptations selon leur progression",
    "Réaliser le bilan du projet d'animation et proposer des axes d'évolution",
    "Informer les parents sur l'organisation de la structure et présenter le programme des activités aux enfants",
    "Organiser ou adapter la séance d'animation selon le déroulement de la journée",
    "Préparer l'espace d'animation et guider les participants tout au long de la séance",
    "Surveiller le déroulement de l'activité et veiller au respect des consignes de jeux, des règles de vie sociale",
    "Repérer les difficultés ou problèmes d'un enfant, intervenir ou informer le directeur, les parents",
    "Réaliser des interventions nécessitant une habilitation BAFA",
    "Réaliser des interventions nécessitant une habilitation BAFD",
    "Informer le public sur la pratique de la discipline sportive et les modalités d'organisation des séances",
    "Évaluer le niveau de départ du pratiquant et déterminer les objectifs sportifs",
    "Concevoir la séance selon le niveau du public et préparer le matériel ou les équipements",
    "Présenter l'exercice aux pratiquants et apporter un appui technique",
    "Réaliser le suivi du matériel ou des équipements sportifs",
    "Réaliser le bilan du projet éducatif ou d'animation sportive et proposer des évolutions",
    "Intervenir auprès d'un public d'adultes",
    "Intervenir auprès d'un public d'enfants",
    "Intervenir auprès de Sportifs de Haut Niveau (SHN)",
    "Surveiller un espace de baignade avec un Brevet de Surveillant de Baignade - BSB",
    "Surveiller un espace de baignade avec un Brevet National de Sécurité et de Sauvetage Aquatique - BNSSA",
    "Surveiller un espace de baignade avec un Brevet Professionnel spécialité Activités Aquatiques et de la Natation - BPJEPS AAN",
    "Vérifier le fonctionnement des équipements et dispositifs de sécurité et signaler les dysfonctionnements",
    "Intervenir sur des attractions à sensation",
    "Intervenir sur des attractions sur l'eau",
    "Intervenir sur des attractions sur rails",
    "Informer les clients sur le fonctionnement de l'établissement de jeux et surveiller les lieux (détection de risques, perturbations, ...)",
    "Vérifier le fonctionnement des équipements de jeux et signaler les dysfonctionnements",
    "Animer des jeux de cartes (Black-jack, Punto banco, baccara, poker, ...)",
    "Animer des jeux de dés (craps)",
    "Animer des jeux de roue (boule, roulette française, anglaise, américaine, ...)",
    "Animer des salles de machines à sous",
    "Recueillir et analyser les informations sur les prestations des partenaires (hôteliers, autocaristes, compagnies aériennes, ...)",
    "Déterminer la programmation du produit touristique et établir le descriptif des sites à visiter, des formalités pratiques, des modalités de vente",
    "Concevoir un forfait touristique pour une clientèle de groupe",
    "Concevoir un forfait touristique pour une clientèle individuelle",
    "Sensibiliser les services (réception, commercial, ...) aux modalités d'optimisation des produits touristiques et leur apporter un appui technique",
    "Intervenir en hôtellerie/restauration",
    "Intervenir en location de véhicules",
    "Intervenir en transport aérien",
    "Intervenir en transport ferroviaire",
    "Intervenir en transport maritime",
    "Sélectionner les produits touristiques selon la demande du client et lui en présenter les caractéristiques (centres d'intérêt, formalités, tarifs, ...)",
    "Sélectionner la pièce (matériaux, formes, dimensions, ...) et identifier l'ordre et la répartition des mises en forme ou découpes",
    "Positionner la pièce sur le support et réaliser la mise en forme ou la coupe",
    "Réaliser des opérations de bobinage",
    "Réaliser des opérations de découpage (presse à découper, ...)",
    "Réaliser des opérations de détourage (détoureuse, ...)",
    "Réaliser des opérations d'emboutissage (presse à emboutir, ...)",
    "Réaliser des opérations de grignotage (grignoteuse, ...)",
    "Réaliser des opérations d'oxydécoupage (chalumeau, plasma, ...)",
    "Réaliser des opérations de pliage, roulage (plieuse, formeuse, ...)",
    "Réaliser des opérations de poinçonnage (poinçonneuse, ...)",
    "Réaliser des opérations de redressage",
    "Réaliser des opérations de refendage (ligne de refendage, refendeuse, ...)",
    "Réaliser des opérations de repoussage sur tour, fluotournage (tour à repousser, ...)",
    "Réaliser des opérations de sciage, tronçonnage (cisaille, scie, ...)",
    "Former, découper du bois, aggloméré",
    "Former, découper des métaux ferreux",
    "Former, découper des métaux non-ferreux",
    "Former, découper des minéraux, pierre",
    "Former, découper des plastiques, composites, élastomères",
    "Former, découper du verre",
    "Intervenir sur des matériaux en forme de barres",
    "Intervenir sur des matériaux en forme de fils",
    "Intervenir sur des matériaux en forme de plaques",
    "Intervenir sur des matériaux à forme profilés, poutres, poutrelles",
    "Intervenir sur des matériaux en forme de tôles épaisses",
    "Intervenir sur des matériaux en forme de tôles fines",
    "Intervenir sur des matériaux en forme de tubes, couronnes",
    "Intervenir sur une opération automatisée de cisaillage, découpage",
    "Intervenir sur une opération automatisée de ferrage",
    "Intervenir sur une opération automatisée de formage, poinçonnage, sertissage",
    "Intervenir sur une opération automatisée de montage, assemblage",
    "Intervenir sur une opération automatisée de soudage sous flux gazeux",
    "Intervenir sur une opération automatisée de soudage sous flux solide, laser, par faisceau d'électrons, par résistance, par ultrason, ...",
    "Intervenir sur une opération automatisée de trempage",
    "Intervenir sur une opération automatisée d'usinage, meulage",
    "Vérifier le fonctionnement de l'installation et des équipements (répartiteur, table de coulée, électrodes, réfractaires, ...)",
    "Surveiller le déroulement des phases de production de métaux",
    "Intervenir dans l'étape d'agglomération des métaux pour la transformation du métal",
    "Intervenir dans l'étape de cokerie pour la transformation du métal",
    "Intervenir dans l'étape d'électrométallurgie pour la transformation du métal",
    "Intervenir dans l'étape de fonderie (coulée sous pression, ...) pour la transformation du métal",
    "Intervenir dans l'étape du four de fusion (à induction, ...) pour la transformation du métal",
    "Intervenir dans l'étape des hauts-fourneaux pour la transformation du métal",
    "Intervenir dans l'étape d'aciérie (train continu à chaud, convertisseur) pour la transformation du métal",
    "Réaliser des opérations de moulage par gravité (sable, coquille)",
    "Réaliser des opérations de moulage sous pression",
    "Réaliser des opérations de moulage sous vide",
    "Déterminer les phases de conception ou les opérations de façonnage de l'outillage",
    "Reporter les cotes sur les matériaux, déterminer les plans de joint, les lignes de dépouille et effectuer les tracés",
    "Former les matériaux par usinage, modelage, découpage, formage, sculpture, ...",
    "Assembler et fixer les éléments selon l'ordre de montage",
    "Réaliser un premier moule ou un moule-mère à partir d'un modèle par enterrage, coffrage, coulage de plâtre, ...",
    "Procéder aux opérations de finition sur les surfaces des modèles, moules, ...",
    "Réaliser des opérations de fabrication/d'usinage sur des machines à bois traditionnelles",
    "Réaliser des opérations de fabrication/d'usinage sur des machines de prototypage rapide",
    "Intervenir sur des matériaux en bois",
    "Intervenir sur des matériaux composites",
    "Intervenir sur des matériaux en cire",
    "Vérifier, positionner et fixer les pièces et les éléments de l'assemblage (bâtis, grappes, gabarits, structures, ...)",
    "Vérifier le montage/assemblage (aspect, parallélisme, jeu, maintien, fonctionnement, ...) et procéder aux ajustements, retouches",
    "Identifier les phases d'intervention à partir du dossier de production et sélectionner l'outillage adapté",
    "Réaliser les noyaux (remplissage des boîtes à noyau de sable, tassage, positionnement d'armatures, traitement de durcissage, ...)",
    "Remplir le moule de sable, déterminer la position du modèle et réaliser le moulage de l'empreinte, les conduits de coulée et d'évacuation des gaz",
    "Dégager l'empreinte, en contrôler les dimensions, l'état de surface et procéder aux retouches et finitions",
    "Déposer un enduit sur les surfaces moulantes (empreintes, noyaux, ...)",
    "Positionner et fixer les éléments et fermer le moule",
    "Préparer un moule selon un procédé de plaque-modèle",
    "Préparer un moule selon un procédé de moulage au trousseau",
    "Reporter les cotes et mesures sur les matériaux et effectuer les tracés",
    "Intervenir sur des matériaux en fonte",
    "Régler des centres d'usinage, cellules flexibles d'usinage",
    "Régler des machines de formage, machines de frappe à froid",
    "Identifier les matériaux et les différentes soudures appropriées selon les instructions, documents techniques, plans, ...",
    "Préparer les joints et positionner les pièces, plaques, tubes, profilés entre eux ou sur un support",
    "Assembler et souder différents éléments entre eux ou sur le support",
    "Évaluer les défauts et effectuer les opérations de reprise ou de finition (bord, dépôt, surface, ...)",
    "Intervenir sur des matériaux en acier et ses alliages",
    "Intervenir sur des matériaux en acier revêtu (zingué, galvanisé, plombé, aluminé, plastifié)",
    "Intervenir sur des matériaux en alliages réfractaires, superalliages",
    "Intervenir sur des matériaux en cuivre, nickel et leurs alliages",
    "Intervenir sur des matériaux en titane, magnésium",
    "Préparer les embouts et les bords à souder, à visser et les ajuster",
    "Contrôler la résistance, l'étanchéité du tronçon, de la ligne, de l'installation et réaliser les retouches, réalignements",
    "Intervenir sur des tuyauteries basse pression",
    "Intervenir sur des tuyauteries en milieu froid",
    "Intervenir sur des tuyauteries à forte épaisseur et grand diamètre de tube",
    "Intervenir sur des tuyauteries à haute pression",
    "Surveiller le déroulement des cycles de transformation du papier ou du carton",
    "Organiser ou surveiller le couchage lors d'une ou plusieurs phases de fabrication",
    "Organiser ou surveiller l'égouttage lors d'une ou plusieurs phases de fabrication",
    "Organiser ou surveiller l'enroulage lors d'une ou plusieurs phases de fabrication",
    "Organiser ou surveiller le lissage lors d'une ou plusieurs phases de fabrication",
    "Organiser ou surveiller le séchage lors d'une ou plusieurs phases de fabrication",
    "Sélectionner des éléments (mandrins, embouts, ...) et effectuer des opérations de contrôle d'enroulage, de déroulage",
    "Sélectionner des éléments (mandrins, embouts, ...) et effectuer des opérations de découpe de bobines au format",
    "Sélectionner des éléments (mandrins, embouts, ...) et effectuer des opérations de référencement de bobines",
    "Réaliser sur les installations le changement d'habillage (feutres, toiles, câbles d'embarquement, ...)",
    "Surveiller le déroulement des phases de fabrication de la pâte ou suspension fibreuse",
    "Vérifier la circulation des matières et produits (eau, pâte, ...) dans les machines, installations ou zones de stockage",
    "Vérifier la conformité de la pâte (pH, blancheur, consistance, ...) ou de la suspension fibreuse, ajuster les paramètres et les dosages de produits chimiques, adjuvants",
    "Fabriquer ou réparer une bobine électrique pour intégration dans une machine tournante (moteur, alternateur, ...)",
    "Sélectionner les câbles et les disjoncteurs, contacteurs, fusibles, ... à partir du schéma électrique, du plan de câblage ou de la puissance des appareils",
    "Vérifier la continuité électrique du câblage et procéder aux réglages préalables à la mise sous tension",
    "Intervenir sur un procédé de fabrication à l'unité (prototypes, ...)",
    "Intervenir sur un procédé de fabrication en grandes séries",
    "Intervenir sur un procédé de fabrication en moyennes séries",
    "Intervenir sur un procédé de fabrication en petites séries",
    "Sélectionner ou vérifier les recettes et programmes de fabrication, selon les caractéristiques du produit (cartes, puces, circuits imprimés, ...)",
    "Détecter les dysfonctionnements relatifs aux produits, outils de production et informer le service maintenance",
    "Réaliser des interventions nécessitant une habilitation aux risques chimiques",
    "Réaliser la maintenance d'un équipement de production au premier niveau (nettoyage graissage, ...)",
    "Réaliser la maintenance préventive/curative au deuxième niveau",
    "Réaliser des opérations de traitement par photomasquage",
    "Réaliser des opérations de traitement par photogravure",
    "Réaliser des opérations d'implantation ionique",
    "Réaliser des opérations de traitement thermique",
    "Repérer et positionner les éléments électriques, composants électroniques dans l'ordre d'assemblage ou alimenter la machine",
    "Assembler les éléments du produit électrique ou électronique (composants, câblage)",
    "Trier les produits défectueux et les diriger vers les zones ou services concernés (réparation, recyclage, évacuation, ...)",
    "Identifier l'implantation des composants (résistances, condensateurs, circuits intégrés, ...) à partir du schéma électronique ou du plan de câblage",
    "Vérifier les composants et les disposer en fonction du déroulement des opérations de montage",
    "Adapter les composants aux caractéristiques du support",
    "Positionner et fixer les composants sur les supports",
    "Vérifier le montage et le câblage (valeur des composants, continuité électrique, ...)",
    "Réaliser des opérations de traitement par moulage",
    "Réaliser des opérations de traitement par nettoyage (cartes, ...)",
    "Réaliser des opérations de traitement par tropicalisation",
    "Vérifier l'état des équipements, de l'installation (bonbonnes d'air, vannes d'arrivée, ...) et sécuriser l'ensemble",
    "Réaliser ou adapter les réglages des brûleurs, turbines, alternateurs à partir de commande centralisée",
    "Détecter les dysfonctionnements des installations, déterminer les causes de panne et établir les demandes d'intervention de maintenance",
    "Intervenir sur des chaudières gaz",
    "Intervenir sur des chaudières fioul",
    "Intervenir sur des blocs nucléaires",
    "Intervenir sur des chaudières de cogénération, régénération",
    "Surveiller et réguler des équipements de production d'énergie nécessitant une habilitation pour la conduite de centrale nucléaire (SN2, SN3, ...)",
    "Surveiller et réguler des équipements de radioprotection",
    "Vérifier le fonctionnement des installations et des équipements (arche de recuisson, presse, feeders, étireuse, ...)",
    "Intervenir dans l'étape de bobinage (fibre de verre, ...) du processus de transformation de verre (à chaud, à froid)",
    "Intervenir dans l'étape de centrifugation (fibre isolation, ...) du processus de transformation de verre (à chaud, à froid)",
    "Intervenir dans l'étape de composition du processus de transformation de verre (à chaud, à froid)",
    "Intervenir dans l'étape d'emboutissage, pressage à chaud du processus de transformation de verre (à chaud, à froid)",
    "Intervenir dans l'étape d'étirage (verre plat, fibre de verre, ...) du processus de transformation de verre (à chaud, à froid)",
    "Intervenir dans l'étape d'extrusion du processus de transformation de verre (à chaud, à froid)",
    "Intervenir dans l'étape de fusion du processus de transformation de verre (à chaud, à froid)",
    "Intervenir dans l'étape de laminage du processus de transformation de verre (à chaud, à froid)",
    "Intervenir dans l'étape de moulage du processus de transformation de verre (à chaud, à froid)",
    "Intervenir dans l'étape de préparation et de frittage du processus de transformation de verre (à chaud, à froid)",
    "Intervenir dans l'étape de recuisson et refroidissement du processus de transformation de verre (à chaud, à froid)",
    "Intervenir dans l'étape de soufflage du processus de transformation de verre (à chaud, à froid)",
    "Parachever le pliage",
    "Parachever la coupe (trous, encoches, découpes)",
    "Parachever l'ébavurage",
    "Parachever le dépolissage",
    "Parachever le polissage, lustrage",
    "Parachever le sablage",
    "Parachever le perçage, montage et l'assemblage d'éléments",
    "Retirer les produits endommagés et leurs débris de la chaîne de production, évacuer les rebuts et les diriger vers les zones de recyclage, ...",
    "Réaliser un premier diagnostic de dysfonctionnement et appliquer les mesures correctives",
    "Surveiller ou réguler une installation de broyage, concassage, tamisage",
    "Surveiller ou réguler une installation de conditionnement, palettisation, étiquetage",
    "Surveiller ou réguler une installation d'extrusion",
    "Surveiller ou réguler une installation de malaxage",
    "Surveiller ou réguler une installation de remplissage",
    "Surveiller ou réguler une installation thermique (four, étuveuse, ...)",
    "Vérifier l'état des pâtes céramiques ou des matériaux (plasticité, liquidité, ...)",
    "Façonner des produits selon des techniques de calibrage automatique",
    "Façonner des produits selon des techniques de calibrage manuel",
    "Façonner des produits selon des techniques de coulage",
    "Façonner des produits selon des techniques d'extrusion",
    "Façonner des produits selon des techniques de moulage",
    "Façonner des produits selon des techniques de pressage",
    "Émailler des produits par trempage",
    "Émailler des produits par projection au pistolet",
    "Émailler des produits par robot d'émaillage",
    "Parachever le garnissage",
    "Parachever la découpe",
    "Parachever le perçage",
    "Parachever le brossage",
    "Parachever la retouche d'émail",
    "Vérifier l'état de l'installation de fusion/transformation de matières",
    "Définir la composition et la quantité de mélange de silice, calcin, oxydes de fer nécessaire aux approvisionnements et aux changements de gammes",
    "Surveiller le déroulement des phases de fusion/transformation du verre et contrôler la température, le débit, ...",
    "Intervenir sur un processus d'affinage",
    "Intervenir sur un processus d'argenture",
    "Intervenir sur un processus d'assemblage de feuilles de verre (vitrages isolants, feuilletés)",
    "Intervenir sur un processus d'étirage ou définir les données de programmation",
    "Intervenir sur un processus de formage par flottage (float)",
    "Intervenir sur un processus de fusion",
    "Intervenir sur un processus de laminage (verre armé, imprimé, ...) ou définir les données de programmation",
    "Intervenir sur un processus de recuisson, de refroidissement ou définir les données de programmation",
    "Intervenir sur un processus de teinture dans la masse",
    "Intervenir sur un processus de traitement de surface",
    "Intervenir sur un processus de trempe (verre trempé, ...)",
    "Réaliser la coulée des fours",
    "Réaliser l'attrempage et le détrempage des fours",
    "Réaliser le rinçage de fours (dépose d'embouts, ...)",
    "Nettoyer un brûleur",
    "Réaliser le redémarrage de four (brûleur, ...) et de dépollueurs",
    "Identifier les phases d'ajustage et de montage d'éléments, systèmes, ensembles mécaniques à partir du dossier technique ou du modèle",
    "Dimensionner et mettre en forme les pièces, éléments mécaniques par usinage, formage, ... et vérifier leur conformité",
    "Réaliser de la maille ou de la dentelle au mètre (fils fins et continus) sur métier Jacquardtronic",
    "Réaliser de la maille ou de la dentelle au mètre (fils fins et continus) sur métier Rachel",
    "Réaliser de la maille ou de la dentelle au mètre (fils fins et continus) sur métier fuseau circulaire",
    "Réaliser de la maille ou de la dentelle au mètre (fils fins et continus) sur métier Leavers",
    "Réaliser de la maille au mètre, en panneau ou en forme (pull-over, ...) sur métier maille circulaire, métier à tricoter rayeur",
    "Réaliser de la maille au mètre, en panneau ou en forme (pull-over, ...) sur métier maille rectiligne, métier à tricoter en forme",
    "Broder des étoffes, des articles sur un métier à broder classique",
    "Broder des étoffes, des articles sur un métier à broder par pilotage informatique",
    "Broder des étoffes, des articles sur une machine à broder multi-têtes industrielle",
    "Réaliser des étoffes tressées (passementeries, ...) sur tresseuse à aiguilles",
    "Réaliser des étoffes tressées (passementeries, ...) sur tresseuse à cylindre",
    "Réaliser des pièces sur métier à tufter",
    "Réaliser des pièces sur machine à raser",
    "Passer les fils, mèches ou rubans dans des grilles, purgeurs, guide-fil, ..., contrôler leur guidage et leur enroulement sur les bobines",
    "Surveiller l'alimentation continue des installations en fils, ... et procéder au nouage en cas de rupture",
    "Surveiller des phases de préparation de cardage",
    "Surveiller des phases de préparation de craquage",
    "Surveiller des phases de préparation d'épluchage et ouverture",
    "Surveiller des phases de préparation d'étirage",
    "Surveiller des phases de préparation de passage sur banc à brocher",
    "Surveiller des phases de peignage",
    "Surveiller des équipements ou phases de bobinage, épuration",
    "Surveiller des équipements ou phases de continu à filer",
    "Surveiller des équipements ou phases de guipage",
    "Surveiller des équipements ou phases de moulinage, retordage",
    "Surveiller des équipements ou phases de transformation d'open end",
    "Surveiller des équipements ou phases de texturation",
    "Monter les mandrins, bobines et équipements (planches à aiguilleté, chaîne de picot, filière, ...) sur la machine/ligne",
    "Surveiller les données de mesure du produit, identifier les anomalies (baisse de température, arrêt de la pompe, ...) et appliquer les mesures correctives",
    "Surveiller et conduire une machine ou une ligne par voie fondue",
    "Surveiller et conduire une machine ou une ligne par procédé air laid",
    "Surveiller et conduire une machine ou une ligne par voie humide",
    "Surveiller et conduire une machine ou une ligne par voie sèche (cardage ou nappage pneumatique)",
    "Surveiller et conduire une machine ou une ligne de consolidation de nappe de non-tissé par procédé chimique (imprégnation, rouleaux chauffés, ...)",
    "Surveiller et conduire une machine ou une ligne de consolidation de nappe de non-tissé par procédé mécanique (aiguilletage, couturage-tricotage, hydrauliage, ...)",
    "Surveiller et conduire une machine ou une ligne de consolidation de nappe de non-tissé par procédé thermique (calandrage, thermofusion par air chaud, ...)",
    "Positionner et fixer les roules sur la machine, centrer le tissu et ajuster la tension lors de l'entraînement",
    "Surveiller le déroulement des cycles de traitement, vérifier la vitesse, la montée du colorant, ...",
    "Sélectionner un traitement pour des fibres d'origine naturelle animale (soie, laine, ...)",
    "Sélectionner un traitement pour des fibres d'origine naturelle végétale (coton, lin)",
    "Sélectionner un traitement pour des fibres d'origine organique (polyamide, polypropylène, polyester, aramide, cellulosique, ...)",
    "Sélectionner un traitement pour des fibres inorganiques ou minérales (carbone, verre, ...)",
    "Sélectionner un traitement pour du non-tissé",
    "Sélectionner un traitement pour du tissu chaîne et trame",
    "Sélectionner un traitement pour du tissu maille",
    "Réaliser des opérations d'ennoblissement sur autoclave",
    "Réaliser des opérations d'ennoblissement sur barque (à tourniquet, ...)",
    "Réaliser des opérations d'ennoblissement sur calandre",
    "Réaliser des opérations d'ennoblissement sur décatisseuse",
    "Réaliser des opérations d'ennoblissement sur flambeuse",
    "Réaliser des opérations d'ennoblissement sur gratteuse/ émeriseuse/ dérompeuse/ sanforiseur",
    "Réaliser des opérations d'ennoblissement sur laveuse",
    "Réaliser des opérations d'ennoblissement sur machine d'enduction / d'imprégnation",
    "Réaliser des opérations d'ennoblissement sur machine de blanchiment",
    "Réaliser des opérations d'ennoblissement sur machine de teinture pour écheveaux ou bobines (à clarinette, à tubes, ...)",
    "Réaliser des opérations d'ennoblissement sur machine jigger",
    "Réaliser des opérations d'ennoblissement sur machine owerflow, type jet",
    "Réaliser des opérations d'ennoblissement sur machines de contrecollage/complexage",
    "Réaliser des opérations d'ennoblissement sur rame d'apprêt",
    "Réaliser des opérations d'ennoblissement sur solvanteuse",
    "Réaliser des opérations d'ennoblissement sur tondeuse",
    "Regrouper les peaux selon leur origine, taille, épaisseur, en vue de leur traitement",
    "Surveiller le déroulement des différents cycles de traitement des peaux",
    "Ajuster l'épaisseur des peaux, des cuirs en fonction de l'utilisation finale de l'article",
    "Contrôler la conformité des peaux/cuirs et rectifier les anomalies",
    "Réaliser des opérations de transformation sur des peaux d'animaux à fourrure (renard, astrakans, ...)",
    "Réaliser des opérations de transformation sur des peaux d'autruche",
    "Réaliser des opérations de transformation sur des peaux de bovins",
    "Réaliser des opérations de transformation sur des peaux de crocodiles, reptile",
    "Réaliser des opérations de transformation sur des peaux d'ovins",
    "Réaliser des opérations de transformation sur des peaux de poissons",
    "Réaliser des opérations de dérayage sur des peaux et des cuirs",
    "Réaliser des opérations de grainage sur des peaux, des cuirs",
    "Réaliser des opérations de lissage sur des peaux, des cuirs",
    "Réaliser des opérations de lustrage sur des peaux, des cuirs",
    "Réaliser des opérations de palissonnage sur des peaux, des cuirs",
    "Réaliser des opérations de ponçage / veloutage sur des peaux, des cuirs",
    "Réaliser des opérations de satinage sur des peaux, des cuirs",
    "Sélectionner les outillages (racles, rouleaux, ...) et les cadres d'impression et déterminer les besoins en produits (couleurs, ...)",
    "Monter /démonter les cylindres, cadres, ... sur la machine à imprimer, cadrer le motif et alimenter la machine en couleurs",
    "Surveiller les données de mesure de la machine au cours de l'impression",
    "Vérifier l'enroulement du tissu sur le cylindre/rouleau et ajuster selon besoin",
    "Imprimer des motifs sur des fibres d'origine animale (soie, laine, ...)",
    "Imprimer des motifs de fibres d'origine organique (polyamide, polyester, cellulosique, ...)",
    "Imprimer des motifs sur des fibres d'origine végétale (coton, lin)",
    "Imprimer des motifs sur des fibres inorganiques ou minérales (carbone, verre, ...)",
    "Imprimer des motifs sur du non-tissé",
    "Imprimer des motifs sur du tissu chaîne et trame",
    "Imprimer des motifs sur du tissu maille",
    "Imprimer des motifs par impression au cadre plat",
    "Imprimer des motifs par impression au cadre rotatif",
    "Imprimer des motifs par impression au motif placé (sérigraphie, ...)",
    "Imprimer des motifs par impression numérique ou à jet d'encre",
    "Imprimer des motifs par impression par dévorage, rongeage",
    "Imprimer des motifs par impression par réserve",
    "Façonner le tissu, cuir sur la table de coupe et ajuster le positionnement (alignement)",
    "Regrouper les pièces coupées par taille, modèle, coloris, ... pour l'assemblage/montage des articles",
    "Réaliser des opérations d'assemblage par clouage, agrafage, couture, sur des pièces en cuir, peau",
    "Réaliser des opérations de brossage de fourrure sur des pièces en cuir, peau",
    "Réaliser des opérations de débordage des peaux sur des pièces en cuir, peau",
    "Réaliser des opérations d'étirage par mouillage/étavillonage sur des pièces en cuir, peau",
    "Réaliser des opérations de refente sur des pièces en cuir, peau",
    "Identifier les défauts des articles (vêtement, dentelle, ...) et les trier selon les non-conformités (finition, reprise, rebut)",
    "Fixer, intercaler les éléments de fermeture, de renfort, de marquage sur l'article",
    "Vérifier la conformité des finitions et procéder aux réparations de remaillage, de reprise de couture, ...",
    "Réaliser des opérations de finition sur des articles chaussants (bas, collants, ...)",
    "Réaliser des opérations de finition sur des articles de haute couture, robes de mariée",
    "Réaliser des opérations de finition sur des articles de lingerie/balnéaire/corseterie",
    "Parachever l'écaillage dentelle",
    "Parachever la coupe de fils résiduels, l'épluchage",
    "Parachever la pose d'oeillets, rivetage, agrafage",
    "Parachever le détachage",
    "Parachever le raccommodage",
    "Parachever le raccoutrage",
    "Parachever le remaillage",
    "Régler le programme de la machine à coudre et monter les outils et accessoires",
    "Détecter les difficultés et anomalies liées au patron, aux matières au cours de la réalisation du prototype et proposer des solutions techniques",
    "Réaliser des prototypes en cuir",
    "Réaliser des prototypes en dentelle",
    "Réaliser des prototypes en fourrure, peausserie",
    "Réaliser des prototypes en maille/lycra",
    "Réaliser des prototypes en tissu fin/flou (satin, soie, voile, ...)",
    "Assembler-monter des vêtements professionnels",
    "Assembler-monter des articles de haute couture/Robes de mariée",
    "Assembler-monter des chaussants (bas, collants, ...)",
    "Vérifier la conformité des contours, des courbes, de la symétrie, des codifications de mesures, ... du patron de base de l'article",
    "Identifier par marquage les pièces constitutives de l'article et reporter les indications supplémentaires (crans de montage, devant, dos, ...) sur le patron",
    "Digitaliser les points et lignes du tracé du patron de base",
    "Décliner les tailles d'un modèle de base sur un logiciel de gradation",
    "Réaliser sur informatique un schéma de placement/coupe par alignement et emboîtage des pièces du patron",
    "Réaliser un patron pour des articles de maroquinerie (petite maroquinerie, sacs, bagages, ...)",
    "Réaliser un patron pour des articles de sellerie générale (bâches, stores, tentes, ...)",
    "Réaliser un patron pour des vêtements de cérémonie",
    "Réaliser un patron pour des vêtements professionnels",
    "Réaliser un patron pour des articles en fourrure",
    "Réaliser un patron pour des articles en maille",
    "Réaliser un patron pour des articles en textile technique",
    "Contrôler la régularité de l'enroulement des fils, des mèches de fils mis sur les supports",
    "Régler la vitesse et la tension des équipements auxiliaires du métier",
    "Rattacher les fils entre eux (constitution de mèche, réalimentation, intervention en cas de casse, ...)",
    "Intervenir dans l'étape du bobinage",
    "Intervenir dans l'étape du cannetage",
    "Intervenir dans l'étape du montage de chaîne",
    "Intervenir dans l'ourdissage, le wappage, l'ensouplage",
    "Intervenir dans le pressage",
    "Intervenir dans le remontage",
    "Intervenir dans le rentrage",
    "Intervenir dans le wheelage",
    "Préparer ou monter un métier à tisser à jet d'air, jet d'eau, projectiles",
    "Préparer ou monter un métier à tisser à pinces, à lance, à navette",
    "Préparer ou monter un métier à tisser Jacquard",
    "Préparer ou monter un métier Jacquardtronic, Textronic",
    "Préparer ou monter un métier Rachel",
    "Préparer ou monter des métiers fuseau circulaire",
    "Préparer ou monter des métiers Leavers",
    "Réaliser le collage des fils",
    "Réaliser le démêlage des fils",
    "Réaliser le nouage des fils",
    "Réaliser le retordage des fils",
    "Déterminer les interventions de préparation ou de finition selon les matériaux (peaux, cuir, ...), produits (sac, chaussure, ...) et défauts repérés",
    "Préparer le cuir en vue du montage/assemblage de l'article",
    "Contrôler la conformité des réalisations, procéder aux rectifications et évacuer les non-conformes",
    "Réaliser la préparation de pièces par découpe décorative (ajour, dentelage, ...)",
    "Réaliser la préparation de pièces par gaufrage",
    "Réaliser la préparation de pièces par parage (biseau, rembord, ...)",
    "Réaliser la préparation de pièces par refente",
    "Réaliser la préparation de pièces par rembordage, rempliage",
    "Parachever la réfection de couture",
    "Parachever la teinture, coloration, fixation de couleur",
    "Réaliser des opérations de finition sur des articles de gainerie",
    "Réaliser des opérations de finition sur des articles de sellerie générale (bâches, stores, tentes, ...)",
    "Réaliser des opérations de finition sur des bagages, sacs",
    "Réaliser des opérations de finition sur des chaussures",
    "Réaliser des opérations de finition sur des gants",
    "Réaliser des opérations de finition sur des vêtements",
    "Détecter les défauts d'aspect des produits et articles et les localiser par des indicateurs (pastilles, sonnettes, ...)",
    "Trier les produits/articles par catégorie de choix, type de déclassement (rebut, destruction, ...) ou catégorie d'intervention (retouche, remise à teinte, ...)",
    "Détecter des défauts sur des articles chaussants",
    "Détecter des défauts sur des articles de confection textile",
    "Détecter des défauts sur des articles de ganterie",
    "Détecter des défauts sur des dentelles, passementeries, tulles, broderies",
    "Détecter des défauts sur des fils",
    "Détecter des défauts sur des textiles imprimés",
    "Détecter des défauts sur des tissus chaîne et trame",
    "Détecter des défauts sur des tissus maille",
    "Détecter des défauts sur des articles de maroquinerie",
    "Détecter des défauts sur des peaux, cuirs",
    "Procéder à des actions correctives ou des réparations de bobinage/débobinage",
    "Procéder à des actions correctives ou des réparations de coupes de parties défectueuses (tissu abîmé, pattes, flancs, ...)",
    "Procéder à des actions correctives ou des réparations de détachage",
    "Procéder à des actions correctives ou des réparations de pose d'éléments de renfort (collage, thermocollage, ...)",
    "Procéder à des actions correctives ou des réparations de réfection de motifs",
    "Procéder à des actions correctives ou des réparations de raccommodage",
    "Procéder à des actions correctives ou des réparations de remise à teinte (tranches d'articles de maroquinerie, ...)",
    "Suivre et faire évoluer la planification de la production en fonction des flux, délais, approvisionnement, ...",
    "Repérer les caractéristiques des éléments électriques à partir du plan, feuille de bobinage, ... ou de l'ancien bobinage",
    "Sélectionner l'outillage et les matériaux selon les caractéristiques de la bobine",
    "Préparer et réaliser le bobinage au moyen de mandrin ou de gabarit en veillant à son bon déroulement",
    "Vérifier la conformité de la bobine (inductance, continuité électrique, équilibre statique et dynamique, ...)",
    "Fabriquer ou réparer une bobine électrique pour intégration dans une machine statique (transformateur, ...)",
    "Réaliser des opérations de disposition des différents plis composant le panneau (kraft, papier mélaminé, placage, ...)",
    "Réaliser sur des panneaux de bois des opérations de pose de bande de chant",
    "Réaliser sur des panneaux de bois des opérations de sciage",
    "Réaliser sur des panneaux de bois des opérations de teinture",
    "Réaliser sur des panneaux de bois des opérations de délignage",
    "Réaliser sur des panneaux de bois des opérations de mise à dimensions (épaisseur, format, ...)",
    "Intervenir lors de la phase de finition dans l'industrie du bois",
    "Intervenir lors de la phase de montage / assemblage dans l'industrie du bois",
    "Intervenir lors de la phase de séchage en industrie du bois",
    "Intervenir lors de la phase de sciage / débit dans l'industrie du bois",
    "Intervenir lors de la phase de traitement en industrie du bois",
    "Intervenir lors de la phase d'usinage dans l'industrie du bois",
    "Sélectionner les grumes (essence, qualité, dimensions, ...) en fonction de la commande, déterminer et marquer les découpes",
    "Eliminer les corps étrangers (éléments métalliques, ...), écorcer et tronçonner les grumes (transformation en billes, billons, ...)",
    "Positionner et maintenir la matière d'oeuvre sur la machine",
    "Sélectionner les outils (lames de scie, couteaux, ...), les monter et les régler (position, tension, ...)",
    "Régler, conduire une broyeuse",
    "Régler, conduire une dérouleuse",
    "Régler, conduire une écorceuse",
    "Régler, conduire une ligne de sciage automatisée",
    "Régler, conduire une scie à canters",
    "Régler, conduire une scie de reprise",
    "Régler, conduire une scie de tête",
    "Régler, conduire une scie mobile",
    "Régler, conduire une scie tronçonneuse",
    "Régler, conduire une trancheuse",
    "Tracer, reporter les repères de coupe et d'assemblage sur les pièces",
    "Façonner des articles d'agencement de magasins, bureaux, hôtels",
    "Façonner des cuisines, salles de bains",
    "Façonner des décors de spectacle (plateau TV, théâtre, ...)",
    "Réaliser des opérations de laquage",
    "Réaliser des opérations de masquage de défauts du bois",
    "Réaliser des opérations de pose de gainage d'ameublement",
    "Réaliser des opérations d'incrustation d'éléments",
    "Réaliser des opérations de pose de marqueterie",
    "Réaliser des opérations de cirage, lustrage",
    "Réaliser des opérations de vieillissement artificiel",
    "Réaliser et/ou orner des jouets, pipes, sabots",
    "Réaliser des opérations de teinture",
    "Réaliser des opérations de patine",
    "Analyser une commande de production bois, d'ameublement (contraintes techniques, quantités, ...) et vérifier sa faisabilité",
    "Concevoir et faire évoluer les nomenclatures des composants (pièces, quincaillerie, ...) de l'ensemble (meuble, agencement, ...)",
    "Vérifier et maintenir l'état de propreté des équipements de production et de contrôle (réacteur, colonne de distillation, pH-mètre, ...)",
    "Réaliser les relevés de mesure du produit (humidité, température, ...) et détecter les anomalies liées à sa transformation",
    "Surveiller ou conduire des phases de remplissage, conditionnement",
    "Déclencher et coordonner des interventions nécessitant l'habilitation Prévention et Secours Civiques de niveau 1 (PSC1)",
    "Déclencher et coordonner des interventions nécessitant l'habilitation Sauveteur Secouriste du Travail (S.S.T)",
    "Positionner (endroit sur endroit, ...) les pièces, éléments de décoration ou de renfort en vue de l'assemblage",
    "Assembler les pièces de l'article à plat",
    "Monter les pièces de l'article (avant, arrière, flans, ...) sur forme ou en volume",
    "Détecter les causes d'anomalies de produits et transmettre au service maintenance",
    "Assembler-monter des articles de maroquinerie",
    "Assembler-monter des gants",
    "Assembler-monter des bâches",
    "Assembler-monter des voiles",
    "Assembler-monter des équipements de protection",
    "Réaliser des opérations de rembord",
    "Réaliser des opérations de pose de renforts",
    "Réaliser des opérations de piqûres décoratives",
    "Réaliser sur des chaussures des opérations d'affichage semelle",
    "Réaliser sur des chaussures des opérations de bordage",
    "Réaliser sur des chaussures des opérations de cardage, verrage",
    "Réaliser sur des chaussures des opérations de claquage",
    "Réaliser sur des chaussures des opérations de pose de talon",
    "Parachever l'astiquage de tranches, la coloration de couture",
    "Parachever le filetage",
    "Parachever le vernissage",
    "Parachever le lustrage, lissage et bichonnage",
    "Parachever la pose d'oeillets, boucles, décoration",
    "Parachever la pose de contreforts",
    "Positionner les pièces en fonction des repères pour l'assemblage/montage de l'article",
    "Monter les pièces et les sous-ensembles des articles textiles par piquage selon les fiches techniques",
    "Vérifier le tracé et la tension de piqûre, procéder aux réparations nécessaires et évacuer les articles non-conformes",
    "Réaliser des opérations de boutonnière sur des articles textiles",
    "Réaliser des opérations de fixation, pose de thermocollant, d'élément de renfort sur des articles textiles",
    "Réaliser des opérations de garnissage (plumes, ouate, ...) sur des articles textiles",
    "Réaliser des opérations de pose d'élastique sur des articles textiles",
    "Réaliser des opérations de pose de doublure sur des articles textiles",
    "Réaliser des opérations de rempliage (ourlet, couture surpiquée, ...) sur des articles textiles",
    "Assembler-monter de la dentelle",
    "Assembler-monter de la fourrure, peausserie, cuir",
    "Assembler-monter de la maille",
    "Assembler-monter des matériaux synthétiques (lycra, ...)",
    "Assembler-monter du textile technique/non tissé",
    "Assembler-monter du tissu léger (satin, soie, voile, ...)",
    "Assembler-monter du tissu lourd",
    "Assembler-monter des produits à manches (tailleur, manteau, ...)",
    "Assembler-monter des jupes/pantalons",
    "Assembler-monter des articles de lingerie/balnéaire/corseterie",
    "Assembler-monter des articles de linge de maison (draps, serviettes, couvre-lits, ... ), ameublement",
    "Assembler-monter des accessoires de l'habillement",
    "Assembler-monter des articles de jeannerie/sportswear",
    "Parachever la broderie",
    "Parachever la pose de boutons, d'étiquettes, rivets, ...",
    "Parachever la fixation de perles",
    "Parachever la reprise de couture",
    "Parachever le marquage, laçage",
    "Parachever le repassage, la mise en forme, ...",
    "Repérer les anomalies de production liées au fil (fils doubles, cassés, mailles coulées, ...) et y remédier",
    "Terminer la pièce textile obtenue et la ranger dans des bacs ou des zones de stockage",
    "Réaliser les ajustements et les finitions et assembler les éléments et les sous-ensembles mécaniques",
    "Effectuer le métrage et la mise au point de systèmes, équipements mécaniques et outils de production (moule, outillage...)",
    "Identifier les défauts, les dysfonctionnements et procéder aux modifications, réajustements",
    "Intervenir dans la fabrication d'armurerie",
    "Intervenir dans la fabrication de cellule aéronautique",
    "Intervenir en mécanique de précision",
    "Intervenir avec un moule métallique",
    "Intervenir dans la fabrication de machines spéciales",
    "Intervenir en optique de précision",
    "Intervenir avec des outils à découper, à emboutir",
    "Intervenir dans le domaine de la chaudronnerie, tôlerie et tuyauterie",
    "Intervenir dans la fabrication d'équipement et matériel militaire",
    "Réaliser une opération d'assemblage / montage / ajustage",
    "Réaliser une opération d'alésage / taraudage",
    "Réaliser une opération de détourage / limage",
    "Réaliser une opération de dressage / pliage",
    "Réaliser une opération d'électroérosion",
    "Réaliser une opération d'installation montage de panneaux, de mobilier",
    "Réaliser une opération de mortaisage",
    "Réaliser une opération de perçage / sciage",
    "Réaliser une opération de ponçage, polissage",
    "Réaliser une opération de rectification, ébavurage, rodage",
    "Réaliser une opération de rivetage / collage / sertissage",
    "Réaliser une opération de soudage, brasage",
    "Réaliser une opération de taillage d'engrenages",
    "Réaliser une opération de tournage, fraisage",
    "Réaliser une opération d'usinage d'empreintes",
    "Réaliser une opération d'usinage de grande dimension",
    "Intervenir sur des matériaux en métaux ferreux (fonte, acier, ...)",
    "Intervenir sur des matériaux en inox",
    "Intervenir sur des matériaux en aluminium et ses alliages",
    "Intervenir sur des matériaux en cuivre et ses alliages",
    "Intervenir sur des matériaux en nickel, titane",
    "Intervenir sur des matériaux en plastiques et composites",
    "Déterminer les opérations de fabrication d'ensembles chaudronnés et préparer les matériaux",
    "Tracer les développés et reporter les cotes sur les matériaux (plaques, tubes, profilés, ...)",
    "Couper les éléments et les mettre à dimensions et en forme par pliage, cintrage, oxycoupage",
    "Marquer, positionner les pièces, plaques, tubes et les assembler (soudure-pointage, rivet, agrafe, colle, ...)",
    "Contrôler les pièces, l'assemblage et réaliser les finitions (meulage, ébavurage, redressage)",
    "Réaliser des opérations de cisaillage",
    "Réaliser des opérations de découpage laser, plasma",
    "Réaliser des opérations de détourage, poinçonnage",
    "Réaliser des opérations de grignotage",
    "Réaliser des opérations d'oxycoupage",
    "Réaliser des opérations de sciage, tronçonnage",
    "Réaliser des opérations de cintrage, cambrage",
    "Réaliser des opérations de pliage",
    "Réaliser des opérations de roulage",
    "Réaliser des opérations de planage",
    "Réaliser des opérations d'emboutissage",
    "Réaliser un soudage nécessitant la qualification soudage avec électrodes enrobées SMAW (Soudage à l'arc à électrodes enrobées - 111)",
    "Réaliser un soudage nécessitant la qualification soudage avec fil fourré sans gaz Innershield (Soudage à l'arc avec fil fourré autoprotecteur - 114)",
    "Réaliser un soudage nécessitant la qualification soudage sous flux en poudre SAW (Soudage à l'arc sous flux (en poudre) avec un seul fil - 121)",
    "Réaliser un soudage nécessitant la qualification Metal Inert Gas -MIG- (Soudage à l'arc semi-automatique MIG avec fil électrode fusible - 131)",
    "Réaliser un soudage nécessitant la qualification Metal Active Gas -MAG- (Soudage à l'arc semi-automatique MAG avec fil électrode fusible - 135)",
    "Réaliser un soudage nécessitant la qualification soudage avec fil fourré sous gaz actif (Soudage à l'arc MAG avec fil fourré de flux - 136)",
    "Réaliser un soudage nécessitant la qualification soudage avec fil fourré sous gaz inerte (Soudage à l'arc MIG avec fil fourré - 137)",
    "Réaliser un soudage nécessitant la qualification soudage avec fil fourré sans laitier (Soudage à l'arc semi-automatique Metal Active Gas - MAG avec fil poudre métallique - 138)",
    "Réaliser un soudage nécessitant la qualification Tungsten Inert Gas -TIG- (Soudage à l'arc TIG avec fil d'apport - 141)",
    "Réaliser un soudage nécessitant la qualification soudage à la flamme oxyacétylénique - 312",
    "Réaliser un soudage nécessitant la qualification soudage par brasage",
    "Réaliser un soudage nécessitant la qualification soudage par soudobrasage - 98",
    "Réaliser un soudage nécessitant la qualification soudage plasma, micro plasma - 16",
    "Réaliser un soudage nécessitant la qualification soudage par point",
    "Intervenir sur des matériaux en métaux non ferreux",
    "Intervenir sur des matériaux en titane",
    "Intervenir sur des épaisseurs de tôle : de 0,5 à 5 mm",
    "Intervenir sur des épaisseurs de tôle : de 5 à 20 mm",
    "Intervenir sur des épaisseurs de tôle > à 20mm",
    "Identifier les phases d'usinage et les cotes de réglages (tolérances, positions, états de surfaces, ...) de la pièce et des outils",
    "Monter, démonter les pièces, outillages de machines",
    "Réaliser une opération d'affûtage",
    "Réaliser une opération d'ajustage",
    "Réaliser une opération d'alésage",
    "Réaliser une opération de brochage",
    "Réaliser une opération de décolletage",
    "Réaliser une opération d'étincelage (électroérosion)",
    "Réaliser une opération de fraisage",
    "Réaliser une opération de rabotage",
    "Réaliser une opération de rectification, rodage",
    "Réaliser une opération de taillage",
    "Réaliser une opération de tournage",
    "Intervenir sur des matériaux en céramique, minéraux",
    "Démarrer, en cadence l'équipement (pilon, presse, filière, laminoir, ...) et alimenter ou positionner la pièce",
    "Surveiller le déroulement des opérations de déformation des métaux, identifier les dysfonctionnements et appliquer les mesures correctives",
    "Réaliser une opération de bobinage",
    "Réaliser une opération de coupage/cisaillage (cisaille, ...)",
    "Réaliser une opération d'estampage/extrusion (presse, ...)",
    "Réaliser une opération d'étirage/repoussage",
    "Réaliser une opération de forgeage (pilon hydraulique, pneumatique, martinet, ...)",
    "Réaliser une opération de laminage à chaud (laminoir, train de laminage)",
    "Réaliser une opération de laminage à froid (laminoir, train de laminage)",
    "Réaliser une opération de laminage circulaire (lamineuse circulaire)",
    "Réaliser une opération de matage",
    "Réaliser une opération de tréfilage (tréfileuse, ligne de tréfilage, ...)",
    "Réaliser une opération de tubage",
    "Former de l'acier, acier inox",
    "Former de l'aluminium et ses alliages",
    "Former du cuivre et ses alliages",
    "Former du titane et ses alliages",
    "Superviser et réaliser des travaux de recherche et présenter les résultats",
    "Superviser un service d'assistance en ligne",
    "Surveiller des évolutions technologiques des systèmes d'information et de télécommunications et proposer des solutions techniques",
    "Surveiller les actes de la vie quotidienne (lever/coucher, toilette, ...) d'enfants en séjour avec hébergement et les aider si nécessaire",
    "Réaliser la mise à jour du site à l'aide d'une interface d'administration",
    "Contrôler la réalisation de pages Web",
    "Réaliser des études d'organisation et d'évolution de systèmes d'information et assister la maîtrise d'ouvrage",
    "Réaliser les opérations d'ouverture et d'aménagement de sentiers pour le public",
    "Réaliser la restauration écologique de milieux naturels (lande, marais, mare, ...)",
    "Réaliser la pose et l'entretien de filets, murets de soutènement pour la stabilisation des dunes",
    "Sécuriser le public et alerter les services compétents de gendarmerie, pompiers, ... lors d'incidents ou verbaliser les contrevenants",
    "Sensibiliser tous les publics à la protection et la sauvegarde du patrimoine naturel",
    "Encadrer ou guider des groupes de scientifiques et de visiteurs, sur des parcs naturels, forêts, ...",
    "Identifier des manques de soin ou des maltraitances sur des animaux et transmettre aux services concernés",
    "Évaluer l'impact de projets d'aménagement (parc éolien, voie ferroviaire, ...) sur la faune et la flore et préconiser les solutions adaptées à la préservation de la biodiversité",
    "Réaliser des opérations de vinification (assemblage, mesure du taux d'alcool, filtration, ...)",
    "Transmettre les autorisations de navigation, les informations sur les situations anormales aux autorités portuaires et aux autres navires",
    "Renseigner les documents de bord",
    "Procéder aux soins d'entretien ou d'esthétique de chevaux, taureaux, ...(curage des sabots, tressage, tonte, ...)",
    "Inculquer à un animal des comportements adaptés à différentes situations (secourisme, aide aux personnes non voyantes, ...)",
    "Peser un animal, lui administrer des produits selon une procédure expérimentale et un protocole d'étude",
    "Effectuer ou planifier des vaccinations, castrations sur des lapins/volailles",
    "Déplacer un troupeau vers les alpages",
    "Réaliser des tests de contrôle (chimiques, sensoriels) de maturité, d'évolution de produits",
    "Réaliser des tests de contrôle chimiques au cours du processus de fermentation et émettre des préconisations (ajouts d'ingrédients)",
    "Surveiller la route d'un bateau en fonction de la direction, la vitesse, ... et informer le maître d'équipage ou l'officier des écarts",
    "Ajuster les paramètres de navigation d'un bateau en fonction des écarts et risques potentiels",
    "Préparer et distribuer les rations journalières aux animaux, pailler les litières, surveiller les femelles en gestation, ...",
    "Surveiller l'état clinique de l'animal, effectuer le suivi post-opératoire et intervenir selon nécessité",
    "Préparer les accessoires de radiologie et procéder au développement des clichés",
    "Renseigner le dossier de soins de l'animal et procéder à la délivrance de produits vétérinaires",
    "Réaliser la gestion administrative d'une structure",
    "Réaliser des ferrures à partir de barres de métal (lopin)",
    "Transmettre les techniques du métier ou mener des actions de sensibilisation au métier",
    "Dispenser des soins d'esthétique à un animal",
    "Procéder à l'anesthésie, l'euthanasie d'un animal accidenté, dangereux présentant des risques sanitaires",
    "Poser et fixer des éléments céramiques (panneaux muraux, poêle en faïence, ...)",
    "Enquêter auprès de la cible et analyser l'impact du projet réalisé sur les ventes",
    "Préparer des couleurs (nuancier, ...) et effectuer la teinture des matériaux (osier, paille, rotin, ...)",
    "Expertiser les instruments de musique (identification, estimation de valeur, ...)",
    "Réaliser des opérations de traitement thermique (recuit, trempe, revenu)",
    "Réaliser des expertises (judiciaires, achat, vente, ...) de pierres, bijoux ou pièces d'orfèvrerie",
    "Authentifier ou réaliser des expertises (judiciaires, achat, vente) de pièces d'horlogerie",
    "Restaurer des animaux naturalisés (teinte, pelage, reconstitution, ...)",
    "Réaliser un montage, un décor représentant un animal dans son environnement naturel",
    "Préparer des couleurs (nuancier, ...) et teindre des matériaux (paille, tissus, cuir, ...)",
    "Recouvrir de cuir ou d'autres matériaux souples des objets ou supports rigides",
    "Procéder à l'essayage et aux ajustements nécessaires selon différents critères (esthétique, confort, dynamique, ...)",
    "Réaliser des opérations de valorisation d'un article (pose d'accessoires, teinture ...)",
    "Actualiser des bases de données",
    "Rechercher les causes/origines d'accidents ou de sinistres par des enquêtes sur le terrain, auprès des parties concernées",
    "Conseiller les assurés en matière de prévention, de sécurité et de facteurs de risque",
    "Gérer des portefeuilles d'actifs financiers",
    "Suivre et mettre à jour des systèmes d'information (équipements, compteurs, ...) liés aux interventions sur le site",
    "Sensibiliser les gardiens, agents d'entretien, agents d'immeuble aux consignes de sécurité, d'entretien, de collecte des déchets",
    "Expertiser des biens immobiliers et déterminer une valeur vénale, locative",
    "Vider et nettoyer des bêtes",
    "Réaliser la conservation de la pâte et le conditionnement des produits de boulangerie et de viennoiserie (barquettes, sachets, ...)",
    "Préparer les fruits secs ou frais (découpe, épluchage, rinçage, ...)",
    "Déterminer l'authenticité et dater des oeuvres d'art à la demande de clients ou des autorités judiciaires et administratives (cour de cassation, douanes)",
    "Préparer et appliquer des produits colorants, décolorants (mèches, ...)",
    "Présenter et conseiller à un client des prestations, des produits et accessoires capillaires",
    "Réaliser des séances de formation sur modèles",
    "Appliquer les produits de soin sur le visage ou le corps",
    "Préparer les produits d'enveloppement (algues, boues, bandes, ...) et les accessoires (couvertures, spatules, ...)",
    "Préparer les appareils, bains, douches, cabines, sauna, hammam",
    "Organiser et planifier l'activité d'un parc de véhicules (affectation, suivi maintenance, ...)",
    "Réaliser sur des articles (tiges, poignées, semelles, ...) des opérations de montage",
    "Réaliser des articles en cuir à la demande du client",
    "Installer le client et surveiller la conformité de déroulement de soins machines, bains, spa, séances d'UV, de sauna, de hammam",
    "Dispenser des soins esthétiques à des personnes fragilisées, souffrantes ou en réinsertion sociale",
    "Vendre ou utiliser des produits phytosanitaires",
    "Déterminer les rations alimentaires des animaux et procéder aux soins de toilettage",
    "Délivrer des bulletins de jeux (grattage, pronostic, ...), contrôler les tickets (perdants / gagnants) et effectuer le paiement des gains",
    "Réaliser des plans de cuisines, de salles de bains, ... et établir les devis de réalisation",
    "Maquiller des clients pour des essais, des lancements de nouveaux produits",
    "Réceptionner les appels en centre de régulation de secours médical, évaluer le degré d'urgence et organiser les modalités d'intervention (envoi de médecin, hospitalisation, rapatriement, ...)",
    "Réaliser le service de carburant à la pompe",
    "Vérifier les conditions de livraison du carburant (niveau de cuve, transvasement, ...)",
    "Vérifier la température des réfrigérateurs, des chambres froides en réserve et en rayon",
    "Corriger l'épreuve avant l'impression de l'oeuvre",
    "Superviser la conception et la réalisation de sites web, d'animation, de jeux vidéo, ...",
    "Réaliser des supports de présentation d'ouvrages (couverture, plaquette, affiche, pages web)",
    "Déterminer des solutions techniques, des modalités de fabrication et sélectionner des prestataires, des imprimeurs",
    "Définir et mettre en oeuvre la politique et les actions de promotion et de diffusion des ouvrages",
    "Diriger une ou plusieurs collections (romans, ouvrages scientifiques, ...)",
    "Définir la politique éditoriale (collections, projets d'éditions, programme éditorial) et sa mise en oeuvre",
    "Traduire ou interpréter d'une langue à une autre des informations, des propos, des écrits",
    "Animer des débats, des évènements institutionnels sur un thème",
    "Procéder à une relecture, révision ou correction de copies",
    "Suivre et contrôler la fabrication d'un journal (signature bons à tirer, délais, réalisation, ...) jusqu'à sa diffusion",
    "Diriger une rédaction",
    "Contrôler les travaux de traduction et de terminologie (qualité, délai, relecture, correction)",
    "Suivre et développer un réseau de spécialistes ou d'organismes spécialisés en traduction et interprétariat",
    "Diagnostiquer des dysfonctionnements et des pannes de matériel et d'accessoires de prise de vues",
    "Diagnostiquer une panne ou un dysfonctionnement d'équipements et le présenter aux services maintenance, dépanneur, ...",
    "Réaliser des prises de vues photographiques",
    "Réaliser des photos d'identité",
    "Transférer un fichier informatique de film, bandes-annonces, publicités vers un serveur et intégrer les données de déclenchement de fonctions automatiques",
    "Sélectionner un programme sur un serveur informatique et lancer la projection numérique",
    "Réaliser les fiches techniques pour chaque plat du restaurant (ingrédients, quantité, prix, ...)",
    "Réaliser des tests de contrôle de fraîcheur des aliments, des préparations et prélever des échantillons pour le service d'hygiène",
    "Préparer des plateaux de fruits de mer",
    "Enregistrer des réservations de chambres, de suites et actualiser le planning d'occupation",
    "Réaliser des courses à la demande d'un client ou pour les besoins de l'établissement",
    "Apporter les bagages, les paquets des clients dans leur chambre, leur suite, leur véhicule",
    "Organiser le stationnement des véhicules dans le parking de l'établissement et y garer les véhicules des clients",
    "Accompagner des clients à leur chambre, leur suite et leur présenter les services annexes (télévision, téléphone, mini-bar)",
    "Renseigner les clients sur les services de l'établissement et les spectacles, les restaurants, les sites touristiques",
    "Concevoir des recettes de cocktails avec ou sans alcool",
    "Réaliser des cocktails à la demande du client",
    "Conseiller les clients sur un choix de vin selon les plats choisis et effectuer le service",
    "Réaliser l'entretien des verres et des couverts (à la vapeur, au vinaigre, ...) et le rangement de la vaisselle",
    "Sélectionner et acheter des vins auprès de viticulteurs, grossistes, ...",
    "Présenter des vins lors de visites de domaines, de caves et faire procéder à des dégustations",
    "Participer à la mise en service d'équipements ou d'installations chez le client",
    "Réaliser les opérations de prétraitement ou de teinte de matières",
    "Réaliser des échantillonnages d'impression sur des matières",
    "Réaliser l'évaluation chiffrée d'une commande",
    "Conseiller les clients, les conducteurs de machine de teinture et leur apporter un appui technique",
    "Réaliser et assembler des maquettes, des prototypes, des pré-séries de produits électroniques",
    "Étudier et identifier des évolutions (fonctionnalité, rentabilité, qualité, ...) de composants, produits, équipements",
    "Étudier et identifier des évolutions de pièces, composants, sous-ensembles, ensembles, ...",
    "Suivre et mettre à jour des banques de données techniques (bibliothèques de références, ...)",
    "Réaliser une ambiance sensorielle autour d'un produit, d'une gamme de produits",
    "Mouler une toile sur mannequin à partir d'un croquis ou d'une figurine",
    "Identifier des tendances et déterminer les spécificités d'un article ou d'une collection",
    "Tester des matériaux (élasticité, retraits de tissu, réactions matière, ...)",
    "Concevoir des modes opératoires, des procédés de fabrication ou d'industrialisation",
    "Réaliser des tests et des essais (fonctionnel, analyse de signature, ...) de sous-ensembles ou de composants",
    "Réaliser la programmation de composants programmables, de cartes électroniques, logiciels, automates et vérifier les fonctionnalités et caractéristiques",
    "Proposer des évolutions et des modifications (techniques, économiques) de système, sous-ensemble ou composant",
    "Réaliser la maintenance de premier niveau d'équipements de laboratoire",
    "Concevoir ou améliorer des tests et des essais, des procédés de fabrication",
    "Réaliser des fabrications de nouveaux produits, de produits pilotes, des prototypes avant industrialisation",
    "Délivrer des autorisations d'activités et déterminer leurs modalités d'exécution (port d'équipement spécial, habilitation, permis feu, ...)",
    "Sélectionner des filières de traitement de déchets (industriels, spéciaux, ...)",
    "Réaliser des relevés, mesures, prélèvements, identifier des écarts (taux, seuil, ...) et préconiser des mesures correctives",
    "Réaliser ou participer à la réalisation d'études de risques (identification, recensement, évaluation, ...)",
    "Vérifier le fonctionnement d'installations de surveillance et de traitement de rejets (eau, fumées, ...) ou de déchets",
    "Sélectionner des filières de traitement des déchets (industriels, spéciaux, ...) et tracer leur parcours",
    "Organiser le transfert et la livraison des produits aux clients",
    "Déterminer les modalités de livraisons clients",
    "Superviser le suivi et la mise à jour de banques de données de références, de nomenclatures, ...",
    "Préparer des dossiers de fabrication et de conditionnement",
    "Concevoir ou adapter des outillages et les décrire sous forme de plans, schémas, cahiers des charges",
    "Procéder à la mise en service de nouveaux équipements",
    "Réaliser des prototypes ou des outillages de fabrication en préparation de la production",
    "Communiquer un diagnostic sur des risques environnementaux ou sanitaires aux élus, institutionnels, organismes et leur apporter un appui technique",
    "Concevoir un dispositif de sensibilisation à la qualité et le mettre en oeuvre",
    "Évaluer le coût des non-conformités",
    "Surveiller et évaluer la sécurité d'emploi de médicaments, de dispositifs médicaux (effets indésirables,...), transmettre les informations aux services concernés (institut de veille sanitaire,...)",
    "Réaliser des bilans périodiques des analyses réalisées",
    "Réaliser des échantillons de nouveaux produits, relever des réactions et modifier des compositions",
    "Concevoir des protocoles d'analyses",
    "Réaliser un diagnostic de pannes d'appareils et instruments de laboratoire et planifier des opérations de maintenance",
    "Configurer et paramétrer un équipement ou un système électronique",
    "Analyser les non-conformités et déterminer des mesures correctives",
    "Régler et contrôler le fonctionnement des instruments de contrôle et de mesure",
    "Usiner et mettre en forme des pièces en bois (débit, dégauchissage, moulurage, sculpture, cintrage, ...)",
    "Déterminer des caractéristiques de gabarits pour le montage de nouveaux produits",
    "Réaliser à dimension des caisses ou des emballages et y déposer/caler des objets, des marchandises",
    "Modifier un programme préétabli (codage-décodage) ou concevoir un programme de base de fabrication (conversion de plan, référentiel pièce, ...)",
    "Vérifier et remplacer des outils, des outillages sur des équipements de production",
    "Façonner des éléments en bois sur machine traditionnelle (toupie plate, volante, chantourneuse, ...) en guidant manuellement l'outil ou la pièce",
    "Régler des équipements (autoclaves, étuves, ...) de traitement du bois (séchage, préservation, ...) et surveiller leur fonctionnement",
    "Réceptionner des grumes (classement, triage, ...) et les stocker selon l'essence et l'utilisation",
    "Surveiller des machines ou équipements de première transformation du bois (scieuse, tronçonneuse, écorceuse, ...) à partir d'une salle de contrôle ou d'une cabine de pilotage",
    "Réaliser des maquettes/prototypes de nouveaux modèles en vue de la fabrication en série",
    "Réparer des éléments de mobilier (serrurerie, ...)",
    "Assembler des pièces synthétiques par soudure selon des repères ou indications",
    "Définir les données de programmation de machines à partir de fiches techniques",
    "Assister techniquement les agents de l'équipe (explication de schéma de montage, difficultés techniques)",
    "Vérifier le fonctionnement des machines au lancement d'une production et contrôler les données de mesure lors des cycles de traitement",
    "Réparer ou intervenir sur des installations, équipements (changement de galets, rouleaux étireurs, cylindres, cartes électroniques)",
    "Identifier des produits, bobines par marquage (étiquetage, ...)",
    "Réparer des nappes et des bobines sur une visiteuse",
    "Réaliser les opérations de maintenance de premier niveau d'outils (changements d'aiguilles, montages de planches à aiguilles, ...)",
    "Débarrasser les peaux des chairs, vaisseaux et impuretés de surface",
    "Prélever des échantillons (peaux, eau des bains, ...) en vue du contrôle de conformité (acidité, ...) et les analyser",
    "Sécher ou cadrer les peaux, les cuirs selon des critères d'étirement définis",
    "Déterminer l'affectation de peaux, cuirs selon l'usage, les commandes clients, la destination des produits",
    "Réaliser le matelassage d'un matériau en vue d'une coupe en nombre",
    "Réaliser la découpe manuelle de pièces pour des prototypes ou des petites séries",
    "Marquer la localisation de crans de montage sur des pièces coupées",
    "Trier des rouleaux de tissu, peaux, ... selon leurs caractéristiques (teinte, dimension, défaut, ...) et constituer des lots à couper",
    "Déterminer les étapes et les techniques de montage d'un nouveau modèle",
    "Couper les pièces d'un prototype à partir d'un patron ou les appareiller/regrouper selon la gamme de montage",
    "Concevoir ou faire évoluer la notice technique, la gamme opératoire, la fiche de nomenclature",
    "Assister techniquement des opérateurs en cours de production (explication du schéma de montage, difficultés techniques)",
    "Réaliser un patron à partir d'une toile",
    "Réaliser manuellement une gradation taille par taille par report de valeurs proportionnelles (compas, ...)",
    "Concevoir une fiche ou un dossier technique en vue de l'industrialisation d'un modèle",
    "Préparer des produits d'encollage / d'ensimage pour les fils et contrôler leur application (trempage, passage en calandre, ...)",
    "Monter les chariots, pignons, cordes de tension, barres, ressorts ... sur des métiers et régler l'alignement des fils",
    "Réaliser un échantillon témoin, identifier les écarts et ajuster les réglages (tension, positionnement des fils, ...)",
    "Évaluer la faisabilité technique et économique de la remise en conformité d'un produit",
    "Prélever des échantillons de fil, tissu, ... en vue du contrôle de conformité de couleur, texture, ...",
    "Vérifier la conformité de dispositifs de sécurité des personnes et des installations",
    "Vérifier la tenue de dossiers de fabrication et de documents de suivi",
    "Diagnostiquer des défaillances techniques (pannes, dysfonctionnements) d'outil de production",
    "Réaliser des tests de contrôle de conformité de matières, produits ou analyser des résultats",
    "Déterminer la faisabilité et les étapes d'industrialisation de nouveaux produits",
    "Fabriquer un noyau ou une carcasse de transformateur (tôles, ...)",
    "Ligaturer des têtes de bobines pour maintien de câbles (frettage)",
    "Connecter des bobines (entre elles, sur plaque à bornes) avec des fils ou des barres",
    "Réaliser un vernissage et un étuvage de bobine contre la corrosion",
    "Remonter l'ensemble d'un moteur électrique ou d'un appareil et effectuer les réglages",
    "Concevoir l'implantation de composants à l'intérieur d'un appareil, d'un équipement (armoire, tableau)",
    "Usiner des éléments (barres de cuivre, ...) nécessaires au câblage d'armoires de puissance élevée",
    "Monter des composants électroniques ou incorporer des cartes électroniques dans des appareils, équipements, ...",
    "Connecter des machines tournantes électriques",
    "Identifier des évolutions dans les modes de production, modes de contrôles et les présenter aux services méthodes, études, maintenance, ...",
    "Réaliser un contrôle final d'un appareil électrique ou électronique et procéder à sa fermeture",
    "Déplacer des produits vers la zone de stockage",
    "Positionner des cartes équipées de Composants Montés en Surface -CMS- dans un four à refusion et régler les paramètres",
    "Positionner des cartes sur une machine à vague et régler les paramètres",
    "Adapter ou modifier la partie mécanique d'un appareil",
    "Vérifier la conformité de matières premières, produits fabriqués ou de rejets et réajuster selon besoin par adjonction de substance chimique, ...",
    "Procéder à l'arrêt ou au lancement d'une ligne de production d'énergie",
    "Contrôler la conformité physico-chimique de matières ou produits",
    "Réceptionner des commandes et planifier les livraisons",
    "Installer, monter et régler des moules et autres outillages (noyaux, cercles, ciseaux, goulottes, rouleaux de lamineuse, ...) et ajuster selon nécessité",
    "Régler et mettre au point des systèmes, équipements mécaniques et outils de production (moules, outillage, ...)",
    "Réparer et maintenir des systèmes, équipements mécaniques et outils de production",
    "Réaliser des dessins, des gabarits et des cotations (dimension, géométrie, tolérance) de pièces, outils, outillages",
    "Définir des méthodes de fabrication, d'assemblage, de réglage ou de démontage d'équipements, systèmes mécaniques",
    "Calculer des développés en fonction de paramètres (matière, épaisseur, outils, rayons)",
    "Concevoir ou faire évoluer des gammes de fabrication",
    "Réaliser la mise au point, la modification d'un programme d'usinage ou réaliser un programme de base (conversion de plan, référentiel pièce, ...)",
    "Concevoir ou modifier des gammes d'usinage, de contrôle, des plans de conception",
    "Adapter des outillages de maintien, de serrage et de porte-pièces",
    "Tracer et reporter des données de fabrication sur des matériaux",
    "Réaliser la protection de pièces (traitement anticorrosion, masquage, emballage, ...)",
    "Réajuster des réglages de paramètres d'équipements de production automatisée",
    "Programmer les déplacements et les trajectoires d'installations automatisées ou robotisées",
    "Prélever des échantillons de matériau et vérifier la conformité de matières premières, aciers, rejets (fumée...)",
    "Préparer et recueillir le métal en fusion sur le plancher de coulée",
    "Réparer des fours/équipements (réfection des réfractaires, poches, ...)",
    "Démarrer une nouvelle gamme de produits et tester les réglages des équipements",
    "Appliquer des produits de protection ou de démoulage sur des parties de moule, matrice",
    "Réaliser des moules de série",
    "Réparer, modifier ou transformer des outillages non métalliques (modèles, moule, matrice, ...)",
    "Réaliser un montage/assemblage manuel de précision (petite chaudronnerie, micromécanique, optique, ...)",
    "Procéder aux réglages (paramétrage, réinitialisation, ...) d'un équipement de production",
    "Réaliser une opération manuelle d'usinage ou de finition de pièces (perçage, arasage, ébavurage, ponçage, ...)",
    "Réaliser des retouches ou des reprises de finitions de surfaces (tôle, peinture, ...)",
    "Assister techniquement un opérateur lors d'une coulée (contrôle du remplissage, de l'écoulement, du refroidissement)",
    "Régler la machine et monter des outillages (tourniquet, chaîne de fabrication)",
    "Modifier ou adapter la forme et les dimensions d'un modèle, d'une plaque modèle, d'une boîte à noyaux",
    "Réaliser un gabarit (bois, métal), une épure de forme d'élément de structure",
    "Réaliser sur site la mise en place, la pose et le montage final d'une structure réalisée (charpente, socle, ...)",
    "Réparer des avaries de coque (repère, découpe de tôle, chanfrein, encastrement, ...)",
    "Réaliser ou modifier un programme de fabrication, de déplacements, de trajectoire d'outils (codage, décodage)",
    "Surveiller l'usure et remettre en état des outillages, outils, organes, ensembles, systèmes mécaniques",
    "Réaliser le traitement thermique de soudures (acier carbone, ...)",
    "Proposer des modifications et participer à la définition de mode opératoire de soudage",
    "Déterminer le coût d'une soudure",
    "Déterminer des modifications d'installation (retraits, rajouts, remplacements) et établir ou modifier des plans (cotation, positionnement d'éléments, ...)",
    "Réaliser un gabarit d'éléments de tuyauterie (dimensionnelle, géométrique, ...)",
    "Prélever des échantillons de matières ou de produits, les transmettre pour analyse ou analyser les résultats",
    "Préparer des sauces et contrôler leur conformité (couleur, viscosité, PH, qualité du filtrage, ...)",
    "Modifier les formes d'un moule ou d'un modèle de fabrication de pièces en matériaux composites",
    "Densifier la structure d'un matériau composite par compactage de plis",
    "Réaliser la finition d'une pièce en matériaux composites",
    "Positionner et fixer des pièces en matériaux composites entre elles ou avec des accessoires",
    "Régler des machines et équipements ou ajuster les réglages (cadence, cadrage du film, configuration de la ligne, ...)",
    "Surveiller le flux et l'avancée de produits sur une ligne de production ou de convoyage et effectuer des interventions de déblocage et de retrait d'éléments",
    "Surveiller le remplissage de cuves (silos, ...) et le flux de consommation des matières, produits en phase de production",
    "Tester les préparations sur les matières/produits à traiter (tissu, bois, ...) et ajuster les dosages (ajout de fixateur, colorants, ...)",
    "Appliquer un produit de revêtement et de protection de surface (film, lubrifiant, peinture)",
    "Réaliser des travaux de finition, parachèvement ou retouche de pièces",
    "Réaliser une préparation ou une finition de surface (décapage, grenaillage, sablage, polissage, ...)",
    "Programmer ou régler des paramètres d'installations automatisées de chauffe (températures, cycles, ...)",
    "Réaliser le montage/démontage d'outils de préchauffage/chauffage (inducteurs, becs, ...)",
    "Tracer et réaliser des décorations sur un avion, un navire, ...",
    "Réaliser un contrôle technique sur un véhicule automobile particulier ou industriel",
    "Identifier des besoins (réparation, révision, utilisation, ...) et déterminer les interventions, prestations adaptées (type, durée, coût, condition, ...)",
    "Suivre et analyser des coûts d'utilisation de véhicules (gestion, optimisation, consommation, externalisation, ...)",
    "Surveiller des lieux, voiries, circulations par vidéosurveillance",
    "Réceptionner, identifier des appels téléphoniques, des alarmes, ... et déclencher des interventions de secours et d'assistance",
    "Renseigner des documents de contrôle, d'intervention",
    "Suivre et contrôler la conformité d'intervention sur un chantier (sécurité, délai, ...)",
    "Réaliser la pose et les raccords de papier peint et de moquette",
    "Réparer ou modifier des éléments maçonnés existants",
    "Réaliser la télésurveillance d'un parc d'appareils",
    "Installer et régler des éléments hydrauliques de puissance (vérins, moteurs, ...)",
    "Concevoir l'implantation de matériel de mesure et de régulation dans une installation industrielle",
    "Relever des mesures (thermiques, mécaniques, électriques, magnétiques, ...) et les consigner sur des supports de suivi",
    "Réceptionner des équipements automatisés et estimer le coût et les délais de réalisation d'une réparation",
    "Installer un système de paiement par carte ou clé sur un distributeur non équipé",
    "Ramasser les fonds d'un distributeur automatique",
    "Définir une procédure d'installation ou une configuration de système électronique en fonction de l'environnement client",
    "Adapter un équipement électronique à de nouveaux besoins",
    "Déterminer des éléments d'équipements frigorifiques ou de conditionnement d'air (puissance, implantation, ...)",
    "Façonner et mettre en place des châssis supports d'éléments (groupe, condenseurs, échangeurs, ...)",
    "Surveiller des installations en fonctionnement (tournées, rondes, ...)",
    "Réaliser le soudage (à l'arc, brasage, ...) de pièces, organes mécaniques, ...",
    "Dimensionner et mettre en forme des pièces, éléments, ensembles mécaniques et les ajuster sur un équipement",
    "Réaliser un câblage réseau de matériels informatiques ou bureautiques",
    "Réaliser des opérations de « Travail Aérien » nécessitant une qualification largage de parachutistes",
    "Accueillir les passagers et les informer sur les conditions d'enregistrement, d'embarquement/débarquement et de vol",
    "Collecter les informations concernant les vols et effectuer les opérations d'ouverture d'enregistrement, d'embarquement",
    "Vérifier et enregistrer les documents des passagers et les bagages (référencement, surpoids, ...)",
    "Réaliser les opérations d'embarquement/débarquement (réservation de bus sur piste, accompagnement des passagers, remise des coupons de vol, ...)",
    "Vérifier les cartes d'embarquement avec la liste des passagers présents à bord, renseigner et transmettre les documents de suivi de vol",
    "Recueillir les données techniques de l'espace aérien et évaluer les écarts avec les paramètres transmis par les pilotes",
    "Contrôler les indicateurs techniques et communiquer aux pilotes les instructions de positionnement et le guidage aux différentes phases de vol",
    "Repérer le positionnement des aéronefs sur les appareils de contrôle et surveiller la conformité de leur trajectoire dans l'espace aérien",
    "Réguler le trafic aérien et élaborer des modifications de plan de vol en fonction des urgences",
    "Identifier les besoins d'intervention en fonction des anomalies/accidents et mettre en place les procédures d'alerte",
    "Consigner les éléments d'activité (dysfonctionnement, infraction, incident) sur les supports techniques",
    "Contrôler le trafic aérien d'un site aéroportuaire de catégorie A - services à grande distance assurés normalement en toute circonstance",
    "Contrôler le trafic aérien d'un site aéroportuaire de catégorie B - services à moyenne distance assurés normalement en toute circonstance et certains services à grande distance ne comportant pas d'étape longue au départ de ces aérodromes",
    "Contrôler le trafic aérien d'un site aéroportuaire de catégorie C - services à courte distance et services moyenne et longue distance ne comportant que des étapes courtes au départ de ces aérodromes",
    "Contrôler le trafic aérien d'un site aéroportuaire de catégorie D destiné à la formation aéronautique, aux sports aériens, au tourisme et à certains services à courte distance",
    "Contrôler le trafic aérien d'un site aéroportuaire de catégorie E destiné aux giravions et aux aéronefs à décollage vertical ou oblique",
    "Réaliser le contrôle aérien avec des aéronefs civils dans l'espace aérien militaire",
    "Réaliser le contrôle aérien avec des aéronefs militaires dans l'espace aérien civil",
    "Réaliser le contrôle aérien avec des aéronefs militaires sur porte-aéronefs sur des zones militaires",
    "Réaliser un contrôle aérien au sol / contrôle d'aérodrome",
    "Réaliser un contrôle aérien d'approche",
    "Réaliser un contrôle aérien en route",
    "Vérifier la sécurisation des aires de roulement ou de stationnement des aéronefs",
    "Préparer le matériel (balises, cales) et les équipements (rampe passagers, ...) en fonction du type de l'appareil et du chargement",
    "Réaliser le tri des bagages, du fret et des sacs postaux en soute, sur piste ou en galerie, selon leur destination",
    "Vérifier l'application des règles de circulation sur les zones de stationnement et de roulement par les intervenants de la piste et signaler tout dysfonctionnement",
    "Convoyer sur les pistes ou installer aux abords des aéronefs un camion-élévateur",
    "Convoyer sur les pistes ou installer aux abords des aéronefs un élévateur à fourche",
    "Convoyer sur les pistes ou installer aux abords des aéronefs un escabeau auto-tracté",
    "Convoyer sur les pistes ou installer aux abords des aéronefs une passerelle télescopique",
    "Convoyer sur les pistes ou installer aux abords des aéronefs une plate-forme élévatrice",
    "Convoyer sur les pistes ou installer aux abords des aéronefs un push/push back/tracteur-avion",
    "Convoyer sur les pistes ou installer aux abords des aéronefs une rampe mobile",
    "Convoyer sur les pistes ou installer aux abords des aéronefs un tapis bagages",
    "Convoyer sur les pistes ou installer aux abords des aéronefs un tracteur bagages, un chariot vrac ou un conteneur",
    "Installer un avitailleur carburant nécessitant une habilitation",
    "Installer un groupe électrogène pour le ravitaillement/vidange nécessitant une habilitation",
    "Installer une pompe vidange eaux usées, un ravitaillement eau potable nécessitant une habilitation",
    "Installer une unité air conditionné/unité de début d'air (Air Start Unit -ASU-) pour le ravitaillement/vidange nécessitant une habilitation",
    "Collecter les données techniques de vol ou d'escale et organiser la répartition du chargement de l'appareil",
    "Constituer le dossier de vol (plan de vol, de chargement, devis de masse) et le présenter au service de l'Aviation Civile, au commandant de bord",
    "Planifier les interventions des équipes de piste et la mise à disposition du matériel et des équipements pour la touchée (temps d'immobilisation au sol)",
    "Transmettre aux personnels de piste, d'équipage, d'escale, ... les informations techniques de déroulement de la touchée (chargement, embarquement/débarquement, parkings, horaires, ...)",
    "Coordonner et contrôler les opérations d'escale et mettre en oeuvre des mesures correctives en cas de retard ou d'avarie technique",
    "Renseigner les supports de suivi de vol et identifier les dysfonctionnements",
    "Organiser des opérations dans le domaine de l'armement hôtelier (catering)",
    "Organiser des opérations de coordination de piste",
    "Organiser des opérations de préparation technique des vols",
    "Planifier l'activité des équipes selon le programme de vol, les escales et mettre en place les mesures correctives en cas de retard, de surréservation, d'annulation, ...",
    "Coordonner ou superviser les interventions aux différentes étapes des vols (escale, filtrage, piste, maintenance) et contrôler l'application des consignes de sécurité",
    "Coordonner les échanges d'information entre les intervenants de l'escale (sous-traitants, personnel d'exploitation)",
    "Coordonner les activités d'un site aéroportuaire de catégorie A - services à grande distance assurés normalement en toute circonstance",
    "Coordonner les activités d'un site aéroportuaire de catégorie B - services à moyenne distance assurés normalement en toute circonstance et certains services à grande distance ne comportant pas d'étape longue au départ de ces aérodromes",
    "Coordonner les activités d'un site aéroportuaire de catégorie C - services à courte distance et services moyenne et longue distance ne comportant que des étapes courtes au départ de ces aérodromes",
    "Coordonner les activités d'un site aéroportuaire de catégorie D - formation aéronautique, sports aériens, tourisme et certains services courte distance",
    "Coordonner les activités d'un site aéroportuaire de catégorie E - destiné aux giravions et aux aéronefs à décollage vertical ou oblique",
    "Recueillir les données techniques de la traversée (horaires, distances, météo, ...) et élaborer la feuille de route",
    "Coordonner la conduite du navire durant la traversée et mettre en place les mesures correctives",
    "Convoyer un navire à moteur (plaisance ou commerce)",
    "Convoyer un navire de plaisance à voile",
    "Intervenir sur des systèmes de propulsion et de production d'énergie classiques",
    "Intervenir sur des systèmes de propulsion et de production d'énergie nucléaires",
    "Préparer et actionner les équipements du navire (ancres, câbles, cordages et gréement...) lors des manoeuvres d'appareillage ou d'accostage",
    "Manoeuvrer des équipements embarqués (grue de navire, ...), des équipements de transbordement des passagers ou de sauvetage",
    "Surveiller la route maritime et identifier les obstacles éventuels sur le trajet",
    "Réaliser des opérations de chargement/déchargement de navires et effectuer le rangement et la sécurisation de la cargaison",
    "Vérifier la conformité des équipements de sécurité (matériel de manutention, de sauvetage) et effectuer leur maintenance",
    "Réaliser une navigation maritime au cabotage",
    "Réaliser une navigation maritime de convoyage d'un navire de plaisance à moteur",
    "Réaliser le convoyage d'un navire de plaisance à voile",
    "Réaliser une navigation maritime de grande plaisance",
    "Réaliser une navigation maritime de plaisance professionnelle",
    "Réaliser une navigation maritime au long cours",
    "Naviguer sur un navire à Utilisation Collective (NUC)",
    "Naviguer sur un yacht de plaisance à moteur",
    "Naviguer sur un yacht de plaisance à voile",
    "Effectuer une navigation nécessitant le Certificat d'Initiation Nautique (CIN)",
    "Effectuer une navigation nécessitant un certificat de formation de base à la sécurité",
    "Effectuer une navigation nécessitant un brevet d'aptitude à l'exploitation des canots de secours rapides",
    "Effectuer une navigation nécessitant une attestation de formation à la sécurité des navires rouliers à passagers",
    "Effectuer une navigation nécessitant un brevet de Capitaine 200 Voile",
    "Effectuer une navigation nécessitant la Mention de Capitaine de yacht",
    "Constituer le convoi de navigation fluviale (nombre et type de barges, branchements électriques, hydrauliques et ventilation)",
    "Procéder aux manoeuvres d'amarrage/largage d'amarres, de mise en fonctionnement et de conduite du bateau en lien avec l'équipage",
    "Réaliser ou surveiller les opérations de chargement/déchargement des marchandises",
    "Contrôler l'embarquement/débarquement des passagers",
    "Effectuer une navigation fluviale sur un bateau automoteur (péniche)",
    "Effectuer une navigation fluviale sur un bateau bac",
    "Effectuer une navigation fluviale sur un bateau-promenade",
    "Effectuer une navigation fluviale sur un bateau-taxi",
    "Effectuer une navigation fluviale sur un paquebot fluvial",
    "Effectuer une navigation fluviale sur une pirogue",
    "Effectuer une navigation fluviale sur un bateau pousseur",
    "Effectuer un transport fluvial de matières dangereuses (gaz, hydrocarbures, produits chimiques, ...)",
    "Effectuer un transport fluvial de passagers lignes régulières (bacs, ...)",
    "Effectuer un transport fluvial de produits pondéreux (charbon, gravier, minerai, céréales, ...)",
    "Effectuer un transport de tourisme fluvial (avec ou sans restauration et hébergement, ...)",
    "Effectuer un transport fluvial de colis lourds (voitures, containers, ...)",
    "Effectuer une navigation nécessitant un certificat général de capacité (permis de navigation)",
    "Réaliser des déplacements ferroviaires en zone nationale",
    "Réaliser des déplacements ferroviaires en zone périmètre restreint",
    "Réaliser des déplacements ferroviaires en zone régionale",
    "Réaliser des déplacements ferroviaires en zone urbaine/interurbaine",
    "Conduire un engin ferroviaire spécialisé (locotracteur, remiseur, ...)",
    "Conduire un engin ferroviaire spécifique (Métro, Réseau Express Régional, ...)",
    "Conduire un train de marchandises",
    "Conduire un Train Grande Vitesse",
    "Conduire un train rapide, express voyageurs",
    "Informer les voyageurs et apporter assistance aux personnes à mobilité réduite",
    "Réguler la vie à bord et autour du véhicule, et intervenir en cas d'incivilité ou de conflit",
    "Vérifier la présence des matériels et équipements de bord (sécurité, alerte), contrôler leur état et signaler toute anomalie ou dégradation",
    "Vérifier la sécurisation des voies et des quais à l'arrivée/départ des trains (signalisation, montée/descente des voyageurs, ...) et lancer les ordres de départ en fonction de la planification",
    "Ajuster la signalisation et actionner les systèmes de manoeuvre d'aiguillage selon la destination des trains",
    "Déployer et mettre en place les mesures palliatives en cas de situation perturbée (information voyageurs, transport de remplacement, ...)",
    "Adapter les tracés de parcours des trains en cas de dysfonctionnements",
    "Manoeuvrer les systèmes des remontées mécaniques, surveiller le passage des cabines, perches et le flux des passagers",
    "Informer les clients sur les horaires, le plan, l'état des pistes et les assister en cas de besoin lors de l'embarquement/débarquement",
    "Organiser les mesures appropriées en cas de panne, incident, chute ou de changement météorologique",
    "Renseigner les supports d'exploitation (registre d'exploitation, d'incident, de réclamation, ...)",
    "Transporter des passagers au moyen d'un ascenseur incliné",
    "Transporter des passagers au moyen d'un funiculaire",
    "Transporter des passagers au moyen d'un télé corde",
    "Transporter des passagers au moyen d'une télébenne",
    "Transporter des passagers au moyen d'une télécabine fixe",
    "Transporter des passagers au moyen d'un téléphérique",
    "Transporter des passagers au moyen d'un télésiège débrayable",
    "Transporter des passagers au moyen d'un télésiège fixe",
    "Transporter des passagers au moyen de Téléski",
    "Transporter des passagers au moyen de tapis roulants",
    "Constituer une rame de wagons et la mettre à quai ou sur desserte",
    "Vérifier et corriger le fonctionnement des équipements de sécurité (freinage, signalisation, transmetteur, ...) et de confort (climatisation/chauffage, étiquetage, ...)",
    "Contrôler les mouvements de trains sur périmètre restreint (autorisation de départ, contrôle du «défilé», aiguillage) à partir des signaux",
    "Identifier les dysfonctionnements sur la zone d'intervention lors d'avarie, de retard, signaler les anomalies et mettre en oeuvre les mesures d'urgence",
    "Effectuer des opérations d'aiguillage manuel sur voies",
    "Vérifier le chargement, le déchargement",
    "Mener ou accompagner un projet d'intégration (implémentation) ou de développement de solutions matérielles, logicielles",
    "Définir la stratégie et l'orientation des systèmes d'information et télécoms d'une structure (évolution, organisation)",
    "Superviser et organiser les travaux d'exploitation/production informatique",
    "Raccorder un ordinateur à un équipement et diagnostiquer des pannes à partir de programmes enregistrés",
    "Optimiser des algorithmes, une application informatique et mettre en oeuvre leur développement",
    "Définir et mettre en oeuvre une gestion des risques (sécurité, confidentialité, intégrité, fiabilité) des systèmes d'information",
    "Déterminer des choix techniques d'architecture logicielle et matérielle et sélectionner des technologies, matériels, logiciels, configurations",
    "Développer une application en lien avec une base de données",
    "Programmer dans un langage informatique spécifique",
    "Piloter un projet",
    "Développer, programmer une application informatique en utilisant un IDE (Integrated Development Environment)",
    "Développer des interfaces entre des applications informatiques ou pour des robots",
    "Superviser et coordonner les réalisations, études ou développements informatiques (collaborateurs, sous-traitants)",
    "Réaliser des opérations de maintenance préventive et curative d'applications informatiques",
    "Développer, programmer une application informatique ou un site Web en utilisant un Framework",
    "Étudier la faisabilité économique et technique du produit sur le marché ciblé et déterminer le planning de lancement",
    "Préparer, suivre ou contrôler la facturation d'opérations de transit ou de douane (règlement fournisseurs, taxes douanières, Déclaration d'Echanges de Biens -DEB-, ...)",
    "Collecter les éléments d'activité du personnel",
    "Coordonner les opérations marketing d'un site Internet",
    "Organiser des tournées de visite (planning, ...)",
    "Réaliser la cotation d'un produit et déterminer le prix de vente de la prestation",
    "Réaliser les formalités administratives d'une prestation de transport (lettre de transport), LTA (aérien), BL (maritime), CMR (route), CIM (ferroviaire, ...)",
    "Mener des opérations de transport en relation avec une clientèle internationale",
    "Implanter et mettre en oeuvre un CRM (logiciel de gestion de la relation commerciale) dans une structure, une organisation",
    "Établir une réponse à un appel d'offre",
    "Rédiger et faire publier des notes de conjonctures sectorielles ou des études sur de grandes entreprises",
    "Définir la faisabilité et la rentabilité d'un projet",
    "Définir ou participer à la définition de la stratégie de développement d'un territoire",
    "Administrer un site web",
    "Prospecter de nouveaux clients",
    "Réaliser un diagnostic de panne ou de dysfonctionnement d'installation",
    "Informer sur une démarche qualité ou sécurité",
    "Tester l'étanchéité, la pente, ...d'un réseau de canalisations",
    "Découper des matériaux (bois, contreplaqué, verre, tissus, ...) selon les dimensions, formes, mesures définies par les clients",
    "Procéder aux essayages de vêtements, d'accessoires et donner un avis professionnel sur le bien-aller",
    "Traiter et prendre les commandes pour des livraisons de végétaux (compositions florales, arbustes, ...)",
    "Prendre les mesures des clients ou effectuer un marquage pour des retouches de vêtements",
    "Réaliser l'emballage de produits frais (entiers, en tranches, ...) pour le rayon libre-service ou pour des commandes (petits fours, buffet, ...)",
    "Actualiser la documentation professionnelle et réglementaire",
    "Assister un ingénieur lors de travaux de laboratoire pour la préparation de matériel, le relevé de résultat",
    "Concevoir des modèles théoriques (calcul, simulation, modélisation)",
    "Conduire un véhicule particulier vers son lieu de livraison ou d'enlèvement (convoyage inter-sites, rapatriement)",
    "Réaliser un mur en pierres",
    "Décomposer les enchaînements, les mouvements d'une chorégraphie et mener les répétitions des danseurs",
    "Diriger un service, un laboratoire de recherche",
    "Réaliser la protection (masquage, emballage, ...) et l'expédition de pièces",
    "Réaliser la gestion matérielle et technique des locaux (approvisionnement, repérage de dysfonctionnements, demande de travaux, ...)",
    "Suivre des risques financiers",
    "Concevoir ou participer à l'élaboration de programmes d'animation",
    "Établir un contrat de travail",
    "Réaliser les commandes de matériel, de fournitures, de consommables et vérifier la conformité des livraisons",
    "Réaliser des supports et des armoires électriques",
    "Injecter un matériau (mousse, isolant, résine, colle, ...) lors du montage/assemblage",
    "Présenter et expliquer des techniques d'esthétique et de maquillage (démonstration, ...) à des clients",
    "Réaliser des études d'urbanisme des systèmes d'information de l'entreprise",
    "Réaliser des spectacles avec les enfants (élaboration du contenu, création de costumes, répétitions, ...) et les présenter au public",
    "Réaliser des travaux de reprographie",
    "Réaliser les interventions de télémaintenance d'équipements d'utilisateurs",
    "Réceptionner, vérifier ou enregistrer les paquets, colis, lettres en recommandé, bordereaux d'acheminement postal",
    "Remettre les lettres recommandées, les colis suivis au destinataire après vérification de l'identité et émargement ou déposer un avis de passage",
    "Suivre et contrôler la conformité des interventions de prestataires de maintenance",
    "Suivre et gérer un parc d'équipements informatiques et organiser des salles informatiques",
    "Suivre l'évolution des matériels, analyser les incidents d'exploitation et instruire les actions correctives et préventives",
    "Intégrer des effets visuels ou sonores à des séquences vidéo",
    "Surveiller le déroulement de la production et ajuster le réglage des paramètres des équipements en fonction des écarts",
    "Réaliser des opérations sur des machines de façonnage indépendantes (massicot, plieuse, relieuse, ...)",
    "Régler des mécanismes d'alimentation de supports d'impression",
    "Concevoir des solutions techniques et organisationnelles d'amélioration de production",
    "Surveiller le fonctionnement d'une machine de façonnage préréglée, ajuster les réglages ou signaler les dysfonctionnements",
    "Réécrire du contenu ou du style de textes",
    "Réaliser ou modifier la mise en page d'un document",
    "Définir et arrêter avec un commanditaire les modalités d'une commande de préparation/correction de documents",
    "Sélectionner un programme de gravure de cylindre d'impression (héliogravure) et surveiller la réalisation",
    "Expliciter le mode d'emploi d'un matériel de reprographie à un client (structure en libre-service, ...)",
    "Vérifier des données et des étapes de production en industrie graphique (quantités, types de production, dysfonctionnements, délais, ...)",
    "Rechercher et analyser des informations sur un produit, une marque (situation, marché, publicités précédentes, ...)",
    "Établir et faire évoluer des méthodologies et outils d'étude et de mesure médias",
    "Expertiser des bâtiments à la demande de particuliers (risques d'effondrement, isolation, ...)",
    "Réaliser des relevés et reconstituer les plans d'un ouvrage existant avant modifications",
    "Concevoir un plan local d'urbanisme d'une commune",
    "Réaliser la maquette d'un projet d'aménagement au moyen de différents matériaux (polystyrène, stratifié, bois, ...)",
    "Réaliser des plans de locaux ou de lieux",
    "Concevoir et faire évoluer des prestations, des procédures techniques et opérationnelles d'intervention",
    "Consulter des fournisseurs, sous-traitants, prestataires",
    "Tracer sur site des implantations extérieures et intérieures d'ouvrages",
    "Analyser des échantillons de roches, boues et définir des compositions, des modèles géologiques",
    "Étudier des nappes souterraines et des caractéristiques hydrodynamiques (analyses, calculs de débit, risques de pollutions, qualités des eaux, approvisionnement, ...)",
    "Réaliser des études de fondations, de tassement ou de stabilité de terrains (glissement, érosion, résistance)",
    "Étudier des impacts environnementaux de sols, sous-sols (implantation d'ouvrage, réaménagement de site, pollutions, décharges, ...)",
    "Réaliser des documents d'explorations, d'exploitations de sols, sous-sols (cartes, coupes, sections sismiques, ...)",
    "Concevoir des modèles géologiques théoriques, prédictifs de sols, sous-sols (calcul, simulation, modélisation)",
    "Concevoir et diriger des programmes de reconnaissance de lieux, de prospections, de recherches (objet, moyens, planification, ...)",
    "Identifier des gisements et réservoirs (miniers, pétroliers, ...) et évaluer des potentiels d'exploitation (valeur, rentabilité, ...)",
    "Concevoir un projet de lotissement de terrains agricoles ou à bâtir",
    "Numériser des données de terrain pour un système d'information géographique",
    "Réaliser la cartographie numérique d'un plan cadastral et d'un Plan Local d'Urbanisme (PLU)",
    "Cartographier des routes, quartiers d'habitation, rivières, plans d'eau",
    "Tracer des cartes topographiques et hydrographiques de ressources naturelles, ...",
    "Estimer la valeur d'un bien foncier, d'un fermage, de produits extraits du sous-sol, ...",
    "Superviser le fonctionnement des installations de traitement et de transformation des matériaux (pilotage, réglage, ...)",
    "Actualiser les plans géologiques d'une exploitation",
    "Analyser des forations et le résultat de tirs de mines",
    "Analyser des échantillons de front de taille sous-terrain",
    "Suivre et analyser des données statistiques d'un chantier sur la sécurité, la santé, les accidents du travail, ...et définir des mesures correctives/préventives",
    "Sortir et mettre à l'eau des bateaux de faible tonnage (<10T)",
    "Démolir une construction",
    "Peigner un front de taille avec un godet",
    "Analyser des données et estimer le volume des réserves pétrolières d'un emplacement donné",
    "Poser un escalier préfabriqué en bois",
    "Poser un plancher sur solivage",
    "Réaliser les moules en plâtre, élastomère, feuilleté, ... des pièces à façonner",
    "Réaliser la pose de la partie électrique des installations photovoltaïques",
    "Installer un plancher chauffant",
    "Régler une chaudière télécommandée",
    "Installer du mobilier sanitaire",
    "Relier des appareils (sanibroyeur, chauffe-eau, sèche-mains, ...) au tableau électrique",
    "Installer un système centralisé de conditionnement d'air",
    "Nettoyer des conduits de cheminée",
    "Réaliser des chambres électriques pour un réseau souterrain",
    "Poser des aiguilles avec un appareillage pneumatique (furet) pour le passage de câbles dans les gaines",
    "Remplacer un élément vitré",
    "Appliquer de la résine de synthèse",
    "Réaliser des opérations d'installation de dallage industriel",
    "Poser un parquet",
    "Réaliser des supports spécifiques (tabliers de baignoires, tablettes, socles, murets, ...) et les carreler ou les lambrisser",
    "Réaliser une pose de mosaïque",
    "Poser un bardage en céramique",
    "Tailler, calibrer la pierre de couverture (lauze, gneiss, ...) et la poser en toiture sur voûte par calage avec du remblai",
    "Tracer des développements de versants sur schémas",
    "Déposer des toitures amiantées",
    "Poser et fixer des profilés, armatures, arrêtes de tableaux, cornières, ...",
    "Récupérer et trier des déchets de décapage",
    "Réaliser un chevêtre pour un châssis",
    "Surfacer et talocher une dalle",
    "Surveiller et ajuster le réglage d'un engin de revêtement",
    "Réaliser des travaux de déconstruction",
    "Passer des gaines de réseaux électriques dans des conduits",
    "Débiter du bois de coffrage",
    "Poser des fontes de voirie",
    "Réaliser des travaux de finition et de retouche de la pièce (réfection des angles, bouchage des trous de douille, retrait des laitances...)",
    "Réaliser des travaux de traitement des surfaces sur le moule (brossage, plissage, sablage, bouchardage, ragréage)",
    "Réaliser des armatures en acier",
    "Définir ou participer à la définition de la politique touristique d'un territoire",
    "Préparer un animal pour la randonnée et procéder à ses soins",
    "Recenser les besoins de suivi sanitaire d'enfants en séjour (traitement en cours, ...) et contrôler l'application des consignes médicales",
    "Décorer et mettre en place des espaces d'animation selon des thèmes ou des évènements (spectacle, fête annuelle, ...)",
    "Encadrer un sportif, une équipe lors d'une compétition et identifier les axes de progrès individuels ou collectifs",
    "Concevoir et mettre en place un programme de préparation physique (exercices, durée, fréquence, enchaînements, ...) pour un sportif ou une équipe",
    "Arbitrer ou juger une épreuve sportive",
    "Réaliser ou participer aux opérations de montage/démontage d'attractions",
    "Vérifier les conditions d'installation et de sécurité du public (harnachement, ...) et procéder au verrouillage des systèmes de sécurité (barres, portes, ...)",
    "Ajuster les paramètres de fonctionnement d'une attraction (vitesse, durée) et lancer l'animation (annonces, musique, jeux de lumière)",
    "Surveiller le déroulement d'une attraction et mettre en place des mesures de sécurité (alerte, arrêt, ...) lors de dysfonctionnements",
    "Animer la table de jeu (consignes aux joueurs, annonces, placement des mises)",
    "Superviser l'activité d'une ou plusieurs tables de jeux (conformité des procédures, régularité des jeux, paiement des gains, change, ...)",
    "Assister techniquement le personnel des jeux lors d'incidents dans le déroulement des jeux, litiges, réclamations de clients",
    "Organiser des séjours linguistiques",
    "Renseigner les clients sur les services de l'établissement",
    "Superviser l'élaboration des menus, des cartes et des tarifs du restaurant",
    "Renseigner les clients sur les services de la structure et effectuer la vente de billets",
    "Réaliser le service des petits-déjeuners en salle, en chambre",
    "Réaliser la maintenance de premier niveau des appareils et des équipements de l'établissement/local",
    "Réaliser la mise en place du mobilier, du matériel audio-visuel et informatique des salles de réunions, de conférences",
    "Dimensionner, planifier et suivre la capacité du réseau télécom et de ses interconnexions",
    "Analyser les conditions de fonctionnement et d'utilisation et mettre en place des solutions d'ajustements, d'évolutions ou de migrations de réseaux et de ressources",
    "Réaliser un prototype de la solution technique pour validation par le donneur d'ordres (configuration type, ...)",
    "Concevoir et développer les programmes et applications informatiques",
    "Analyser les problèmes techniques, fonctionnels et proposer des correctifs, mises en conformité techniques",
    "Intervenir en portail d'entreprise et technologie Internet",
    "Contrôler le fonctionnement des équipements, des systèmes et vecteurs d'information",
    "Surveiller la performance, la capacité, la qualité du réseau et adapter les équipements, systèmes et vecteurs d'informations",
    "Rechercher ou recueillir les données, signaux, mesures, images, transmis ou émis et les identifier",
    "Réaliser les relevés terrain et collecter des données alphanumériques et graphiques",
    "Sélectionner, traiter et réaliser l'analyse spatiale de données géographiques",
    "Identifier et interpréter les données et caractéristiques géographiques et analyser les résultats",
    "Restituer et mettre en forme les données géographiques pour l'édition cartographique",
    "Sélectionner une gamme de données géoréférencées sur requêtes selon la demande du client",
    "Formaliser des données cartographiques et les diffuser sur des réseaux d'information",
    "Ordonnancer le déroulement des travaux et mettre en oeuvre les traitements d'exploitation/production des ressources informatiques",
    "Superviser et vérifier l'état des ressources informatiques, réaliser les sauvegardes et les archivages de données",
    "Diagnostiquer la nature et l'origine des incidents et mettre en oeuvre les mesures correctives",
    "Installer et intégrer le matériel (station, équipement réseau, périphériques, ...) dans l'environnement de production et configurer les ressources logiques et physiques",
    "Surveiller le fonctionnement d'applicatifs et logiciels",
    "Surveiller le fonctionnement de bases de données",
    "Surveiller le fonctionnement de couches logiciels",
    "Surveiller le fonctionnement d'espaces disques",
    "Surveiller le fonctionnement de réseaux de Télécoms",
    "Surveiller le fonctionnement de réseaux informatiques",
    "Surveiller le fonctionnement de serveurs",
    "Surveiller le fonctionnement de systèmes",
    "Sélectionner et préparer l'engin de manutention, selon les caractéristiques des produits et les conditions de déplacement",
    "Renseigner les supports de suivi de déplacements des charges et transmettre au service concerné",
    "Vérifier l'état des charges, identifier les anomalies et les transmettre au service concerné",
    "Sélectionner et charger le matériel de déménagement en fonction de la commande",
    "Transporter des charges entre un local un véhicule",
    "Conduire un véhicule articulé",
    "Conduire un véhicule avec grue auxiliaire de chargement",
    "Conduire un véhicule porteur de plus de 3,5 t",
    "Réaliser le prélèvement de produits selon les instructions de préparation de commande et constituer les colis, lots, ...",
    "Renseigner les supports de suivi de commande et transmettre un état des produits détériorés et du matériel défectueux",
    "Réaliser les opérations de levage et de déplacement des charges selon les indications des équipes au sol",
    "Sécuriser l'engin de manutention et les équipements",
    "Lever des bateaux",
    "Lever des céréales",
    "Lever des conteneurs",
    "Lever des grumes",
    "Lever du métal en fusion",
    "Lever des matières dangereuses",
    "Manoeuvrer une écluse",
    "Manoeuvrer une grue de quai",
    "Manoeuvrer une passerelle",
    "Manoeuvrer un pont-mobile",
    "Manoeuvrer un portique",
    "Trier et répartir les colis, marchandises selon les indications (codification, format, poids, nombre, ...)",
    "Repérer et signaler les produits détériorés ou manquants",
    "Réaliser l'étiquetage",
    "Définir les conditions et le coût du transport avec le client et en organiser les différentes étapes (enlèvement, livraison, ...)",
    "Réaliser et contrôler les documents liés au contrat de transport et d'affrètement et les transmettre aux clients, prestataires, ...",
    "Contrôler et/ou préparer les éléments de facturation de la prestation de transport",
    "Déployer une prestation de transport dans le domaine aérien",
    "Déployer une prestation de transport dans le domaine ferroviaire",
    "Déployer une prestation de transport dans le domaine fluvial",
    "Déployer une prestation de transport dans le domaine maritime",
    "Déployer une prestation de transport dans le domaine routier international",
    "Déployer une prestation de transport dans le domaine routier national et communautaire",
    "Codifier la marchandise à partir du descriptif fourni par le client",
    "Collecter les documents d'expédition de la marchandise et constituer le dossier de la prestation (transit, douane)",
    "Réaliser et contrôler les documents liés à la circulation internationale de la marchandise (facture douanière, Déclaration Administrative Unique -DAU-, lettre de crédit, ...)",
    "Suivre l'acheminement des marchandises, informer le client sur le déroulement et présenter des solutions en cas d'anomalies",
    "Intervenir sur des dossiers de consignation maritime",
    "Intervenir sur des dossiers de déclaration en douane",
    "Intervenir sur des dossiers de réservation de fret",
    "Intervenir sur des dossiers de transit export marchandises",
    "Intervenir sur des dossiers de transit import marchandises",
    "Suivre la mise en place et la réalisation des plans d'action logistiques et contrôler la conformité d'application",
    "Superviser la planification de l'exploitation du ou des sites logistiques en fonction de l'activité (flux internes/externes, commandes spécifiques, ...)",
    "Effectuer les vérifications techniques de la cellule avant décollage (portes, chargement de coffres à bagages, ...) en lien avec le poste de pilotage",
    "Préparer, servir et débarrasser les plateaux-repas, collations ou éléments de confort à bord",
    "Intervenir auprès des passagers en cas de besoin ou difficulté et mettre en place les procédures adaptées (premiers secours, rappel des mesures de sécurité, ...)",
    "Intervenir sur une ligne aérienne court-courrier",
    "Intervenir sur une ligne aérienne long-courrier",
    "Intervenir sur une ligne aérienne moyen-courrier",
    "Contrôler le dossier de vol, le plan de vol, les données techniques de l'appareil et vérifier le niveau de carburant et les conditions météorologiques",
    "Procéder aux contrôles et à la préparation du poste de pilotage, des équipements et matériels de la cellule avec le personnel navigant commercial -PNC-",
    "Transmettre et échanger les informations de vol avec le personnel de bord et le personnel technique au sol",
    "Organiser ou superviser les opérations à bord (chargement, accueil passagers, ...) et mettre en place des mesures d'urgence en cas d'aléas",
    "Réaliser les opérations de mise en vol, de pilotage et d'atterrissage de l'appareil et procéder aux vérifications techniques à chaque étape",
    "Contrôler les paramètres de navigation et de fonctionnement de l'appareil en vol et adapter le plan de vol en liaison avec les centres de contrôle aérien",
    "Réaliser les différents comptes rendus de fin de vol et les transmettre au service concerné",
    "Réaliser des vols aériens court et moyen-courrier",
    "Réaliser des vols aériens long courrier",
    "Réaliser des opérations d'évacuation sanitaire par transport aérien",
    "Réaliser des opérations de fret par transport aérien",
    "Réaliser des opérations de transport aérien de passagers",
    "Réaliser des opérations de « Travail Aérien » nécessitant une qualification Agricole, épandage dispersion",
    "Réaliser des opérations de « Travail Aérien » nécessitant une qualification banderoles",
    "Réaliser des opérations de « Travail Aérien » nécessitant une qualification Hélitreuillage",
    "Réaliser des opérations de « Travail Aérien » nécessitant une qualification largage de charges toute nature",
    "Réaliser des opérations de « Travail Aérien » nécessitant une qualification lutte incendie",
    "Réaliser des opérations de « Travail Aérien » nécessitant une qualification relevés, photographie, surveillance aérienne",
    "Réaliser des opérations de « Travail Aérien » nécessitant une qualification remorquage planeur",
    "Réaliser des opérations de « Travail Aérien » nécessitant une qualification transport de charges à l'élingue",
    "Réaliser des opérations de « Travail Aérien » nécessitant une qualification vérification de lignes électriques",
    "Informer la personne, la famille sur les soins funéraires et les démarches administratives nécessaires",
    "Vérifier les documents administratifs post mortem (certificat de décès, autorisation de transport, d'inhumer, de crémation, ...)",
    "Procéder aux opérations de transfert, de réception de corps, de préparation, d'aide aux autopsies",
    "Réaliser des soins de conservation du corps (aspiration et élimination de liquides corporels, injection de mélanges antiseptiques, ...)",
    "Réaliser la restauration esthétique du corps ou le remodelage post-traumatique du visage du défunt",
    "Réaliser la toilette post mortem, le maquillage et la présentation du défunt",
    "Animer des jeux, sketchs, karaoké, présenter et distribuer des lots, des cadeaux au public",
    "Défiler lors de manifestations publiques selon les indications du styliste et les répétitions",
    "Mettre en scène des spectacles de cirque",
    "Mettre en scène des comédies musicales",
    "Mettre en scène des spectacles de contes",
    "Mettre en scène des lectures, monologues",
    "Mettre en scène des spectacles de mime, pantomime, ...",
    "Mettre en scène des opéras",
    "Mettre en scène des opérettes",
    "Mettre en scène des pièces de théâtre",
    "Mettre en scène des spectacles d'improvisation",
    "Mettre en scène des spectacles de rue",
    "Cerner la personnalité de l'artiste, du mannequin, du sportif et définir ses objectifs de carrière",
    "Vérifier la conformité du montage du film, de l'émission, du documentaire, ...",
    "Réaliser des clips vidéo",
    "Réaliser des documentaires cinématographiques ou audiovisuels",
    "Définir ou participer à la définition du projet individuel ou collectif d'entraînement avec les entraîneurs, le directeur sportif, ...",
    "Réaliser les exercices de préparation physique selon le programme d'entraînement, de compétition",
    "Déterminer le mode d'intervention avec l'entraîneur, l'équipe, le responsable technique selon les objectifs de la compétition et la situation",
    "Sélectionner le matériel ou les équipements selon la compétition et les contraintes",
    "Concourir en compétition et adapter les actions selon les directives de l'entraîneur, du directeur sportif",
    "Concevoir les coiffures, les maquillages des interprètes/intervenants pour un spectacle, un tournage selon les indications (thème, scénario, époque)",
    "Déterminer les conditions de réalisation (temps, lieu, logistique, compétences) des coiffures ou des maquillages, les moyens (matériel, budget) et effectuer les commandes",
    "Réaliser les essais de coiffure ou de maquillage sur les interprètes/les intervenants",
    "Maquiller les interprètes et les intervenants selon les types de rôle, les scènes, les séquences, ...",
    "Coiffer les interprètes et les intervenants selon le contexte de la scène, de la séquence",
    "Procéder aux raccords et aux changements de coiffure ou de maquillage entre deux prises, deux séquences, ...",
    "Vérifier les costumes et réaliser les retouches, les finitions",
    "Installer les costumes des interprètes dans la loge, selon la chronologie du spectacle",
    "Concevoir les costumes des interprètes d'un spectacle, d'un tournage selon les indications (époque, scénario, thème, ...)",
    "Transmettre les accessoires de jeu (journal, bouteille, valise, ...) aux acteurs, au cours du tournage, de la représentation",
    "Contrôler la similitude des décors et des accessoires du début à la fin du tournage ou de la représentation",
    "Régler les projecteurs fixes ou mobiles en fonction des ambiances définies",
    "Visionner les rushes du tournage et sélectionner les prises pour le montage selon les indications et les intentions artistiques du réalisateur, du journaliste, ...",
    "Synchroniser les sons directs avec les images pour le montage du film",
    "Superviser l'acheminement et l'installation de la logistique et de la signalétique sur le lieu d'un spectacle ou d'un tournage",
    "Intervenir sur une zone européenne",
    "Intervenir sur une zone mondiale",
    "Estimer la valeur de l'entreprise analysée et élaborer les conclusions",
    "Contrôler la gestion de la trésorerie",
    "Suivre un budget",
    "Suivre des séquestres conventionnels",
    "Superviser les procédures de gestion financière",
    "Superviser la gestion de la trésorerie",
    "Diriger un Établissement Public à caractère Administratif (EPA)",
    "Diriger un Établissement Public à caractère Industriel et Commercial (EPIC)",
    "Diriger un Établissement Public à caractère Scientifique et Technologique (EPST)",
    "Diriger un Établissement Public à caractère Scientifique, Culturel et Professionnel (EPSCP)",
    "Diriger un Établissement Public de Coopération Culturelle (EPCC)",
    "Compléter les formulaires, les questionnaires et les comptes rendus",
    "Superviser les procédures de gestion administrative",
    "Intervenir en recherche, innovation et prospective",
    "Adapter les outils de traitement statistique de données",
    "Rédiger l'information produite (études, synthèses, rapports, bulletins, ...) et établir des prévisions, des évaluations, des recommandations, des perspectives, ...",
    "Présenter et diffuser les résultats des études réalisées",
    "Réaliser une étude",
    "Analyser les besoins de l'entreprise en formation, en recrutement et en mobilité professionnelle",
    "Réaliser des profils de postes",
    "Réaliser des référentiels de formations",
    "Organiser la concertation et le dialogue social avec les membres du personnel ou leurs représentants",
    "Superviser la gestion administrative du personnel",
    "Réaliser la gestion de la paie",
    "Piloter des actions d'une démarche qualité",
    "Piloter des actions de mobilité, reclassement",
    "Accueillir les personnes",
    "Trier et assembler les catalogues, le courrier, les imprimés, les journaux selon les destinataires",
    "Restituer les éléments non distribués au service concerné",
    "Distribuer des documents dans les boîtes aux lettres",
    "Concevoir des indicateurs",
    "Réaliser les recherches documentaires en lien avec l'activité de la structure ou du service et les transmettre ou les archiver",
    "Conseiller le patient lors de démarches administratives (constitution de dossiers, remplissage de formulaires)",
    "Réaliser l'administration des ventes export",
    "Réaliser l'administration des ventes",
    "Concevoir les modalités de fabrication des produits selon les impératifs de production",
    "Intervenir sur un marché national",
    "Intervenir sur un marché international",
    "Organiser la stratégie commerciale sur une zone géographique internationale",
    "Organiser la stratégie commerciale sur une zone géographique nationale",
    "Organiser la stratégie commerciale sur une zone géographique régionale",
    "Intervenir en gros systèmes",
    "Intervenir en mini systèmes",
    "Analyser et définir les besoins de l'entreprise, des utilisateurs en matière d'organisation et de systèmes d'information et de télécoms",
    "Concevoir le schéma directeur à partir des orientations fixées par la direction et des besoins des services utilisateurs et superviser les modalités de mise en oeuvre",
    "Procéder au choix de réalisation, de traitement en interne ou par sous-traitance et en contrôler la conformité de réalisation",
    "Définir et contrôler l'application des procédures qualité et sécurité des systèmes d'information et de télécoms",
    "Diriger un service, une structure",
    "Superviser un secteur/projet de déploiement (matériels, système, réseau, postes de travail, logiciels)",
    "Superviser un secteur/projet de maintenance",
    "Superviser un secteur/projet de pilotage de ressources logiques et physiques",
    "Superviser un secteur/projet de sécurité, qualité",
    "Recueillir et définir les besoins de l'entreprise, des clients, des utilisateurs en matière de systèmes télécoms (capacité, fiabilité, sécurité, ...)",
    "Définir et élaborer les spécifications fonctionnelles et techniques d'un réseau, d'équipements télécoms (matériel, logiciel, implantation)",
    "Déterminer des composants d'architecture, des technologies, des équipements, des outils supports et les intégrer selon les spécifications",
    "Définir et contrôler les procédures, les protocoles d'essais, de tests ou de validation de réseaux télécoms",
    "Effectuer une navigation nécessitant l'Attestation de Capacité Professionnelle -ACP-",
    "Effectuer une navigation nécessitant l'attestation ADNR (transport de matières dangereuses)",
    "Effectuer une navigation nécessitant une attestation spéciale passagers",
    "Effectuer une navigation nécessitant un certificat de radiotéléphonie",
    "Effectuer une navigation nécessitant une attestation radar",
    "Conduire un véhicule nécessitant un permis T « piste aéroportuaire »",
    "Conduire un véhicule nécessitant une habilitation",
    "Conduire un véhicule de moins de 3,5 t et moins de 14 m4",
    "Conduire un véhicule de moins de 3,5 t et plus de 14 m4",
    "Conduire un véhicule poids lourds de moins de 7,5 t",
    "Conduire un véhicule poids lourds de plus de 7,5 t",
    "Procéder à l'avitaillement en carburant d'un avion",
    "Piloter un avion nécessitant une qualification homologuée Flight Crew Licencing (FCL)",
    "Piloter un hélicoptère nécessitant une qualification homologuée Flight Crew Licencing (FCL)",
    "Langage informatique Apache",
    "Langage informatique CSS",
    "Langage informatique SharePoint",
    "Système d'exploitation informatique Apache Tomcat",
    "Langage informatique J2EE",
    "Langage informatique PL/SQL",
    "Langage informatique Script Shell",
    "Langage informatique T-SQL",
    "Langage informatique ASP.NET",
    "Langage informatique AutoItScript",
    "Langage informatique Java",
    "Langage informatique J2ME",
    "Langage informatique Pascal",
    "Langage informatique Ada",
    "Langage informatique C#",
    "Langage informatique Cobol",
    "Langage informatique Fortran",
    "Langage informatique Lisp",
    "Langage informatique Perl",
    "Langage informatique PHP",
    "Langage informatique SQL",
    "Langage informatique Visual Basic",
    "Langage informatique WLangage",
    "Langage informatique C/C++",
    "Langage informatique Assembleur",
    "Langage informatique HDL/VHDL",
    "Système d'exploitation IBM Aix",
    "Système d'exploitation Linux",
    "Français Langue Étrangère -FLE-",
    "Grues auxiliaires de chargement de véhicules (CACES R 390)",
    "Langue des signes",
    "Mainframes et systèmes IBM",
    "Langage informatique Ruby",
    "Brevet d'Animateur pour la Formation des Conducteurs Responsables d'Infractions (BAFCRI)",
    "Brevet pour l'Exercice de la Profession d'Enseignant de la Conduite Automobile et de la Sécurité Routière (BEPECASER)",
    "Brevet pour l'Exercice de la Profession d'Enseignant de la Conduite Automobile et de la Sécurité Routière (BEPECASER) mention deux-roues",
    "Brevet pour l'Exercice de la Profession d'Enseignant de la Conduite Automobile et de la Sécurité Routière (BEPECASER) mention groupe lourd",
    "Langue étrangère - Anglais des affaires",
    "Langue étrangère - Espagnol des affaires",
    "Langue étrangère - Allemand des affaires",
    "Brevet d'aptitude à l'exploitation des canots de secours rapides",
    "Pilote de Ligne Avion (PL pratique, -ATPL-)",
    "Pilote Professionnel Avion (PP, CPL)",
    "Travail en Équipage Avion (FTE, MCC)",
    "Qualification machine Avion (1ère QT JAR25, QT Multipilote)",
    "Vol aux instruments Avion (IFR, IR)",
    "Pilote de Ligne Hélicoptère -PLH- pratique, (ATPL -H-)",
    "Pilote Professionnel Hélicoptère -PPH-, (CPL, Hélicoptère -H-)",
    "Travail en Équipage Hélicoptère (MCC -H-)",
    "Vol aux instruments Hélicoptère (PPH/IFR, IR -H-)",
    "Microsoft Office PowerPoint (diaporama)",
    "Microsoft Visio",
    "Microsoft SQL Server",
    "Microsoft Dynamics CRM",
    "Microsoft Visual Studio",
    "Node.js",
    "Filières IAA",
    "Ponts roulants et portiques à commande en cabine (CACES R 484-2) - A partir du 01/01/2021",
    "Gerbeurs à conducteur accompagnant (CACES R 485) - A partir du 01/01/2021",
    "Gerbeurs à conducteur accompagnant (1,20 m < h < ou = à 2,50 m) (CACES R 485-1) - A partir du 01/01/2021",
    "Gerbeurs à conducteur accompagnant (h > 2,50 m) (CACES R 485-2) - A partir du 01/01/2021",
    "Plates-formes élévatrices mobiles de personnel (CACES R 486) - A partir du 01/01/2021",
    "PEMP du groupe A (CACES R 486-A) - A partir du 01/01/2021",
    "PEMP du groupe B (CACES R 486-B) - A partir du 01/01/2021",
    "Conduite hors production des PEMP des types 1 et 3 (CACES R 486-C) - A partir du 01/01/2021",
    "Grues à tour (CACES R 487) - A partir du 01/01/2021",
    "GME à flèche distributrice (CACES R 487-1) - A partir du 01/01/2021",
    "GME à flèche distributrice (CACES R 487-2) - A partir du 01/01/2021",
    "GMA Grue à tour à montage automatisé (CACES R 487-3) - A partir du 01/01/2021",
    "Chariots de manutention automoteurs à conducteur porté (CACES R 489) - A partir du 01/01/2021",
    "Transpalettes et préparateurs sans élévation du poste de conduite (h < ou = à 1,20 m) (CACES R 489-1A) - A partir du 01/01/2021",
    "Gerbeurs à conducteur porté (hauteur de levée > 1.20 m) (CACES R 489-1B - A partir du 01/01/2021",
    "Chariots porteurs (capacité de charge < ou = à 2 tonnes) (CACES R 489-2A) - A partir du 01/01/2021",
    "Chariots tracteurs (capacité de traction < ou = à 25 tonnes)(CACES R 489-2B) - A partir du 01/01/2021",
    "Chariots frontaux en porte-à-faux (capacité nominale < ou = à 6 tonnes) (CACES R 489-3) - A partir du 01/01/2021",
    "Chariots frontaux en porte-à-faux (capacité nominale > 6 tonnes) (CACES R 489-4) - A partir du 01/01/2021",
    "Chariots à mât rétractable (CACES R 489-5) - A partir du 01/01/2021",
    "Chariots à poste de conduite élevable (hauteur plancher > 1,20 m) (CACES R 489-6) - A partir du 01/01/2021",
    "Conduite des chariots hors production des chariots à conducteur porté de toutes les catégories (CACES R 489-7) - A partir du 01/01/2021",
    "Grues de chargement (CACES R 490) - A partir du 01/01/2021",
    "Habilitation au pilotage de drone professionnel",
    "Scénario de vol S1",
    "Scénario de vol S2",
    "Scénario de vol S3",
    "Scénario de vol S4",
    "Assister techniquement dans l'élaboration de dossiers de Permis de construire",
    "Intervenir en réhabilitation de l'habitat",
    "Habilitation transport de fonds",
    "Attestation de formation professionnelle initiale des conducteurs de véhicules de guidage - FIG",
    "Produits carnés (viande, volaille)",
    "Gestion du risque vol",
    "Modalité de stockage du lait",
    "Gravure à l'acide",
    "Fibres de carbone",
    "Vie pratique (santé, cuisine, ...)",
    "Feuilleton radiophonique",
    "Théâtre classique",
    "Presse",
    "Caractéristiques des véhicules de collection",
    "Allergies alimentaires",
    "Attestation de capacité de déménagement",
    "Caractéristiques des bibelots",
    "Instruments de musique",
    "Caractéristiques des sculptures",
    "Lunetterie / optique",
    "Techniques de peinture décorative",
    "Habilitation agent de sûreté aéroportuaire",
    "Techniques de typogravure",
    "Écrire un texte dans un genre littéraire spécifique",
    "Lecture de modes d'emploi (produits, matériels, /...)",
    "Permis de conduire BE",
    "Permis de conduire C2",
    "Permis de conduire C1E",
    "Permis de conduire CE",
    "Permis de conduire D2",
    "Permis de conduire D1E",
    "Permis de conduire DE",
    "Techniques d'exploration du champ visuel (périmétrie, campimétrie, ...)",
    "Post-synchronisation",
    "Techniques de recherche d'emploi",
    "Habilitation d'opérateur en télésurveillance",
    "Techniques de façonnage par calibrage",
    "Pratique du triathlon",
    "Langues vivantes étrangères",
    "Chaîne de création d'un film d'animation",
    "Techniques cinématographiques (cadrage, mouvements de caméra, ...)",
    "Logiciel d'éclairage",
    "Techniques de Layout décor",
    "Logiciel de motion capture",
    "Logiciel TVPaint",
    "Montage, utilisation et démontage des échafaudages de pied (CACES R 408)",
    "Montage, démontage et utilisation des échafaudages roulants (CACES R 457)",
    "Pratique du cor",
    "Cuisine des spécialités régionales françaises",
    "Logiciel FlexiSign",
    "Logiciel SolidWorks",
    "Logiciel RIP d'impression",
    "Bonnes pratiques de dispensation de l'oxygène - BPDO",
    "Méthodes d'industrialisation",
    "Techniques de maçonnerie paysagère",
    "Habilitation Socle Commun Nucléaire niveau 1 (SCN1)",
    "Habilitation Socle Commun Nucléaire niveau 2 (SCN2)",
    "Habilitation Radioprotection niveau 1 (RP1)",
    "Habilitation Radioprotection niveau 2 (RP2)",
    "Habilitation Complément Sûreté Qualité (CSQ)",
    "Utilisation d'outils collaboratifs (planning partagé, web conférence, réseau social d'entreprise, ...)",
    "Systèmes d'Aide à l'Exploitation et à l'Information Voyageurs - SAEIV",
    "Principes de l'éco-conduite",
    "Matériovigilance",
    "Méthodologie SAFe",
    "Animation d'instances agiles (mêlée, démonstration, rétrospective, …)",
    "Engin de chantier (CACES R 482) - A partir du 01/01/2021",
    "Engins compacts (CACES R 482-A) - A partir du 01/01/2021",
    "Engins d’extraction à déplacement séquentiel (CACES R 482-B1) - A partir du 01/01/2021",
    "Engins de sondage ou de forage à déplacement séquentiel (CACES R 482-B2) - A partir du 01/01/2021",
    "Engins rail-route à déplacement séquentiel (CACES R482-B3) - A partir du 01/01/2021",
    "Engins de chargement à déplacement alternatif (CACES R 482-C1) - A partir du 01/01/2021",
    "Engins de réglage à déplacement alternatif (CACES R 482-C2) - A partir du 01/01/2021",
    "Engins de nivellement à déplacement alternatif (CACES R 482-C3) - A partir du 01/01/2021",
    "Engins de compactage (CACES R 482-D) - A partir du 01/01/2021",
    "Engins de transport (CACES R 482-E) - A partir du 01/01/2021",
    "Chariots de manutention tout-terrain (CACES R 482-F) - A partir du 01/01/2021",
    "Conduite hors-production des engins de chantier des catégories A à F (CACES R 482-G) - A partir du 01/01/2021",
    "Grues mobiles (CACES R 483) - A partir du 01/01/2021",
    "Grues mobiles à flèche treillis (CACES R 483-A) - A partir du 01/01/2021",
    "Grues mobiles à flèche télescopique (CACES R 483-B) - A partir du 01/01/2021",
    "Ponts roulants et portiques (CACES R 484) - A partir du 01/01/2021",
    "Ponts roulants et portiques à commande au sol (CACES R 484-1) - A partir du 01/01/2021",
    "Logistique internationale",
    "Sécurité portuaire",
    "Guerre de l'information",
    "Sécurité économique",
    "Organisation des structures sanitaires et sociales",
    "Typologie des prestations sanitaires et sociales",
    "Droit aux prestations et offre de services",
    "Création d'entreprise innovante",
    "Management de l'expérience client",
    "Google analytics",
    "Probabilités",
    "Langage informatique PDO (PHP Data Objects)",
    "Structures de données",
    "Statistique inférentielle",
    "Modèle linéaire",
    "Algèbre linéaire",
    "Langage informatique SQL4",
    "Interface de programmation Java Database Connectivity (JDBC)",
    "Responsabilité sociétale des organisations - ISO 26001",
    "Épistémologie",
    "Classification automatique",
    "Classification supervisée",
    "Recherche opérationnelle",
    "Théories du leadership",
    "Systèmes avancés",
    "Web sémantique",
    "Méthodes d'intégration numérique",
    "Atelier de Génie Logiciel (AGL)",
    "PRINCE3",
    "Séries temporelles",
    "Maintenance logicielle",
    "Langage informatique C",
    "E-Business",
    "Marketing multicanal",
    "Mathématiques pour l'ingénieur",
    "Mécanique des milieux continus",
    "Géologie de l'ingénieur",
    "Calcul de structures",
    "Procédés généraux de construction",
    "Propriétés des matériaux de construction",
    "Dimensionnement des structures",
    "Conception d'ouvrages géotechniques",
    "Hydraulique urbaine",
    "Organisation de chantier",
    "Analyse numérique",
    "Caractéristiques du béton armé",
    "Codes des marchés privés",
    "Charpentes et ossatures mixte",
    "Conception routière",
    "Législation HQE - Haute Qualité Environnementale",
    "Design informationnel",
    "Négociation commerciale",
    "Droit des achats",
    "Marketing des achats",
    "Construction de maisons à ossature bois",
    "Menuiserie extérieure",
    "Techniques d'étanchéité en construction",
    "Résistance Des Matériaux (RDM)",
    "Réglementation des pressings",
    "Protection sociale",
    "Santé et sécurité au travail",
    "Droit social individuel",
    "Droit social collectif",
    "Formes de la rémunération",
    "Systèmes de planification avancée (Advanced Planning System - APS)",
    "Systèmes de gestion des transports (Transport Management System - TMS)",
    "Management des ressources externes",
    "Négociation achat",
    "Électrothermie",
    "Styles musicaux",
    "Conduite d'attelage canin",
    "Pratique du Taekwondo",
    "Divertissements (jeux, variétés, ...)",
    "Émissions à thèmes (littérature, sport, cinéma, ...)",
    "Pratique de chant : tessiture mezzo-soprano",
    "Disques",
    "Produits frais",
    "Techniques de rééducation cardiovasculaire",
    "Techniques de rééducation des troubles veino-lymphatiques",
    "Techniques de rééducation neurologique",
    "Techniques d'électrophysiologie oculaire",
    "Techniques d'établissement de la courbe d'adaptation à l'obscurité",
    "Techniques de rétinographie",
    "Petits effets spéciaux (cicatrices, balafres, griffures, ...)",
    "Techniques de sculpture",
    "Produits périssables",
    "Milieux urbains et industriels",
    "Cristallerie / verrerie",
    "Législation de la formation continue",
    "Procédures d'appels d'offres",
    "Techniques de prise de notes",
    "Mise en tension des aciers",
    "Pratiques énergétiques",
    "Attestation de formation professionnelle initiale des conducteurs de véhicules de protection - FIP",
    "Principes déontologiques liés aux débats et aux échanges",
    "Techniques de conserverie",
    "Produits laitiers, oeufs",
    "Techniques de spectacle de rue",
    "Qualité et traitement des eaux",
    "Actualité quotidienne / informations",
    "Météorologie",
    "Film érotique, pornographique",
    "Caractéristiques des véhicules industriels",
    "Utilisation d'appareils de lecture optique de codes-barres (pistolet, flasheur, ...)",
    "Maladies métaboliques",
    "Déséquilibre du poids",
    "Affections cardiaques",
    "Caractéristiques morphologiques de la tête",
    "Éléments de base en psychologie",
    "Pratiques naturelles",
    "Méthode d'analyse de besoins (poste, formation, projet, /...)",
    "Fleurs coupées, plantes en pots",
    "Procédé de vitrail au plomb",
    "Procédé Tiffany",
    "Caractéristiques des pierres fines",
    "Techniques de ligaturage",
    "Film institutionnel",
    "Caractéristiques des séries télévisées",
    "Théâtre contemporain",
    "Pratique de chant : tessiture basse",
    "Caractéristiques des tabacs et articles pour fumeurs",
    "Techniques de rééducation de la déglutition",
    "CACES Attestation de formation monte-meubles",
    "Matières dangereuses",
    "Attestation de capacité de transport marchandises plus de 3,5t",
    "Attestation de capacité de transport voyageurs",
    "Argenterie",
    "Droit de l'enfant et protection des mineurs",
    "Meubles",
    "Caractéristiques des timbres, marques postales",
    "Caractéristiques des chaussures",
    "Techniques de formation en présentiel",
    "Pratique des sports automobiles",
    "Émissions avec débats",
    "Programmes jeunesse",
    "Pratique d'un instrument de musique",
    "Film court métrage",
    "Lecture-spectacle / monologue",
    "Caractéristiques des armes",
    "Distribution de carburant",
    "Techniques de rééducation des troubles de l'équilibre",
    "Caractéristiques des troubles du comportement alimentaire",
    "Différenciation des spécificités des publics cibles",
    "Caractéristiques des bijoux",
    "Monnaies, médailles",
    "Caractéristiques des céramiques réfractaires",
    "Éléments de base en anatomie",
    "Site web",
    "Application au pochoir",
    "Techniques de laque tendue",
    "Techniques OFFSET",
    "Techniques de sérigraphie",
    "Numéros visuels, cabaret, autres spectacles",
    "Théâtre de boulevard",
    "Pratique de chant : tessiture contralto ou alto",
    "Vêtements, accessoires",
    "Caractéristiques des voitures neuves",
    "Techniques de rééducation respiratoire",
    "Techniques d'exploration du sens chromatique",
    "Techniques de mesure de la tension oculaire",
    "Pathologies digestives",
    "Produits de valeur",
    "Produits sous douane",
    "Verrerie et céramique",
    "Modalités d'accueil",
    "Caractéristiques de la faïence",
    "Techniques de maitrise des énergies",
    "Soins vétérinaires",
    "Techniques de formation collective",
    "Techniques de formation individuelle",
    "Techniques de taille douce",
    "Plats cuisinés à base de poisson",
    "Fruits et légumes",
    "Produits d'alimentation générale",
    "Caractéristiques des minerais",
    "Techniques de sauvegarde/mise en valeur du patrimoine naturel",
    "Pratique de l'escrime",
    "Cirque (M. ou Mme Loyal)",
    "Programmes musicaux (classiques, jazz, rock, ...)",
    "Pratique du conte",
    "Caractéristiques des téléfilms",
    "Pratique de chant : tessiture ténor",
    "Pratique de chant : tessiture soprano",
    "Loterie, jeux",
    "Caractéristiques des véhicules de société",
    "Caractéristiques des voitures d'occasion",
    "Pratique de la danse",
    "Cascades équestres",
    "Techniques de rééducation orthopédique",
    "Effets spéciaux moyens (vieillissement au latex, faux crânes, ...)",
    "Caractéristiques des textiles et du linge",
    "Marchandises sous température dirigée",
    "Attestation de capacité de transport marchandises moins de 3,5t",
    "Superviser un établissement de santé de 40 à 200 lits",
    "Superviser un établissement de santé de 201 à 400 lits",
    "Superviser un établissement de santé de 401 à 1000 lits",
    "Superviser un établissement de santé de + de 1000 lits",
    "Réaliser des plongées hyperbare",
    "Réaliser des plongées inférieures à 40 m",
    "Réaliser des plongées inférieures à 60 m",
    "Réaliser des plongées inférieures à 80 m",
    "Effectuer une navigation nécessitant un permis côtier",
    "Effectuer une navigation nécessitant un permis hauturier",
    "Intervenir sur des avions d'observation",
    "Intervenir sur des avions de combat",
    "Intervenir sur des avions de transport de marchandises",
    "Intervenir sur des avions de transport de voyageurs",
    "Intervenir sur des avions légers (- 5,7 tonnes)",
    "Intervenir sur des hélicoptères",
    "Intervenir sur des hydravions",
    "Effectuer une navigation nécessitant un permis fluvial",
    "Effectuer une navigation nécessitant un permis rivière",
    "Intervenir sur un cyclomoteur (- de 50cc)",
    "Intervenir sur un scooter (- de 50cc, 80cc, 100cc, 125cc, + de 125cc)",
    "Intervenir sur un scooter des neiges ou des mers",
    "Intervenir sur un side-car",
    "Intervenir sur un vélomoteur (Solex, ...)",
    "Intervenir sur une voiturette (sans permis)",
    "Intervenir sur des moteurs 3 cylindres",
    "Intervenir sur des moteurs 4 cylindres",
    "Suivre et contrôler la conformité réglementaire d'utilisation de véhicules (contrôle technique, assurance, permis, temps de repos, ...)",
    "Contrôler la légalité a posteriori des actes émis par les collectivités territoriales (mariages, élections, permis de construire)",
    "Réaliser le suivi d'application des peines des condamnés (permission de sortie, réduction de peine, démarches de réinsertion, ...)",
    "Effectuer les manoeuvres d'accrochage/décrochage d'avions au véhicule de tractage et convoyer des aéronefs sur l'aire de roulement",
    "Collecter les taux d'occupation, réservation, tarifs sur le rendement de nuitées, de places d'avion",
    "Analyser les taux d'occupation, réservation, tarifs sur le rendement de nuitées, de places d'avion",
    "Élaborer des dossiers de permis de construire",
    "Instruire une demande de permis de construire",
    "Former au pilotage d'un hélicoptère",
    "Former au pilotage d'un avion",
  ];
  //get salaire aggr

  const getCompetenceAggr = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/competenceAggr`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        filtre: filtre,
      })
      .then((resp) => {
        let data = resp.data.aggregations.experiences.buckets;
        // console.log(data);
        setGraph(data);
      });
  };
  const getCompetenceTableAggr = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/competenceTableAggr`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        filtre: filtre,
      })
      .then((resp) => {
        //let data = resp.data;
        setTable(resp.data);
      });
  };
  // Init function
  useEffect(() => {
    setGraph([]);
    getCompetenceAggr();
    getCompetenceTableAggr();
  }, [filtre]);

  return (
    <Fragment>
      <Indicateur></Indicateur>

      <MDBRow>
        <MDBCol size="12" className="text-center mb-2">
          <TimelineIcon
            className={`mr-3 ${
              view === "graph" ? "blue-text" : "grey-text"
            } pointer`}
            onClick={(e) => {
              setView("graph");
            }}
          ></TimelineIcon>
          <TableChartIcon
            className={`mr-3 ${
              view === "table" ? "blue-text" : "grey-text"
            } pointer`}
            onClick={(e) => setView("table")}
          ></TableChartIcon>
        </MDBCol>
      </MDBRow>

      {view == "table" && table.length > 0 && (
        <table className="table mb-5  table-striped">
          <tbody>
            {table.map((t, i) => {
              return (
                <tr>
                  <td>
                    <b>
                      <CountUp
                        delay={1}
                        start={0}
                        end={t.doc_count}
                        duration={4}
                        separator=" "
                        decimals={1}
                        decimal="."
                        prefix="  "
                        suffix="%  "
                      />
                      {t.key}
                    </b>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {view == "graph" && graph.length > 0 && (
        <NestedDonut
          typeData="competences"
          id="typeexperienceDev"
          data={graph}
          title="Top compétences par niveau d'expérience recherché"
        ></NestedDonut>
      )}
    </Fragment>
  );
};
export default Competence;
