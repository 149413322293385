import React, { Fragment, useState, useEffect, useRef } from "react";
import CountUp from "react-countup";
import { MDBCardImage, MDBTooltip, MDBIcon, MDBBtn } from "mdbreact";
import { color } from "@amcharts/amcharts4/core";
import { width } from "@amcharts/amcharts4/.internal/core/utils/Utils";
const Total = (props) => {
  const [valeur, setValeur] = useState(0);
  const style = { display: "inline-block", maxWidth: "50vh" };
  const styleInfo = {
    backgroundColor: "white !important",
    padding: "0px !important",
    boxShadow: "0px  !important",
  };
  useEffect(() => {
    /*
    if (props.type === "numero")
      setValeur(Intl.NumberFormat("fr-FR").format(props.valeur));
    if (props.type === "pourcentage")
      setValeur(
        Intl.NumberFormat("fr-FR", {
          maximumSignificantDigits: props.decimal,
        }).format(props.valeur) + " %"
      );
    if (props.type === "argent")
      setValeur(
        Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        }).format(props.valeur)
      );
      */
  });

  return (
    <div
      className={` pl-1 p-1 rounded white-text mb-4 mb-md-0`}
      style={{
        borderColor: props.bgColor,
        borderStyle: "solid",
        borderWidth: "1px",
      }}
    >
      <small
        className={`${props.bgColor}-text`}
        style={{ fontSize: "75%", marginLeft: "10px" }}
      >
        <b>{props.titre}</b>
      </small>
      <MDBCardImage
        className=" d-flex justify-content-center align-items-center flex-column "
        tag="div"
      >
        <h2 className={`${props.bgColor}-text`}>
          {" "}
          {props.type === "argent" && (
            <CountUp
              delay={0}
              start={0}
              end={props.valeur}
              duration={1}
              separator=" "
              decimals={0}
              decimal=","
              prefix=""
              suffix=" €"
            />
          )}
          {props.type === "numero" && (
            <CountUp
              delay={0}
              start={0}
              end={props.valeur}
              duration={1}
              separator=" "
              decimals={0}
              decimal=","
              prefix=""
              suffix={props.suffix}
            />
          )}
          {props.type === "pourcentage" && props.valeur > 0 && (
            <CountUp
              delay={0}
              start={0}
              end={props.valeur}
              duration={1}
              separator=" "
              decimals={2}
              decimal="."
              prefix=""
              suffix=" %"
            />
          )}
          {props.type === "argent" && (
            <div style={style} className="text-right">
              <small className={`${props.bgColor}-text smaltotal`}>
                <MDBTooltip placement="top">
                  <MDBBtn color="" className="infobull" size="sm">
                    <MDBIcon icon="info-circle"></MDBIcon>
                  </MDBBtn>
                  <div>Par mois (équivalent temps plein)</div>
                </MDBTooltip>
              </small>
            </div>
          )}
          {props.type !== "pourcentage" && (
            <small className={`${props.bgColor}-text smaltotal`}>
              {props.sous_titre}
            </small>
          )}
          {props.type === "pourcentage" && (
            <small className={`${props.bgColor}-text smaltotal`}>
              <CountUp
                start={0}
                end={props.sous_titre}
                duration={2.75}
                separator=" "
                decimals={0}
                decimal=","
                prefix=""
                suffix=" offres"
              />
            </small>
          )}
        </h2>
      </MDBCardImage>
    </div>
  );
};

export default Total;
