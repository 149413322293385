import axios from 'axios';

export const submit_hubspot_form = async (email, firstname, lastname, message) => {
    const portalId = '20409429';
    const formGuid = '81e4d813-e80b-49ec-b83a-7b5d9525cdf4';
    const config = { // important!
        headers: {
            'Content-Type': 'application/json',
        }
    }

    const response = await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
            portalId,
            formGuid,
            fields: [
                {
                    name: 'firstname',
                    value: firstname,
                },
                {
                    name: 'lastname',
                    value: lastname,
                },
                {
                    name: 'email',
                    value: email,
                },
                {
                    name: 'message',
                    value: message,
                },
            ],
        },
        config
    );
    return response;
}