import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavFiltre } from "./../Contextes/navfiltreContext";
import AmChartLine from "../components/chart/AmChartLine";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import SelectSearch from "react-select-search";
import Indicateur from "../components/widget/Indicateur";
const SoftSkills = () => {
  // filtre
  const { filtre, setFiltre } = useNavFiltre();
  const [graph, setGraph] = useState([]);
  const [size, setSize] = useState(10);
  //get salaire aggr
  const getSoftSkillsAggr = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/softSkillsAggr`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        filtre: filtre,
        size: parseInt(size),
      })
      .then((resp) => {
        let data1 = [];
        let data = resp.data.aggregations.soft.buckets;
        for (var k in data) {
          console.log(k, data[k].doc_count);
          data1.push({ key: k, doc_count: data[k].doc_count });
        }
        var data2 = data1.sort(function (a, b) {
          return b.doc_count - a.doc_count;
        });
        data2 = data2.slice(0, 11);
        setGraph(data2);
      });
  };

  // Init function
  useEffect(() => {
    setGraph([]);
    getSoftSkillsAggr();
  }, [filtre, size]);

  return (
    <Fragment>
      {" "}
      <Indicateur></Indicateur>
      {/* <div className="width-10">
        <SelectSearch
          onChange={setSize}
          closeOnSelect={true}
          printOptions="on-focus"
          placeholder="Top 10"
          options={[
            { name: "Top 10", value: "10" },
            { name: "Top 15", value: "15" },
            { name: "Top 20", value: "20" },
            { name: "Top 25", value: "25" },
            { name: "Top 30", value: "30" },
          ]}
        />
      </div> */}
      {graph.length > 0 ? (
        <AmChartLine
          typeData="softskills"
          id="devSoftSkillss"
          title="Top Soft Skills"
          label=""
          data={graph}
          fill="#42a5f5"
        ></AmChartLine>
      ) : (
        <ScaleLoader size={10} color={"#303950"} loading={true} />
      )}
    </Fragment>
  );
};

export default SoftSkills;
