import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "../components/header/Header";
import "./DashBord.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SideBar from "../components/menu/SideBar";
import { MDBContainer, MDBRow, toast, ToastContainer } from "mdbreact";
import Tendance from "./Tendence";
import Salaire from "./Salaire";
import Secteur from "./Secteur";
import Offres from "./Offres";
import Recruteur from "./Recruteur";
import Metier from "./Metier";
import Formation from "./Formation";
import Rome from "./Rome";
import Contrat from "./Contrat";
import Competence from "./Comptence";
import SkillNorm from "./SkillNorm";
import SoftSkills from "./SoftSkills";
import Cv from "./Cv";
import Cv1 from "./Cv1";
import ProgramationSkills from "./ProgramationSkills";
import PrivateAdminRoute from "../PrivateAdminRoute";
import { CSVParser } from "@amcharts/amcharts4/core";

const DashBord = (props) => {
  return (
    <Fragment>
      <Header></Header>
      <ToastContainer
        hideProgressBar={false}
        newestOnTop={false}
        autoClose={1500}
      ></ToastContainer>
      <MDBContainer fluid>
        <MDBRow>
          <div className="dream-text p-2 border-right">
            <SideBar></SideBar>
          </div>
          <div className="max-Heigher m-0">
            <Switch>
              <Route path="/dashboard/tendance" component={Tendance} />
              <Route path="/dashboard/salaire" component={Salaire} />
              <PrivateAdminRoute path="/dashboard/secteur" component={Secteur} />
              <PrivateAdminRoute path="/dashboard/recruteur" component={Recruteur} />
              <Route path="/dashboard/formation" component={Formation} />
              <Route path="/dashboard/competence" component={Competence} />
              <Route path="/dashboard/Metier" component={Metier} />
              <Route path="/dashboard/Rome" component={Rome} />
              <Route path="/dashboard/Contrat" component={Contrat} />
              <Route path="/dashboard/Offres" component={Offres} />
              <PrivateAdminRoute path="/dashboard/softSkills" component={SoftSkills} />
              <PrivateAdminRoute path="/dashboard/SkillNorm" component={SkillNorm} />
              <Route path="/dashboard/Cv" component={Cv} />
              <Route path="/dashboard/Cv1" component={Cv1} />
              <Route
                path="/dashboard/programmationSkills"
                component={ProgramationSkills}
              />
              <Route path="*" component={Tendance} />
            </Switch>
          </div>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

export default DashBord;
