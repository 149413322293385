import React, { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import DashBord from "./pages/DashBord";
import LoginHome from "./pages/LoginHome";
/* context */
import { AuthContext } from "./Contextes/auth";
import { NavFiltreContext } from "./Contextes/navfiltreContext";
import { MDBContainer, MDBRow, toast, ToastContainer } from "mdbreact";
/* controll access to private routes*/
import PrivateRoute from "./PrivateRoute";
import SignIn from "./pages/SignIn";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import ContactPage from "./pages/ContactPage";
import { LayersControl } from "react-leaflet";
import Tendance from "./pages/Tendence";
import Admin from "./pages/Admin";
import axios from "axios";
import Contact from "./components/contact/Contact";
import PrivateAdminRoute from "./PrivateAdminRoute";
function App() {
  /*  manage token logic  */
  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);

  //init filtre function
  var now = new Date();
  const [filtre, setFiltre] = useState({
    search: "",
    fields: ["intitule"],
    period: [new Date(now.setDate(now.getDate() - 180)), new Date()],
    regions: [],
    typeContrat: [],
    niveauEtude: [],
    DureeTravail: [],
    salaire: [0, 15000],
    competences: [],
    formations: [],
    secteurs: [],
    metiers: [],
    romes: [],
    entreprises: [],
  });
  const [filtreRAM, setFiltreRAM] = useState({
    competences: [],
    formations: [],
    secteurs: [],
    metiers: [],
    romes: [],
    entreprises: [],
  });
  function addToFiltre(type, value) {
    let data = [];
    data = filtreRAM[type];
    if (!data.includes(value)) {
      filtreRAM[type] = [...data, value];
      console.log(filtreRAM);
      setFiltreRAM({ ...filtreRAM });
    } else {
      toast.warn("élément déjà sélectionné", {
        closeButton: false,
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    return 1;
  }

  const removeFromFiltre = (type, value) => {
    let array = filtreRAM[type];
    let index = array.indexOf(value);
    array.splice(index, 1);
    //filtreRAM[type] = [...array];
    setFiltreRAM({ ...filtreRAM, type: array });
    // toast.warn("Supprimé : " + value, {
    //   closeButton: false,
    //   position: toast.POSITION.BOTTOM_LEFT,
    // });
  };

  // enf filtre functions
  const setLoggedUser = (data) => {
    localStorage.setItem("role", JSON.stringify(data.role));
    localStorage.setItem(
      "tokens",
      JSON.stringify(data.token.replace(/"/g, ""))
    );
    localStorage.setItem(
      "userId",
      JSON.stringify(data.userId.replace(/"/g, ""))
    );
    localStorage.setItem("email", JSON.stringify(data.email.replace(/"/g, "")));
    localStorage.setItem("nom", JSON.stringify(data.nom.replace(/"/g, "")));
    localStorage.setItem(
      "prenom",
      JSON.stringify(data.prenom.replace(/"/g, ""))
    );
    localStorage.setItem(
      "fonction",
      JSON.stringify(data.fonction.replace(/"/g, ""))
    );
    localStorage.setItem(
      "entreprise",
      JSON.stringify(data.entreprise.replace(/"/g, ""))
    );

    setAuthTokens(data.token);
  };
  // axios token interception
  axios.interceptors.request.use(
    async (config) => {
      const token = JSON.parse(localStorage.getItem("tokens"));
      config.headers = {
        Authorization: `Bearer ${token}`,
        // 'Accept': 'application/json',
        //'Content-Type': 'application/x-www-form-urlencoded'
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      if (error.response.status === 401) {
        console.log("444444444444444444");
        window.location = "/";
      }
      return Promise.reject(error);
    }
  );
  return (
    <AuthContext.Provider
      value={{
        authTokens,
        setLoggedUser: setLoggedUser,
      }}
    >
      <NavFiltreContext.Provider
        value={{
          filtre,
          filtreRAM,
          setFiltre: setFiltre,
          setFiltreRAM: setFiltreRAM,
          addToFiltre: addToFiltre,
          removeFromFiltre: removeFromFiltre,
        }}
      >
        <BrowserRouter>
          <Switch>
            <PrivateAdminRoute path="/admin" component={Admin} />
            <PrivateRoute path="/dashboard" component={DashBord} />
            <PrivateRoute exact path="/offres" component={Home} />

            {/*  <Route exact path="/signin" component={SignIn} />*/}
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/" component={LoginHome} />
            <Route path="*" component={LoginHome} />
          </Switch>
        </BrowserRouter>
      </NavFiltreContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
