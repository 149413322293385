import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBNotification,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBView,
  MDBCardImage,
} from "mdbreact";

export default function LoginForm(props) {
  return (
    <div>
      <div className="text-center mb-3">
        <h4 className=" dream-text text-center">
          <strong>Se connecter</strong>
        </h4>
      </div>

      {props.error && (
        <MDBNotification
          style={{ "max-width": "100%" }}
          show
          fade
          iconClassName="text-warning"
          title="Error"
          message={props.message}
          text=""
        />
      )}
      <form
        className="needs-validation"
        onSubmit={(e) => {
          props.submitHandlerLogin(e);
        }}
        noValidate
      >
        <MDBInput
          icon="envelope"
          name="email"
          type="email"
          value={props.email}
          required
          onChange={(e) => {
            props.setEmail(e.target.value);
          }}
        />
        <span className=" pb-5" />
        <MDBInput
          className="mt-2"
          value={props.password}
          icon="key"
          name="password"
          type="password"
          required
          onChange={(e) => {
            props.setPassword(e.target.value);
          }}
        />

        <div className="text-center mt-3 black-text">
          <MDBBtn color="success" rounded type="submit">
            {props.request && (
              <MDBIcon
                icon="sync"
                spin
                size="1x"
                fixed
                className="mr-1 white-text"
              />
            )}
            Valider
            <MDBIcon
              icon="paper-plane"
              size="1x"
              className="ml-2 white-text"
            ></MDBIcon>
          </MDBBtn>
        </div>
      </form>
    </div>
  );
}
