import React, { Fragment, useState, useEffect, useRef } from "react";

import axios from "axios";
import DataTable, { createTheme } from "react-data-table-component";
import Moment from "moment";
import { MDBContainer, MDBAlert } from "mdbreact";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBListGroup,
  MDBListGroupItem,
} from "mdbreact";
import SelectSearch from "react-select-search";
const ExpandableComponent = ({ data }) => (
  <small className="pt-2 pb-3">{data.description}</small>
);

const getLink = (offre) => {
  let link = null;
  let id = "";
  if (offre) id = offre.id_formation.substring(4);
  link = "https://www.francecompetences.fr/recherche/rncp/" + id;
  return link;
};

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <MDBInput
      id="search"
      type="text"
      className="dream-text sidebar_recherche_input"
      hint="Votre recherche"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
  </>
);
const Formation = (props) => {
  // filtre
  useEffect(() => {
    setData([...props.data]);
  }, [props]);
  const [size, setSize] = useState(10);
  const [data, setData] = useState([]);
  const [datatable, setDatatable] = useState([]);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const filteredItems = data.filter(
    (item) =>
      item.intitule &&
      item.intitule.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  createTheme("solarized", {
    text: {
      primary: "#268bd2",
      secondary: "#2aa198",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  });

  const columns = [
    {
      name: "Intitulé",
      selector: "intitule",
      grow: 3,
      sortable: true,
      cell: (row) => (
        <a href={getLink(row)} target="_blanck">
          {row.intitule}
        </a>
      ),
    },

    {
      name: "Ref ",
      selector: "id_formation",
      sortable: true,
      //  cell: (row) => Moment(row.date).format("DD/MM/YYYY"),
    },

    // {
    //   name: "Recruteur ",
    //   selector: "recruteur",
    //   sortable: true,
    // },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
  };

  return (
    <Fragment>
      {data.length > 0 ? (
        <DataTable
          defaultSortAsc="false"
          subHeaderComponent={subHeaderComponentMemo}
          subHeader
          noHeader="true"
          customStyles={customStyles}
          pagination="True"
          paginationPerPage="5"
          title=""
          columns={columns}
          data={filteredItems}
          expandableRows
          expandableRowsComponent={<ExpandableComponent />}
          //   theme="solarized"
        />
      ) : (
        <ScaleLoader size={10} color={"#303950"} loading={true} />
      )}
    </Fragment>
  );
};
export default Formation;
