import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "../components/header/Header";
import LoginForm from "../components/login/LoginForm";
import { useAuth } from "../Contextes/auth";
import { Redirect, Link } from "react-router-dom";

import {
  MDBContainer,
  MDBRow,
  MDBView,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBTooltip,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
} from "mdbreact";
import Contact from "../components/contact/Contact";

export default function LoginHome() {
  return (
    <Fragment>
      <Header></Header>
      <MDBContainer fluid className="">
        {/*
        <div className="text-right mb-0">
          <Link to="/signin">
            <MDBBtn color="success" outline rounded size="sm">
              Créer votre compte
            </MDBBtn>
          </Link>
          <Link to="/login">
            <MDBBtn color="success" outline rounded size="sm">
              Se connecter
            </MDBBtn>
          </Link>
        </div>*/}
        <section className="mb-2 mt-3">
          <h4 className="h3-responsive font-weight-bold text-center mt-5 mb-2 dream-text">
            L'information approfondie et temps réel sur l'emploi
          </h4>
          {/*  <p className="lead grey-text w-responsive text-center mx-auto mb-5">
            Votre système d'information approfondie et temps réel sur l'emploi,
            les métiers et les compétences
          </p>
*/}
          <MDBRow>
            <MDBCol lg="6" className="">
              <div className="text-center ">
                <img
                  alt=""
                  className="img-fluid  d-block w-100"
                  src="intro.png"
                ></img>
              </div>
            </MDBCol>

            <MDBCol lg="6" className=" pl-5">
              <MDBRow className="mb-3 mt-5 pt-5">
                <MDBCol size="1">
                  <MDBIcon
                    icon="layer-group"
                    size="lg"
                    className="dream-text"
                  />
                </MDBCol>
                <MDBCol xl="10" md="11" size="10">
                  <h5 className=" font-weight-bold mb-3 dream-text">
                    Puissance de la Big Data
                  </h5>
                  <p className="grey-text">
                    Plus de 1M offres d'emploi uniques collectées et analysées
                    chaque mois, en temps réel, à partir des plus grands job
                    boards en France{" "}
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol size="1">
                  <MDBIcon icon="atom" size="lg" className="dream-text" />
                </MDBCol>
                <MDBCol xl="10" md="11" size="10">
                  <h5 className="font-weight-bold mb-3 dream-text">
                    Profondeur de l'Intelligence Artificielle
                  </h5>
                  <p className="grey-text">
                    Plusieurs dizaines d'éléments de données et d'indicateurs
                    granulaires générés et harmonisés grâce à des modèles IA de
                    dernière génération
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3 ">
                <MDBCol size="1">
                  <MDBIcon
                    fab
                    icon="buromobelexperte"
                    size="lg"
                    className="dream-text"
                  />
                </MDBCol>
                <MDBCol xl="10" md="11" size="10">
                  <h5 className="font-weight-bold mb-3 dream-text">
                    Efficacité du design
                  </h5>
                  <p className="grey-text">
                    Dashboards et fonctionnalités accessibles, optimisées et
                    évolutives, pour une navigation fluide et une exploitation
                    complète du potentiel qu'offrent les données
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </section>
        <hr className="m-2 d-none d-md-block"></hr>
        <section className="d-none d-md-block">
          <h4 className="h4-responsive font-weight-bold   mb-3 dream-text">
            Nos partenaires
          </h4>
          <MDBRow className="text-center">
            <MDBCol lg="2" md="12" className="mb-lg-0 mb-4">
              <MDBView className="" waves>
                <img
                  src="region_ile-de-France.png"
                  alt=""
                  className="img-fluid"
                  style={{ height: "100px" }}
                />
              </MDBView>
            </MDBCol>
            <MDBCol lg="2" md="12" className="mb-lg-0 mb-4">
              <MDBView className="" waves>
                <img
                  src="neo4jLogo.png"
                  alt=""
                  className="img-fluid"
                  style={{ height: "100px" }}
                />
              </MDBView>
            </MDBCol>
            <MDBCol lg="2" md="12" className="mb-lg-0 mb-4">
              <MDBView className="" waves>
                <img
                  src="Microsoft-for-Startups.png"
                  style={{ height: "100px" }}
                  alt=""
                  className="img-fluid"
                />
              </MDBView>
            </MDBCol>

            <MDBCol lg="2" md="12" className="mb-lg-0 mb-4">
              <MDBView className="" waves>
                <img
                  src="mathworks1.png"
                  alt=""
                  className="img-fluid"
                  style={{ height: "100px" }}
                />
              </MDBView>
            </MDBCol>
            <MDBCol lg="2" md="12" className="mb-lg-0 mb-4">
              <MDBView className="" waves>
                <img
                  src="levillagelogo1.png"
                  alt=""
                  className="img-fluid"
                  style={{ height: "100px" }}
                />
              </MDBView>
            </MDBCol>
            <MDBCol lg="2" md="12" className="mb-lg-0 mb-4">
              <MDBView className=" " waves>
                <img
                  src="French-Tech-logo.png"
                  alt=""
                  className="img-fluid"
                  style={{ height: "100px" }}
                />
              </MDBView>
            </MDBCol>
          </MDBRow>
        </section>
        <hr className="m-2 d-none d-md-block"></hr>
        <section>
          <Contact></Contact>
        </section>
      </MDBContainer>
    </Fragment>
  );
}
