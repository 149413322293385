import React, { Fragment, useState, useEffect, useRef } from "react";
import { MDBContainer } from "mdbreact";
import Contact from "../components/contact/Contact";
import Header from "../components/header/Header";

const ContactPage = (props) => {
  return (
    <Fragment>
      <Header></Header>
      <MDBContainer className="pt-5">
        <Contact></Contact>
      </MDBContainer>
    </Fragment>
  );
};

export default ContactPage;
