import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "../components/header/Header";
import LoginForm from "../components/login/LoginForm";
import { useAuth } from "../Contextes/auth";
import { Redirect, Link } from "react-router-dom";
import queryString from "query-string";
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn } from "mdbreact";
import axios from "axios";
export default function Login(props) {
  const { setLoggedUser } = useAuth();

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [request, setRequest] = useState(false);

  useEffect(() => {
    let params = queryString.parse(props.location.search);
    if (params._email) setEmail(params._email);
    if (params._ref) setPassword(params._ref);
    //if (params._ref) setPassword(reverseMd5(params._ref));
    //if (props.location.query._email) setEmail(props.location.query._email);
  });
  if (isLoggedIn) {
    const url = `/dashboard`;
    return <Redirect to={url} />;
  }
  const submitHandlerLogin = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";

    if (event.target.reportValidity()) {
      setRequest(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/user/login`, {
          email: email,
          password: password,
        })
        .then(
          (response) => {
            console.log(response);
            if (response.status === 200) {
              //se`Basic ${response.data.token}`;tAvatar(response.data.avatar);
              //  setNom(response.data.nom);
              //  setPrenom(response.data.prenom);
              // setEmail(response.data.email);

              setLoggedUser(response.data);

              setLoggedIn(true);
            }
          },
          (error) => {
            console.log("error");
            if (error.response.status === 400) {
              setPassword("");
              setError(true);
              setMessage(
                "Incorrect login information. Please check your credentials and try again"
              );
            } else {
              setError(true);
              setMessage("Please check and correct your input data.");
            }
          }
        )
        .then(function () {
          setRequest(false);
        });
    } else {
      console.log("form not valide");
    }
  };
  return (
    <Fragment>
      <Header></Header>
      <MDBContainer fluid className="">
        <section className="my-5">
          <MDBRow center>
            <MDBCol lg="4" className="">
              <LoginForm
                submitHandlerLogin={submitHandlerLogin}
                error={error}
                message={message}
                email={email}
                setEmail={setEmail}
                setPassword={setPassword}
                request={request}
              ></LoginForm>
            </MDBCol>
          </MDBRow>
        </section>
      </MDBContainer>
    </Fragment>
  );
}
