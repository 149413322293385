import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavFiltre } from "./../Contextes/navfiltreContext";
import axios from "axios";
import DataTable, { createTheme } from "react-data-table-component";
import Moment from "moment";
import { MDBContainer, MDBAlert } from "mdbreact";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBListGroup,
  MDBListGroupItem,
} from "mdbreact";
import SelectSearch from "react-select-search";
import Indicateur from "../components/widget/Indicateur";
const ExpandableComponent = ({ data }) => (
  <small className="pt-2 pb-3">{data.desc}</small>
);

const getLink = (offre) => {
  let link = null;

  if (offre.lien) link = offre.lien;

  if (offre.origineOffre) {
    if (offre.origineOffre.partenaires) {
      if (offre.origineOffre.url) link = offre.origineOffre.url;
    }
  }
};

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <MDBInput
      id="search"
      type="text"
      className="dream-text sidebar_recherche_input"
      hint="Votre recherche"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
  </>
);
const Offres = (props) => {
  // filtre
  const { filtre, setFiltre } = useNavFiltre();
  const [size, setSize] = useState(10);
  const [data, setData] = useState([]);
  const [datatable, setDatatable] = useState([]);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const filteredItems = data.filter(
    (item) =>
      item.intitule &&
      item.intitule.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  createTheme("solarized", {
    text: {
      primary: "#268bd2",
      secondary: "#2aa198",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  });

  //get salaire aggr
  const getOffres = () => {
    // setDatatable([]);
    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/getOffres`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        filtre: filtre,
        size: parseInt(size),
      })
      .then((resp) => {
        console.log(data);
        setData(resp.data);
        // resp.data.hits.hits.forEach((o) => {
        //   console.log(o._source.intitule);
        //   setData(...data, {
        //     id: o._id,
        //     intitule: o._source.intitule,
        //     date: "cc",
        //     region: "dd",
        //   });
        // });
        // // console.log(data);
        // setDatatable(data);
      });
  };

  // Init function
  useEffect(() => {
    getOffres();
  }, [filtre, size]);
  const columns = [
    {
      name: "Intitulé",
      selector: "intitule",
      grow: 3,
      sortable: true,
      cell: (row) =>
        row.lien ? (
          <a href={row.lien ? row.lien : row.lien} target="_blanck">
            {row.intitule}
          </a>
        ) : (
          row.intitule
        ),
    },

    {
      name: "Date publication ",
      selector: "date",
      sortable: true,
      cell: (row) => Moment(row.date).format("DD/MM/YYYY"),
    },

    // {
    //   name: "Recruteur ",
    //   selector: "recruteur",
    //   sortable: true,
    // },
    {
      name: "Région",
      selector: "region",
      sortable: true,
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
  };

  return (
    <Fragment>
      <Indicateur></Indicateur>
      <MDBAlert color="primary">
        Cette version ne permet d'afficher qu'une partie limitée des offres,
        tirées aléatoirement (les offres affichées sont non dé-dupliquées)
      </MDBAlert>
      {data.length > 0 ? (
        <DataTable
          defaultSortAsc="false"
          subHeaderComponent={subHeaderComponentMemo}
          subHeader
          noHeader="true"
          customStyles={customStyles}
          pagination="True"
          paginationPerPage="5"
          title=""
          columns={columns}
          data={filteredItems}
          expandableRows
          expandableRowsComponent={<ExpandableComponent />}
          //   theme="solarized"
        />
      ) : (
        <ScaleLoader size={10} color={"#303950"} loading={true} />
      )}
    </Fragment>
  );
};
export default Offres;
