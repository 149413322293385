import React, { Fragment, useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { useNavFiltre } from "./../../Contextes/navfiltreContext";

import Total from "./Total";
const Indicateur = (props) => {
  const [total, setTotal] = useState(-1);
  const [totalPoste, setTotalPost] = useState(-1);
  const [totalAlternance, setTotalAlternance] = useState(-1);
  const { filtre } = useNavFiltre();

  const getData = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/totalOffre`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        filtre: filtre,
      })
      .then((resp) => {
        setTotal(resp.data.hits.total.value);
      });

    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/totalPoste`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        filtre: filtre,
      })
      .then((resp) => {
        //console.log(resp.data);
        setTotalPost(resp.data.aggregations.nombrePostes.value);
      });

    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/TotalAlternence`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        filtre: filtre,
      })
      .then((resp) => {
        // console.log("$$$$");
        // console.log(resp.data);
        setTotalAlternance(resp.data.hits.total.value);
      });
  };

  // Init function
  useEffect(() => {
    getData();
  }, [filtre]);

  return (
    <MDBRow center className="mb-3">
      <MDBCol md="4" sm="12">
        {total > -1 ? (
          <div>
            <Total
              titre="N° total d'offres  (uniques normalisées) "
              sous_titre="Offres"
              valeur={total}
              type="numero"
              decimal="0"
              note=""
              suffix=""
              bgColor="orange"
            ></Total>
          </div>
        ) : (
          <ScaleLoader size={10} color={"#303950"} loading={true} />
        )}
      </MDBCol>
      <MDBCol md="4" sm="12">
        {totalPoste > -1 ? (
          <Total
            bgColor="blue"
            titre=" N° de postes "
            sous_titre="Postes"
            valeur={totalPoste}
            type="numero"
            decimal="0"
            note=""
          ></Total>
        ) : (
          <ScaleLoader size={10} color={"#303950"} loading={true} />
        )}
      </MDBCol>
      <MDBCol md="4" sm="12">
        {totalAlternance > -1 ? (
          <div className="delay-2s">
            <Total
              bgColor="teal"
              titre=" N° d'alternances "
              sous_titre="Alternances"
              valeur={totalAlternance}
              type="numero"
              decimal="0"
              note=""
            ></Total>
          </div>
        ) : (
          <ScaleLoader size={10} color={"#303950"} loading={true} />
        )}
      </MDBCol>
    </MDBRow>
  );
};
export default Indicateur;
