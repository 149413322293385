import React, { Fragment, useState, useEffect } from "react";
import { MDBIcon, MDBBtn, MDBInput } from "mdbreact";
import { useNavFiltre } from "../../Contextes/navfiltreContext";

function NavFilter(props) {
  //filtre global
  const { filtreRAM, addToFiltre, removeFromFiltre } = useNavFiltre();
  const [data, setData] = useState([]);
  const [item, setItem] = useState();
  // Init function
  useEffect(() => {
    console.log(filtreRAM);
    // alert(FiltreRAM[props.type]);
    setData(filtreRAM[props.type]);
  }, []);
  const _handleKeyDown1 = (e) => {
    if (e.key === "Enter") {
      addToFiltre(props.type, item);
      setItem("");
      updateData();
    }
  };
  const updateData = (close) => {
    setData(filtreRAM[props.type]);
    // if (data.length === 0) props.openModal();
  };
  return (
    <div className="dream-text">
      <table style={{ width: "460px" }}>
        {data &&
          data.map((d, index) => {
            return (
              <tr key={"list-secteurs-" + index}>
                <td style={{ width: "5px" }}>
                  <MDBIcon
                    key={"btn-secteurs-" + index}
                    onClick={(e) => {
                      removeFromFiltre(props.type, d);
                      updateData();
                    }}
                    far
                    icon="times-circle"
                    className="red-text mr-2 pointer"
                  />
                </td>

                <td style={{ fontWeight: "400" }}>{d}</td>
              </tr>
            );
          })}
        <tr>
          <td></td>
          <td>
            <MDBInput
              className="dream-text "
              onKeyDown={_handleKeyDown1}
              onChange={(e) => setItem(e.target.value)}
              hint="..."
              type="text"
              value={item}
            />
          </td>
        </tr>
      </table>
    </div>
  );
}

export default NavFilter;
