import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavFiltre } from "./../Contextes/navfiltreContext";
import SalaireGraph from "../components/chart/SalaireGraph";
import axios from "axios";
import Total from "../components/widget/Total";
import { MDBRow, MDBCol } from "mdbreact";
import ScaleLoader from "react-spinners/ScaleLoader";
const Salaire = () => {
  // filtre
  const { filtre, setFiltre } = useNavFiltre();
  const [graph, setGraph] = useState([]);
  const [total, setTotal] = useState(-1);
  const [totalMoyenSalaire, setTotalMoyenSalaire] = useState(-1);
  const [totalMedianSalaire, setTotalMedianSalaire] = useState(-1);
  const [totalWithSalaire, setTotalWithSalaire] = useState(-1);
  //get salaire aggr
  const getSalaireAggr = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/salaireAggr`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        filtre: filtre,
      })
      .then((resp) => {
        let data = resp.data.aggregations.salaire.buckets;
        setTotal(resp.data.hits.total.value);
        setTotalMedianSalaire(
          resp.data["aggregations"]["median"]["values"]["50.0"]
        );
        setTotalMoyenSalaire(resp.data["aggregations"]["moyen"]["value"]);
        setGraph(data);
      });

    axios
      .post(`${process.env.REACT_APP_API_URL}/dashbord/totalOffreAvecSalaire`, {
        headers: {
          // Authorization: `Basic ${existingTokens}`,
        },
        filtre: filtre,
      })
      .then((resp) => {
        let total = resp.data["hits"]["total"]["value"];
        setTotalWithSalaire(total);
      });
  };

  // Init function
  useEffect(() => {
    getSalaireAggr();
  }, [filtre]);

  return (
    <Fragment>
      <MDBRow center className="">
        <MDBCol size="6">
          {total > -1 ? (
            <div className="animated  fadeInDown mb-2">
              <Total
                titre="N° total d'offres  (uniques normalisées) "
                sous_titre="Offres"
                valeur={total}
                type="numero"
                decimal="0"
                note=""
                suffix=""
                bgColor="orange"
              ></Total>
            </div>
          ) : (
            <ScaleLoader size={10} color={"#303950"} loading={true} />
          )}
        </MDBCol>
        <MDBCol size="6">
          {totalWithSalaire > -1 ? (
            <div className="animated  fadeInDown mb-2">
              <Total
                bgColor="green"
                titre="Pourcentage d'offres indiquant le salaire"
                sous_titre={totalWithSalaire}
                valeur={Intl.NumberFormat("en-IN").format(
                  (totalWithSalaire * 100) / total
                )}
                type="pourcentage"
                decimal="2"
                note=""
              ></Total>
            </div>
          ) : (
            <ScaleLoader size={10} color={"#303950"} loading={true} />
          )}
        </MDBCol>

        <MDBCol size="6">
          {totalMoyenSalaire > -1 ? (
            <div className="animated  fadeInDown mb-2 ">
              <Total
                bgColor="blue"
                titre="Salaire mensuel moyen (fourchette basse)"
                sous_titre=""
                valeur={totalMoyenSalaire}
                type="argent"
                decimal="0"
                note=""
              ></Total>
            </div>
          ) : (
            <ScaleLoader size={10} color={"#303950"} loading={true} />
          )}
        </MDBCol>
        <MDBCol size="6">
          {totalMedianSalaire > -1 ? (
            <div className="animated  fadeInDown mb-2">
              <Total
                bgColor="teal"
                titre="Salaire mensuel médian (fourchette basse)"
                sous_titre=""
                valeur={totalMedianSalaire}
                type="argent"
                decimal="0"
                note=""
              ></Total>
            </div>
          ) : (
            <ScaleLoader size={10} color={"#303950"} loading={true} />
          )}
        </MDBCol>
      </MDBRow>
      <SalaireGraph data={graph}></SalaireGraph>
    </Fragment>
  );
};

export default Salaire;
