import React, {useEffect, useState} from "react";
import {Route, Redirect} from "react-router-dom";
import {useAuth} from "./Contextes/auth";

function PrivateAdminRoute({component: Component, ...rest}) {
    const auth = useAuth();
    const {authTokens} = auth

    return (
        <Route
            {...rest}
            render={(props) =>
                authTokens && localStorage.getItem("role") === '"admin"' ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{pathname: "/dashboard", state: {referer: props.location}}}
                    />
                )
            }
        />
    );
}

export default PrivateAdminRoute;
